import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { TelemetryPoint } from '../model/telemetry-point.model';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TelemetryPointService {
  redirectUrl: string;
  readonly brokenPoints$: BehaviorSubject<TelemetryPoint[]> = new BehaviorSubject<TelemetryPoint[]>([]);
  private brokenPoints: TelemetryPoint[] = [];

  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  get hasBrokenPoints() {
    return Boolean(this.brokenPoints$.getValue().length);
  }

  addBrokenPoint(point: TelemetryPoint) {
    this.brokenPoints.push(point);
  }

  clearBrokenPoints() {
    this.brokenPoints$.next([]);
    this.brokenPoints = [];
  }

  pushBrokenPoints() {
    this.brokenPoints$.next(this.brokenPoints);
    this.brokenPoints = [];
  }

  async createPoint(regId: string, point: TelemetryPoint) {
    let resp
    try {
      const dto = point.toPayload();
      resp = await this.apiService.post(`registrations/${regId}/telemetry-points`, dto);
      return resp;
    } catch (e) {
      point.toEdit = true;
      this.addBrokenPoint(point);
    }
  }

  async updatePoint(point: TelemetryPoint) {
    try {
      const id = point.id;
      const dto = point.toPayload();
      return await this.apiService.put(`telemetry-points/${id}`, dto);
    } catch (error) {
      point.toEdit = true;
      this.addBrokenPoint(point);
    }
  }

  async deletePoint(id: string) {
    return await this.apiService.delete(`telemetry-points/${id}`);
  }

  async getPointsForRegistration(id: string) {
    try {
      const resp = await this.apiService.get(`registrations/${id}/telemetry-points`);
      return resp.map(point => new TelemetryPoint(point));
    } catch (error) {
      console.log("Error fetching telemetry points", error);
    }
  }

  async createOrUpdatePoints(regId: string, points: TelemetryPoint[]) {
    this.clearBrokenPoints();
    let data = [];
    for (const point of points) {
      let saved;
      if (point.channelId == "-1") {
        // SOLO-1274 initialize channelId
        point.channelId = point.deliveredChannelId;
      }
      if (point.deleted) {
        if (point.id) {
          await this.deletePoint(point.id);
        }
      } else if (point.id) {
        saved = await this.updatePoint(point);
        data.push(saved);
      } else {
        saved = await this.createPoint(regId, point);
        data.push(saved);
      }
    }
    if (this.brokenPoints.length) {
      this.brokenPoints = [...this.brokenPoints, ...await this.getPointsForRegistration(regId)];
    }
    this.pushBrokenPoints();
    return data;
  }
}
