export class SDP {
  constructor(
    public id = '',
    public commodity = '',
    public displayLabel = '',
    public descriptions = '',
    public sdpType = '',
    public serviceAccounts = [],
  ) {}
}
