export class Locale {
  constructor(public id = '1', public displayLabel = 'English (US)', public localeName = 'en_US') {}
}

export interface LocaleBaseConfig {
  supportedLocales: any[];
  locales: any[];
  defaultLocale: Locale;
  displayLabelKey: string;
  localeKey: string;
  id: string;
  maxLength?: number;
  required?: boolean;
}
