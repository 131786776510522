import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { GlobalAlertService } from '../../shared/services/global-alert.service';
import { RegistrationsService } from '../../shared/services/registrations.service';
import { RegistrationSetsService } from '../../shared/services/registration-sets.service';
import { BulkService } from '../bulk.service';
import { DrTypeService } from '../../shared/services/dr-type.service';
import {
  DispatchableRegBulkFormGroup,
  DispatchableRegistrationFormGroup,
  SettlementRegBulkFormGroup,
  SettlementRegistrationFormGroup,
} from '../bulk-form-group.model';
import { Registration } from '../../shared/model/registration.model';
import { BulkEditDialogComponent } from '../dialogs/bulk-edit-submit/bulk-edit.dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-edit',
  templateUrl: './bulk.edit.component.html',
  styleUrls: ['./bulk.edit.component.scss', '../../shared/shared.styles.scss'],
})
export class BulkEditComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = [];
  loadingPage: boolean = true;
  selectedDRType: any;
  selectedRegs: {}[] = [];
  bulkForm: UntypedFormGroup = SettlementRegBulkFormGroup();
  loadForm = false;
  saving: boolean;
  done = false;
  errorMessages = [];
  registrationType: string = 'dispatchable';
  allBulkTypes: string[] = ['bulkSet', 'list'];
  selectedBulkType: string = this.allBulkTypes[0];
  private loadingRegistrations: boolean;
  private bulkRegistrations: Registration[] = [];
  private bulkRegistrationsFormGroups: any[];
  private formIsValid: string;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private translateService: TranslateService,
    private messagingService: GlobalAlertService,
    private drTypeService: DrTypeService,
    private registrationsService: RegistrationsService,
    private registrationSetService: RegistrationSetsService,
    private bulkService: BulkService,
  ) {
    const translateSub = this.translateService.get('registration.registration').subscribe((result: string) => {});

    const selectedDRTypeSub = this.drTypeService.selectedDRType$.subscribe(async currentDrType => {
      if (currentDrType) {
        this.selectedDRType = currentDrType;
      }
    });

    const routeSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url !== '/bulk/edit') {
          this.router.navigate([`/`]);
        }
      });

    const selectedBulkFormTypeSub = this.bulkService.selectedBulkFormType$.subscribe(type => {
      this.registrationType = type;
    });

    const bulkSelectionSub = this.bulkService.bulkRegistrationsWithData$.subscribe(regs => {
      if (regs && regs.length > 0) {
        this.bulkRegistrations = regs;
        this.bulkRegistrationsFormGroups = this.bulkRegistrations.map(registration => {
          let formGroup;

          if (this.registrationType === 'settlement') {
            formGroup = SettlementRegistrationFormGroup();
          } else {
            formGroup = DispatchableRegistrationFormGroup();
          }
          formGroup.patchValue(registration);

          return formGroup;
        });
        this.loadingRegistrations = false;
      }
    });

    this.subscriptions.push(...[translateSub, selectedDRTypeSub, bulkSelectionSub, routeSub, selectedBulkFormTypeSub]);
  }

  ngAfterViewInit() {}

  ngOnInit() {
    this.loadForm = false;
    this.loadingPage = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  handleCancel() {
    this.bulkService.setBulkModeOff();
    this.router.navigate([`/`]);
  }

  removeDisabledFields(rawRegistration: any) {
    for (const key in rawRegistration) {
      if (rawRegistration[key] && rawRegistration[key].disabled) {
        delete rawRegistration[key];
      }
    }
    return rawRegistration;
  }

  handleSubmit() {
    const registrationsWithUpdatedValues = this.bulkRegistrationsFormGroups.map((formGroup: UntypedFormGroup) => {
      formGroup.patchValue(this.bulkForm.value);
      return this.removeDisabledFields(formGroup.getRawValue());
    });
    this.dialog.open(BulkEditDialogComponent, {
      width: '400px',
      disableClose: true,
      data: {
        bulkEditData: this.bulkForm.value,
        registrations: registrationsWithUpdatedValues,
        action: 'PUT',
      },
    });
  }
}
