import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { DefaultScheduleConf, RegistrationSet } from '../model/registration-set.model';
import { Router } from '@angular/router';

@Injectable()
export class RegistrationSetsService {
  readonly registrationSet$ = new BehaviorSubject<RegistrationSet>(null);
  readonly orgId$ = new BehaviorSubject<string>(null);
  registrationSetList$: Subject<RegistrationSet[]>;

  constructor(private apiService: ApiService, private router: Router) {
    this.registrationSetList$ = new Subject<RegistrationSet[]>();
  }

  async getRegistrationSet(id: string) {
    try {
      const response = await this.apiService.get(`registration-sets/${id}`);
      let registrationSet = this.newRegistrationSet(response);
      this.registrationSet$.next(registrationSet);
      return registrationSet;
    } catch (err) {
      throw err;
    }
  }

  async getRegistrationsForSite(siteId: string) {
    try {
      const response = await this.apiService.get(`sites/${siteId}/registrations`);
      return convertKeys.toCamel<any>(response);
    } catch (err) {
      throw err;
    }
  }

  async getRegistrationSets(orgId: string, orderBy?: string) {
    try {
      const registrationSets = await this.apiService.get(`registration-sets?org_id=${orgId}&order=${orderBy}`);
      this.orgId$.next(orgId);
      this.registrationSetList$.next(registrationSets);
      return registrationSets;
    } catch (err) {
      throw err;
    }
  }

  async getRegistrationsForProgram(programId: string) {
    try {
      const response = await this.apiService.get(`programs/${programId}/registrations`);
      return convertKeys.toCamel<any>(response);
    } catch (err) {
      throw err;
    }
  }

  async refetchRegistrationSets() {
    if (this.orgId$.value) {
      await this.getRegistrationSets(this.orgId$.value, 'ASCEND');
    }
  }

  async createRegistrationSet(registrationSet: RegistrationSet): Promise<RegistrationSet> {
    if (!registrationSet.defaultScheduleConf.applyDefaultOffers) {
      delete registrationSet.defaultScheduleConf.defaultScheduleStart;
      delete registrationSet.defaultScheduleConf.defaultScheduleEnd;
    }
    let dto = convertKeys.toSnake<any>(registrationSet);
    this.removeEmpty(dto);
    dto.display_labels = registrationSet.displayLabels;
    dto.descriptions = registrationSet.descriptions;

    let createResponse = await this.apiService.post('registration-sets', dto);

    await Promise.all(
      registrationSet.registrationIds.map(async registrationId => {
        await this.apiService.put(`registration-sets/${createResponse.id}/registration/${registrationId}`, null);
        return undefined;
      }),
    );

    return this.newRegistrationSet(createResponse);
  }

  async updateRegistrationSet(
    registrationSet: RegistrationSet,
    registrationsToDelete: string[],
  ): Promise<RegistrationSet> {
    const regSetId = registrationSet.id;
    if (!registrationSet.defaultScheduleConf.applyDefaultOffers) {
      delete registrationSet.defaultScheduleConf.defaultScheduleStart;
      delete registrationSet.defaultScheduleConf.defaultScheduleEnd;
    }
    let dto = convertKeys.toSnake<any>(registrationSet);
    this.removeEmpty(dto);
    dto.display_labels = registrationSet.displayLabels;
    dto.descriptions = registrationSet.descriptions;

    let editResponse = await this.apiService.put(`registration-sets/${regSetId}`, dto);

    await Promise.all(
      registrationsToDelete.map(async registrationId => {
        await this.apiService.delete(`registration-sets/${editResponse.id}/registration/${registrationId}`);
        return undefined;
      }),
    );

    await Promise.all(
      registrationSet.registrationIds.map(async registrationId => {
        await this.apiService.put(`registration-sets/${editResponse.id}/registration/${registrationId}`, null);
        return undefined;
      }),
    );

    return this.newRegistrationSet(editResponse);
  }

  newRegistrationSet(rawRegistrationSet: any) {
    const registrationSet = convertKeys.toCamel<any>(rawRegistrationSet);
    registrationSet.alternateIds = registrationSet.alternateIds || {};
    registrationSet.displayLabels = rawRegistrationSet.display_labels;
    registrationSet.descriptions = rawRegistrationSet.descriptions || {};

    return new RegistrationSet(
      registrationSet.id,
      registrationSet.displayLabel,
      registrationSet.displayLabels,
      registrationSet.programId,
      registrationSet.defaultLocale,
      registrationSet.timezone,
      registrationSet.status,
      registrationSet.drType,
      registrationSet.registrationIds,
      registrationSet.descriptions,
      registrationSet.description,
      registrationSet.minimumValue,
      registrationSet.maximumValue,
      registrationSet.offerAtSet,
      registrationSet.percentChange,
      registrationSet.offerDimension,
      registrationSet.defaultScheduleConf,
      registrationSet.registrations,
    );
  }

  async deleteRegistrationSet(regSetId: string) {
    try {
      return await this.apiService.delete(`registration-sets/${regSetId}`);
    } catch (error) {
      console.log('Could not delete this registration set', error);
    }
  }

  async updateRegSetDeleted(registrations) {
    const regSetId = null;
    await Promise.all(
      registrations.map(async registrationId => {
        await this.apiService.put(`registration-sets-deleted/registration/${registrationId}`, null);
        return undefined;
      }),
    );
  }

  async getSiteFromRegistration(registrationId: string) {
    let registration = await this.apiService.get(`registrations/${registrationId}`);
    registration = convertKeys.toCamel<any>(registration);
    if (registration) {
      if (registration.flexibleAssetId) {
        let flexibleAsset = await this.apiService.get(`flexible-assets/${registration.flexibleAssetId}`);
        flexibleAsset = convertKeys.toCamel<any>(flexibleAsset);
        if (flexibleAsset && flexibleAsset.ownerId) {
          return flexibleAsset.ownerId;
        }
      }
    }
  }

  removeEmpty = obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmpty(obj[key]);
      } else if (obj[key] === null || obj[key] === '') {
        delete obj[key];
      }
    });
  };
}
