import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { Site } from '../model/space.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { RegistrationsService } from './registrations.service';
import { Registration } from '../model/registration.model';

@Injectable()
export class RegistrationDetailsService {
  loadingSites = false;
  readonly sites$: BehaviorSubject<Site[]>;
  readonly registration$: BehaviorSubject<Registration>;
  readonly selectedSiteId$ = new Subject<string>();
  readonly selectedRegistration = new Subject<string>();

  constructor(private apiService: ApiService, private registrationsService: RegistrationsService) {
    this.sites$ = new BehaviorSubject<Site[]>([]);
    this.registration$ = new BehaviorSubject<Registration>(null);
  }

  selectSite(siteId: string): void {
    this.selectedSiteId$.next(siteId);
  }

  setRegistration(registration: any): void {
    this.registration$.next(registration);
  }

  async getRegistrationDetails(registrationId) {
    const registration = await this.registrationsService.getRegistration(registrationId);
    if (registration) {
      this.setRegistration(registration);
    }
  }

  async getSitesForOrg(orgId: string) {
    try {
      this.loadingSites = true;
      const sites = await this.apiService.get(`sites/${orgId}`);
      this.sites$.next(sites.map(convertKeys.toCamel));
      this.loadingSites = false;
    } catch (err) {
      console.log('Could not load Sites.', err);
      this.loadingSites = false;
      this.sites$.next([]);
    }
  }
}
