import { Injectable } from '@angular/core';
import { TransformRule } from '../model/transform-rule.model';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class TransformRulesService {
  constructor(private apiService: ApiService) {}

  async getRules(regId: string): Promise<TransformRule[]> {
    const resp = await this.apiService.get(`registrations/${regId}/transform-rules`);
    return resp.map(rule => new TransformRule(rule));
  }

  async createRule(regId: string, rule: TransformRule): Promise<TransformRule> {
    const r = await this.apiService.post(`registrations/${regId}/transform-rules`, rule.toPayload());
    return new TransformRule(r);
  }

  async updateRule(regId: string, rule: TransformRule): Promise<TransformRule> {
    const r = await this.apiService.put(`registrations/${regId}/transform-rules/${rule.id}`, rule.toPayload());
    return new TransformRule(r);
  }

  async deleteRule(regId: string, rule: TransformRule): Promise<void> {
    await this.apiService.delete(`registrations/${regId}/transform-rules/${rule.id}`);
  }

  async save(regId: string, rules: TransformRule[]): Promise<TransformRule[]> {
    // Reset the order on the rules to ensure it's unique and prevent overlapping
    // rule error responses from DAD
    const orderSuffix = Math.floor(Math.random() * 10000) / 10000;
    rules.forEach((r, index) => {
      r.registration_id = regId;
      r.order = index + orderSuffix;
    });

    // Then save or delete each rule in turn
    const savedRules = [];
    for (const rule of rules) {
      let saved;
      if (rule.deleted) {
        if (rule.id) {
          await this.deleteRule(regId, rule);
        }
      } else if (rule.id) {
        saved = await this.updateRule(regId, rule);
        savedRules.push(saved);
      } else {
        saved = await this.createRule(regId, rule);
        savedRules.push(saved);
      }
    }
    return savedRules;
  }
}
