import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationsService } from '../../../shared/services/registrations.service';
import { GlobalAlertService } from 'src/app/shared/services/global-alert.service';
import { BulkService } from '../../bulk.service';
import { Registration } from '../../../shared/model/registration.model';

interface BulkData {
  bulkCommonData: object;
  registrations: any[];
  action: string;
}

@Component({
  selector: 'app-bulk-edit-dialog',
  templateUrl: './bulk-edit.dialog.component.html',
  styleUrls: ['./bulk-edit.dialog.component.scss'],
})
export class BulkEditDialogComponent {
  dialogState = 'DEFAULT';
  SUCCESS = 'Updated Successfully';
  failedRegistrations = [];
  succeededRegistrations = [];

  constructor(
    private router: Router,
    private registrationsService: RegistrationsService,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
    private bulkService: BulkService,
    public dialogRef: MatDialogRef<BulkEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkData,
  ) {
    this.translateService.get('registration.notification.edit').subscribe((result: string) => {
      this.SUCCESS = result;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.failedRegistrations = [];
    this.succeededRegistrations = [];
  }

  async handleSubmit() {
    try {
      this.dialogState = 'UPDATING';

      this.bulkService.resetFailedRegistrations();
      this.bulkService.resetSucceededRegistrations();

      if (this.data.registrations) {
        await (async () => {
          for await (const reg of this.data.registrations) {
            await this.registrationsService
              .updateRegistration(reg)
              .then(() => {
                this.succeededRegistrations.push(reg);
                this.bulkService.setSucceededRegistrations(this.succeededRegistrations);
              })
              .catch(() => this.pushIfNotExist(reg));
          }
        })();
      }
      if (this.failedRegistrations.length !== 0) {
        this.bulkService.setFailedRegistrations(this.failedRegistrations);
        throw new Error();
      }
      this.bulkService.resetFailedRegistrations().then(() => {
        this.dialogState = 'UPDATE_SUCCESS';
        this.messageService.setSuccess(this.SUCCESS);
      });
    } catch (e) {
      this.dialogState = 'UPDATE_FAILED';
    }
  }

  async onSubmit() {
    await this.bulkService.clearAllSelections();
    await this.bulkService.setBulkModeOff();
    this.dialogRef.close();
    await this.router.navigate([`/`]);
  }

  async handleRetryAll() {
    try {
      this.dialogState = 'UPDATING';

      this.bulkService.resetFailedRegistrations();

      if (this.failedRegistrations) {
        await (async () => {
          for await (const reg of this.failedRegistrations) {
            await this.registrationsService
              .updateRegistration(reg)
              .then(() => {
                this.succeededRegistrations.push(reg);
                this.failedRegistrations = this.failedRegistrations.filter(
                  reg => !this.succeededRegistrations.includes(reg),
                );
                this.bulkService.setSucceededRegistrations(this.succeededRegistrations);
                this.bulkService.setFailedRegistrations(this.failedRegistrations);
              })
              .catch(() => this.pushIfNotExist(reg));
          }
        })();
      }
      if (
        this.failedRegistrations.length !== 0 &&
        this.succeededRegistrations.length !== this.data.registrations.length
      ) {
        this.failedRegistrations = this.failedRegistrations.filter(reg => !this.succeededRegistrations.includes(reg));
        await this.bulkService.setFailedRegistrations(this.failedRegistrations);
        throw new Error();
      } else {
        this.failedRegistrations.length = 0;
        this.bulkService.resetFailedRegistrations().then(() => {
          this.dialogState = 'UPDATE_SUCCESS';
          this.messageService.setSuccess(this.SUCCESS);
        });
      }
    } catch (e) {
      this.dialogState = 'UPDATE_FAILED';
      console.log(e);
      console.log('this.failedRegistrations', this.failedRegistrations);
    }
  }

  async handleSingleRetry(regId: string) {
    try {
      this.dialogState = 'UPDATING';
      const reg = await this.registrationsService.getRegistration(regId);

      await this.registrationsService
        .updateRegistration(reg)
        .then(() => this.handleRemove(regId))
        .catch(() => this.pushIfNotExist(reg));

      if (this.failedRegistrations.length !== 0) {
        throw new Error();
      }
      setTimeout(() => {
        this.dialogRef.close();
        this.router.navigate([`/`]);
      }, 2000);

      this.messageService.setSuccess(this.SUCCESS);
    } catch (e) {
      this.dialogState = 'UPDATE_FAILED';
      console.log('this.failedRegistrations', this.failedRegistrations);
    }
  }

  handleRemove(regId: string): void {
    this.failedRegistrations = this.failedRegistrations.filter(reg => reg.id !== regId);
    if (this.failedRegistrations.length === 0) this.onNoClick();
  }

  pushIfNotExist(reg: Registration) {
    if (!this.failedRegistrations.find(r => r.id === reg.id)) {
      this.failedRegistrations.push(reg);
    }
  }
}
