import { Directive, Input } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[minMaxValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinMaxValidator, multi: true }],
})
export class MinMaxValidator implements Validator {
  @Input('minControl') minControl: any;
  @Input('maxControl') maxControl: any;
  validate(theForm: UntypedFormGroup) {
    let isValid = true;

    if (
      Object.keys(theForm.controls).length > 0 &&
      this.minControl.control.value !== null &&
      this.maxControl.control.value !== null &&
      this.minControl.control.value >= 0 &&
      this.maxControl.control.value >= 0
    ) {
      isValid = this.minControl.control.value < this.maxControl.control.value;
    }

    if (isValid) {
      return null;
    } else {
      return {
        minMaxValid: {
          valid: false,
        },
      };
    }
  }
}
