import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { Status } from '../model/status.model';
import { LOAStatus } from '../model/loa-status.model';

@Injectable()
export class StatusesService {
  statuses$: BehaviorSubject<Status[]>;
  loaStatuses$: BehaviorSubject<LOAStatus[]>;

  constructor(private apiService: ApiService) {
    this.statuses$ = new BehaviorSubject<Status[]>([]);
    this.loaStatuses$ = new BehaviorSubject<LOAStatus[]>([]);
  }

  async fetchStatuses() {
    try {
      const statuses = await this.apiService.get(`statuses`);
      if (typeof statuses !== undefined && Array.isArray(statuses) && statuses.length > 0) {
        statuses.sort((a, b) => Number(a.display_seq) - Number(b.display_seq));

        this.statuses$.next(convertKeys.toCamel<Status[]>(statuses));
      }
    } catch (err) {
      console.log('Could not load Statuses.', err);
    }
  }

  async fetchLOAStatuses() {
    try {
      const loaStatuses = await this.apiService.get(`loa-statuses`);
      if (typeof loaStatuses !== undefined && Array.isArray(loaStatuses) && loaStatuses.length > 0) {
        loaStatuses.sort((a, b) => Number(a.display_seq) - Number(b.display_seq));

        this.loaStatuses$.next(convertKeys.toCamel<LOAStatus[]>(loaStatuses));
      }
    } catch (err) {
      console.log('Could not load LOA Statuses.', err);
    }
  }
}
