export class Registration {
  constructor(
    public id = '',
    public name = '',
    public displayLabels: any = {},
    public productId: any = '',
    public defaultLocale = 'en_US',
    public timezone = 'America/New_York',
    public status = 'ACTIVE',
    public baselineDefId = null,
    public fullReg: any = null,
    public sdpId?: string,
    public regId?: string,
    public crmId?: string,
    public description?: string,
    public descriptions?: any,
    public registrationSetId?: string,
    public setId?: string,
    public programId?: string,
    public portfolioId?: string,
    public flexibleAssetId?: string,
    public effectiveDttm?: string,
    public terminationDttm?: string,
    public minimumValue?: number,
    public maximumValue?: number,
    public nominationOffset?: number,
    public customerReadOnly?: boolean,
    public eventAdvanceNotice?: number,
    public minEventDuration: number = 30,
    public maxEventDuration?: number,
    public maxHoursPerDay?: number,
    public lockedOut?: boolean,
    public controlTimeoutOverride?: number,
    public controlTimeOverride?: number,
    public restoreTimeOverride?: number,
    public initialNotifTimeOverride?: number,
    public restoreNotifTimeOverride?: number,
    public preauthorized?: boolean,
    public controlType?: string,
    public dispatchConf?: DispatchConfig,
    public marketConf?: MarketConfig,
    public registrationType?: string,
    public registrationExternalReferenceId?: string,
    public dropToValue?: number,
    public baselineValue?: number,
    public loaStatus?: string,
    public energyReductionPlan: string = '',
    public projectManagerName?: string,
    public projectManagerId?: string,
    public accountManagerName?: string,
    public accountManagerId?: string,
    public readyToTest?: boolean,
  ) {}
}

export class MarketConfig {
  constructor(public locationId?: string) {}
}

export class DispatchConfig {
  constructor(
    public anticipatedKw?: number,
    public estimatePerformanceInd = false,
    public maxHoursPerSeason?: number,
    public maxConsecutiveDays?: number,
    public maxConsecutiveHours?: number,
    public maxEventsPerSeason?: number,
    public minutesBetweenEvents?: number,
    public rampDownRate?: number,
    public rampUpRate?: number,
  ) {}
}
