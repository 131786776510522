import { SelectedTemplate } from './../model/selected-template.model';
import { Injectable } from '@angular/core';
import { TransformTemplate } from '../model/transform-template.model';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable()
export class TransformTemplatesService {
  transformTemplates: TransformTemplate[];

  private selectedTemplates: SelectedTemplate[] = [];
  selectedTemplatesChanged = new Subject<SelectedTemplate[]>();


  constructor(private apiService: ApiService) {
    this.transformTemplates = [];
  }

  addSelectedTemplate(template: SelectedTemplate){
    this.selectedTemplates.push(template)
    this.selectedTemplatesChanged.next(this.selectedTemplates.slice());
  }

  addPreSelectedTemplates(templates: SelectedTemplate[]){
    this.selectedTemplates.push(...templates);
    this.selectedTemplatesChanged.next(this.selectedTemplates.slice())
  }

  deleteSelectedTemplate(index: number){ 
    this.selectedTemplates = this.selectedTemplates.filter( (template) => {
      return template.id !== index
    })
    this.selectedTemplatesChanged.next(this.selectedTemplates.slice())
  }

  updateSelectedTemplate(index: number, newType: string){
    this.selectedTemplates.forEach( (template) => {
      if(template.id === index){
        template.type = newType;
      }
    })
    
    this.selectedTemplatesChanged.next(this.selectedTemplates.slice())
  }


  async setTransformTemplates() {
    this.transformTemplates = await this.apiService.get('transform-templates');
  }
}
