import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Space } from '../model/space.model';
import { BehaviorSubject, Subject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';

@Injectable()
export class SelectedSpaceService {
  private subject: ReplaySubject<Space> = new ReplaySubject(1);
  sites$: BehaviorSubject<Space[]>;

  readonly selectedID$ = new Subject<string>();

  constructor(private apiService: ApiService) {
    this.sites$ = new BehaviorSubject<Space[]>(null);
  }

  get space(): ReplaySubject<Space> {
    return this.subject;
  }

  async setOrgSites(orgId: string) {
    try {
      const sites = await this.apiService.get(`organizations/${orgId}/sites`);
      this.sites$.next(convertKeys.toCamel(sites));
    } catch (err) {
      this.sites$.next([]);
      console.log('Could not load sites.', err);
    }
  }

  async setSelectedID(id: string) {
    await this.selectedID$.next(id);
  }
}
