import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { Status } from '../../shared/model/status.model';
import { Timezone } from '../../shared/model/timezone.model';
import { Product } from '../../shared/model/product.model';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { ActivatedRoute } from '@angular/router';
import { RegistrationSetsService } from '../../shared/services/registration-sets.service';
import { LocalesService } from '../../shared/services/locales.service';
import { StatusesService } from '../../shared/services/statuses.service';
import { TimezonesService } from '../../shared/services/timezones.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { DeeplinksService } from '../../shared/services/deeplinks.service';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { ProgramsService } from '../../shared/services/programs.service';
import { OrganizationsService } from '../../shared/services/organizations.service';
import { SupportingDataService } from 'src/app/shared/services/supporting-data.service';
import { RegistrationSet } from 'src/app/shared/model/registration-set.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-registration-set',
  templateUrl: './registration-set-details.component.html',
  styleUrls: ['./registration-set-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class RegistrationSetDetailsComponent implements OnInit, OnDestroy {
  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  REQUIRED = 'required';

  clone = false;
  org = '';
  locales: Locale[];
  statuses: Status[];
  timezones: Timezone[];
  displayLabel = 'displayLabel';
  selectedSite: '';
  selectedSite_label: '';

  userLocale = 'en_US';

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  cloneLabel = 'Clone';
  namePlaceholder = '';
  nameLabel = '';
  descriptionPlaceholder = '';
  descriptionLabel = '';

  private _mode: string;
  private _data: any = {};
  private _isRetired = false;
  private _registrationSet: RegistrationSet = null;
  private subscriptions: Subscription[] = [];

  loadingDataProviders: boolean;
  loadingRegistrations: boolean;
  registrationsBySite: any[] = [];
  registrationsByProgram: any[] = [];
  filteredRegistrations: any[] = [];
  originalRegistrations: any[] = [];
  registrationsToRemove: any[] = [];
  offerUnits: any[];

  constructor(
    private programsService: ProgramsService,
    private ngxCookieService: NgxCookieService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private route: ActivatedRoute,
    private registrationSetService: RegistrationSetsService,
    private localesService: LocalesService,
    private statusesService: StatusesService,
    private timezonesService: TimezonesService,
    private orgSelectorService: ContextSelectorService,
    private organizationsService: OrganizationsService,
    private deeplinksInternalService: DeeplinksService,
    private translateService: TranslateService,
    private _adapter: DateAdapter<any>,
    private supportingDataService: SupportingDataService,
  ) {
    // @ts-ignore
    this._registrationSet = new RegistrationSet();
    this._registrationSet.registrations = {};
    this.org = this.ngxCookieService.getCookie('preferred-org-id') || '';
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this._adapter.setLocale(this.userLocale);

    const contextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      this.org = org.id;
      if (org.id) {
        this.loadingDataProviders = true;
        await this.supportingDataService.setDataProviders(org.id);
        this.loadingDataProviders = false;
      }
    });

    const routeSub = this.route.queryParams.subscribe(async queryParams => {
      if (queryParams.clone) {
        this.clone = true;
      }
    });

    const localesSub = this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;

      if (this.isCreateMode) {
        this.multiLocaleConfig.supportedLocales = [locale];
        this.registrationSet.defaultLocale = this.userLocale;
      } else if (this.isEditMode) {
        this.buildSupportedLocales();
      }
    });

    const offerUnitsSub = this.supportingDataService.offerUnits$.subscribe(offers => {
      if (this.isCreateMode || this.isEditMode) {
        this.offerUnits = offers;
      }
    });

    const statusesSub = this.statusesService.statuses$.subscribe((statuses: any) => {
      if (statuses && statuses.length > 0) {
        this.statuses = statuses.filter(status => status.code === 'ACTIVE' || status.code === 'INACTIVE');
      }
    });

    const timezonesSub = this.timezonesService.timezones$.subscribe((timezones: any) => {
      if (timezones && timezones.length > 0) {
        this.timezones = timezones;
      }
    });

    this.translateService.get('registration.create.button.create_new_product').subscribe((result: string) => {
      this.REQUIRED = this.translateService.instant('registration.validation.required');
      this.cloneLabel = this.translateService.instant('registration.create.clone');
      this.nameLabel = this.translateService.instant('registration_set.registration_set_name');
      this.namePlaceholder = this.translateService.instant('registration_set.create.placeholder.registration_set_name');
      this.descriptionLabel = this.translateService.instant('registration.description');
      this.descriptionPlaceholder = this.translateService.instant('registration.create.placeholder.description');
    });

    this.subscriptions.push(...[contextSub, routeSub, localesSub, offerUnitsSub, statusesSub, timezonesSub]);
  }

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  @Input()
  set appData(appData: any) {
    this._data = appData;
  }

  get appData() {
    return this._data;
  }

  get registrationSet() {
    return this._registrationSet;
  }

  set isRetired(status) {
    this._isRetired = status;
  }

  get isRetired() {
    return this._isRetired;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  async ngOnInit() {
    this.timezonesService.fetchTimezones();
    this.statusesService.fetchStatuses();
    this.localesService.fetchLocales();
    this.supportingDataService.getOfferUnits();

    const registrationSetSub = this.registrationSetService.registrationSet$.subscribe(
      async (regSet: RegistrationSet) => {
        if (regSet && !this.isCreateMode) {
          this.originalRegistrations = regSet.registrationIds;
          this.populateRegistrationSet(regSet);
        }
      },
    );

    const localesSub = this.localesService.locales$.subscribe(locales => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;

      if (this.isCreateMode) {
        this.multiLocaleConfig.supportedLocales = [locale];
        this.registrationSet.defaultLocale = this.userLocale;
      } else if (this.isEditMode) {
        this.buildSupportedLocales();
      }
    });
    this.subscriptions.push(...[registrationSetSub, localesSub]);
  }

  async populateRegistrationSet(registrationSetData) {
    this._registrationSet = { ...registrationSetData };
    if (registrationSetData.status && registrationSetData.status === 'RETIRED') {
      this.isRetired = true;
    }
    this.buildSupportedLocales();
    if (this.isEditMode && registrationSetData.registrationIds[0]) {
      this.loadingRegistrations = true;
      this.selectedSite = await this.registrationSetService.getSiteFromRegistration(
        registrationSetData.registrationIds[0],
      );
      this.onProgramChange(registrationSetData.programId);
      this.onSiteChange(this.selectedSite);
    }
  }

  getProgramForRegistrationSet() {
    if (this._data.programs?.length > 0 && this._registrationSet?.programId) {
      return (
        this._data.programs.find(product => this._registrationSet.programId.indexOf(product.id) !== -1) || new Product()
      );
    }

    return new Product();
  }

  getTimezoneForRegistrationSet() {
    if (this.timezones?.length > 0 && this._registrationSet?.timezone) {
      return (
        this.timezones.find(timezone => this._registrationSet.timezone.indexOf(timezone.timezoneName) !== -1) ||
        new Timezone()
      );
    }

    return new Timezone();
  }

  getStatusForRegistrationSet() {
    if (this.statuses?.length > 0 && this._registrationSet?.status) {
      return this.statuses.find(status => this._registrationSet.status === status.code) || new Status();
    }

    return new Status();
  }

  getLocaleForRegistrationSet() {
    if (this.locales?.length > 0 && this._registrationSet?.defaultLocale) {
      return (
        this.locales.find(locale => this._registrationSet.defaultLocale.indexOf(locale.localeName) !== -1) ||
        new Locale()
      );
    }

    return new Locale();
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  handleLocaleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this.registrationSet && this.locales) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.registrationSet.displayLabels);
      for (let key of keys) {
        const localeFound = this.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  getRegistrationDisplayLabel(registrationId) {
    const registration = this.registrationsBySite.find(reg => reg.id === registrationId);
    if (registration) {
      return registration.displayLabel;
    }
  }

  async onSiteChange(siteId: string) {
    if (siteId) {
      this.loadingRegistrations = true;
      this.registrationsBySite = await this.registrationSetService.getRegistrationsForSite(siteId);
      this.findCommonRegistrations();
      this.loadingRegistrations = false;
      this._data.sites.forEach(site => {
        if (site.id === siteId) {
          this.selectedSite_label = site.displayLabel;
        }
      });
    }
  }

  async onProgramChange(programId: string) {
    if (programId) {
      this.loadingRegistrations = true;
      this.registrationsByProgram = await this.registrationSetService.getRegistrationsForProgram(programId);
      this.findCommonRegistrations();
      this.loadingRegistrations = false;
    }
  }

  findCommonRegistrations() {
    if (this.isCreateMode) {
      this.filteredRegistrations = this.registrationsBySite.filter(reg => {
        return this.registrationsByProgram.find(reg_1 => {
          return reg.id === reg_1.id && !reg.registrationSetId && reg.flexibleAssetId;
        });
      });
      const unsupportedRegistrations = this.registrationSet.registrationIds.filter(regId => {
        return !this.filteredRegistrations.find(reg => reg.id === regId);
      });
      if (unsupportedRegistrations) {
        unsupportedRegistrations.forEach(regId => {
          this.removeRegistration(regId);
        });
      }
    } else if (this.isEditMode) {
      this.filteredRegistrations = this.registrationsBySite.filter(reg => {
        return this.registrationsByProgram.find(reg_1 => {
          return (
            reg.id === reg_1.id &&
            reg.flexibleAssetId &&
            (reg.registrationSetId === this.registrationSet.id || !reg.registrationSetId)
          );
        });
      });
    }
  }

  handleRegistrationSelection() {
    if (this.isEditMode) {
      this.registrationsToRemove = this.originalRegistrations.filter(
        regId => !this.registrationSet.registrationIds.includes(regId),
      );
    }
  }

  removeRegistration(registrationId: string) {
    this.registrationSet.registrationIds = this.registrationSet.registrationIds.filter(
      regId => regId !== registrationId,
    );
    this.handleRegistrationSelection();
  }

  resetForm() {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
