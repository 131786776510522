import { ToggleElementsService } from './toggle-elements.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { GlobalAlertService } from './global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

export enum Permission {
  create = 'CREATE',
  read = 'READ',
  update = 'UPDATE',
  delete = 'DELETE',
}

@Injectable()
export class AuthGuard implements CanActivate {
  navigatedEdit$: Subject<boolean>;
  private static hasPermission(permissions, requiredPermissions: Permission[]): boolean {
    if (!requiredPermissions || requiredPermissions.length === 0) {
      return false;
    }
    if (permissions.length === 0) {
      return false;
    }

    return requiredPermissions.every(permission => permissions.includes(permission));
  }

  readonly forbiddenPath = '/forbidden/index.html';
  readonly notFoundPath = '/notFound/index.html';
  private errorTypeForbidden: string;

  constructor(
    private userService: UserService,
    private toggleElements: ToggleElementsService,
    private messagingService: GlobalAlertService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    this.errorTypeForbidden = 'unauthorized';
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const { requiredPermissions } = route.data;
    const permissions = await this.userService.getPermissions();

    if (permissions.length === 0 || !AuthGuard.hasPermission(permissions, requiredPermissions)) {
      const homeUrl = await this.userService.getHomePageUrl();
      if (state.url.includes('edit') || state.url.includes('create')) {
        if (state.url.includes('edit')) {
          this.toggleElements.disable();
          this.messagingService.setError(
            this.translateService.instant('registration.notification.forbidden_edit_fields'),
            5000,
          );
          this.router.navigate([`/`]);
        } else if (state.url.includes('create')) {
          this.messagingService.setError(
            this.translateService.instant('registration.notification.forbidden_create'),
            5000,
          );
          document.location.href = document.location.origin + this.forbiddenPath + '?type=' + this.errorTypeForbidden;
        }
        return false;
      } else if (homeUrl) {
        document.location.href = homeUrl;
        return false;
      }
      document.location.href = document.location.origin + this.forbiddenPath + '?type=' + this.errorTypeForbidden;
      return false;
    } else {
      this.toggleElements.enable();
      return true;
    }
  }
}
