<div
    id="baselinePointsForm"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
    ngModelGroup="baselinePointsForm_{{ index }}"
    data-automation="baselinePointContainer"
>
    <mat-expansion-panel
        (opened)="expandPanel()"
        (closed)="panelOpenedState = false"
        togglePosition="before"
        class="expansion-panel baseline-panel_{{ index }}"
        data-automation="toggleBaselinePointPanel"
        #baselinePanel
    >
        <mat-expansion-panel-header
            class="panel-override"
            collapsedHeight="74px"
            expandedHeight="48px"
            data-automation="baselinePointHeader"
        >
            <mat-panel-title class="baseline-point-title">
                <div data-automation="baselineDisplayLabel">
                    <ng-container>
                        {{ displayLabel }}
                    </ng-container>
                </div>
                <div>
                    <i *ngIf="isViewMode && !isRetired" (click)="handleEdit($event)"
                        ><img
                            class="edit-icon"
                            id="edit_panel_{{ index }}"
                            src="../../../assets/svg/edit-new.svg"
                            alt=""
                    /></i>
                    <i
                        *ngIf="isCreateMode || isEditMode"
                        (click)="deleteBaselinePoint($event, index)"
                        data-automation="deleteBaselinePoint_{{ index }}"
                        ><img
                            class="edit-icon delete-icon"
                            data-index="{{ index }}"
                            id="deleteBaselinePoint_{{ index }}"
                            src="../../../assets/svg/delete.svg"
                            alt=""
                    /></i>
                </div>
            </mat-panel-title>

            <mat-panel-description class="baseline-point-subtitle">
                {{ panelOpenedState ? '' : baselinePointSourceId }}
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="panel-override">
            <!-- Multi-locale name -->
            <div class="form-entry">
                <ng-container *ngIf="isEditMode || isCreateMode">
                    <multi-locale-input
                        name="displayLabels"
                        [inputLabel]="nameLabel"
                        [locales]="localeConfig.supportedLocales"
                        [(ngModel)]="baselinePoint.displayLabels"
                        [ngModelOptions]="{ updateOn: 'blur' }"
                        [displayLabelKey]="localeConfig.displayLabelKey"
                        [localeKey]="localeConfig.localeKey"
                        [defaultLocale]="localeConfig.defaultLocale"
                        [placeholderText]="namePlaceholder"
                        [required]="true"
                        appSelectValid
                        [id]="getDisplayLabelsId()"
                        [maxLength]="500"
                    >
                    </multi-locale-input>
                </ng-container>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'baseline_point.create.name' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="baselinePointName" [matTooltip]="displayLabel">
                        {{ displayLabel }}
                    </mat-label>
                </ng-container>
            </div>
            <!-- Multi-locale description -->
            <div class="form-entry" *ngIf="isCreateMode || isEditMode">
                <multi-locale-input
                    name="descriptions"
                    [inputLabel]="descriptionLabel"
                    [locales]="localeConfig.supportedLocales"
                    [(ngModel)]="baselinePoint.descriptions"
                    [displayLabelKey]="localeConfig.displayLabelKey"
                    [localeKey]="localeConfig.localeKey"
                    [defaultLocale]="localeConfig.defaultLocale"
                    [placeholderText]="descriptionPlaceholder"
                    [id]="getDescriptionLabelsId()"
                >
                </multi-locale-input>
            </div>
            <!-- Data Provider -->
            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'baseline_point.create.data_provider' | translate }}</mat-label>
                    <mat-select
                        id="baseline-dataProvider_{{ index }}"
                        name="baseline-dataProvider_{{ index }}"
                        #dataProvider="ngModel"
                        class="create-select"
                        [ngClass]="{ failure: dataProvider.invalid && (dataProvider.dirty || dataProvider.touched) }"
                        [(ngModel)]="baselinePoint.dataProviderId"
                        data-automation="dataProviderSelector"
                        appSelectValid
                        [class.loading-field]="loadingDataProviders"
                        required
                        (selectionChange)="handleDataProviderChange($event)"
                        placeholder="{{
                            isEditMode && getSelectedDataProviderLabel()
                                ? getSelectedDataProviderLabel()
                                : ('baseline_point.create.placeholder.data_provider' | translate)
                        }}"
                    >
                        <mat-option
                            *ngFor="let provider of dataProviders"
                            [value]="provider.id"
                            id="baseline-dataProvider_{{ index }}_{{ provider.id }}"
                            >{{ provider.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error
                        *ngIf="dataProvider.invalid && (dataProvider.dirty || dataProvider.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="dataProvider.errors.selectValid">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'registration.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingDataProviders" align="end">{{
                        'registration.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="loadingDataProviders" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'baseline_point.create.data_provider' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="dataProvider">
                        {{ getSelectedDataProviderLabel() }}
                    </mat-label>
                </ng-container>
            </div>
            <!-- Channel -->
            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'baseline_point.create.channel' | translate }}</mat-label>
                    <mat-select
                        id="baseline-channel_{{ index }}"
                        name="baseline-channel_{{ index }}"
                        #channel="ngModel"
                        class="create-select"
                        [ngClass]="{ failure: channel.invalid && (channel.dirty || channel.touched) }"
                        [(ngModel)]="baselinePoint.deliveredChannelId"
                        data-automation="channelSelector"
                        (selectionChange)="onChannelChange($event)"
                        [class.loading-field]="loadingChannels"
                        appSelectValid
                        [compareWith]="channelCompare"
                        placeholder="{{ 'baseline_point.create.placeholder.channel' | translate }}"
                        required
                    >
                        <mat-option>{{ 'baseline_point.create.placeholder.channel' | translate }}</mat-option>

                        <mat-option
                            *ngFor="let channel of channels"
                            [value]="channel.id"
                            id="baseline-channel_{{ channel.id }}"
                            [disabled]="checkExistingBaselinePointChannels(channel.id)"
                            >{{ channel.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="channel.invalid && (channel.dirty || channel.touched)" class="alert alert-danger">
                        <mat-error *ngIf="channel.errors.selectValid">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'registration.validation.required' | translate }}
                            </span>
                        </mat-error>
                        <mat-error *ngIf="channel.errors.channelInvalid">
                            <span class="input-title"></span>
                            <span data-automation="channelInvalid">
                                {{ 'registration.validation.channelInvalid' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingChannels" align="end">{{ 'registration.loading' | translate }}</mat-hint>
                    <mat-spinner *ngIf="loadingChannels" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'baseline_point.create.channel' | translate }}:</mat-label>
                    <mat-label class="info-value" [matTooltip]="baselinePointChannel" data-automation="channel">
                        {{ baselinePointChannel }}
                    </mat-label>
                </ng-container>
            </div>
            <!-- Baseline Template -->
            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'baseline_point.create.baseline_template_type' | translate }}</mat-label>
                    <mat-select
                        id="baselineDefinition_{{ index }}"
                        name="baselineDefinition_{{ index }}"
                        #baselineDefinition="ngModel"
                        class="create-select"
                        [ngClass]="{
                            failure:
                                baselineDefinition.invalid && (baselineDefinition.dirty || baselineDefinition.touched)
                        }"
                        [(ngModel)]="baselinePoint.baselineDefinitionId"
                        data-automation="baselineDefinitionSelector"
                        [class.loading-field]="loadingBaselineDefinitions"
                        required
                        [disabled]="!baselineDefinitions || baselineDefinitions.length === 0"
                        placeholder="{{ 'baseline_point.create.placeholder.baseline_template_type' | translate }}"
                    >
                        <mat-option
                            *ngFor="let baselineDefinition of baselineDefinitions"
                            [value]="'' + baselineDefinition.id"
                            id="baselineDefinition_{{ baselineDefinition.id }}"
                            >{{ baselineDefinition.blTemplateName }}</mat-option
                        >
                    </mat-select>
                    <mat-error
                        *ngIf="baselineDefinition.invalid && (baselineDefinition.dirty || baselineDefinition.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="baselineDefinition.errors.selectValid">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'registration.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingBaselineDefinitions" align="end">{{
                        'registration.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="loadingBaselineDefinitions" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">
                        {{ 'baseline_point.create.baseline_template_type' | translate }}:
                    </mat-label>
                    <mat-label class="info-value" data-automation="baselineDefinitionId">
                        {{ getSelectedBaselineDefinitionLabel() | translate }}
                    </mat-label>
                </ng-container>
            </div>
            <!-- Reporting Interval -->
            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'baseline_point.create.reporting_interval' | translate }}</mat-label>
                    <mat-select
                        id="baseline-reportingInterval_{{ index }}"
                        name="baseline-reportingInterval_{{ index }}"
                        #reportingInterval="ngModel"
                        class="create-select"
                        [ngClass]="{
                            failure: reportingInterval.invalid && (reportingInterval.dirty || reportingInterval.touched)
                        }"
                        [(ngModel)]="baselinePoint.reportingInterval"
                        data-automation="reportingIntervalSelector"
                        appSelectValid
                        [class.loading-field]="loadingReportingIntervals"
                        required
                        [compareWith]="reportingIntervalCompare"
                        placeholder="{{ 'baseline_point.create.placeholder.reporting_interval' | translate }}"
                    >
                        <mat-option
                            *ngFor="let interval of reportingIntervals"
                            [value]="interval.durationInMilliseconds"
                            id="baseline-interval_{{ interval.id }}"
                            >{{ interval.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error
                        *ngIf="reportingInterval.invalid && (reportingInterval.dirty || reportingInterval.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="reportingInterval.errors.selectValid">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'registration.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingReportingIntervals" align="end">{{
                        'registration.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="loadingReportingIntervals" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title"
                        >{{ 'baseline_point.create.reporting_interval' | translate }}:</mat-label
                    >
                    <mat-label class="timezone-value info-value" data-automation="reportingInterval">
                        {{
                            getBaselinePointReportingInterval()
                                ? getBaselinePointReportingInterval()
                                : this.baselinePoint.reportingInterval
                        }}
                    </mat-label>
                </ng-container>
            </div>
            <!-- Baseline definition ID -->
            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'baseline_point.create.baseline_definition_id' | translate }}</mat-label>
                    <input
                        matInput
                        id="baselineDefinitionId_{{ index }}"
                        #baselineDefinitionId="ngModel"
                        type="text"
                        [ngClass]="{
                            failure:
                                baselineDefinitionId.invalid &&
                                (baselineDefinitionId.dirty || baselineDefinitionId.touched)
                        }"
                        name="baselineDefinitionId_{{ index }}"
                        placeholder="{{ 'baseline_point.create.placeholder.baseline_definition_id' | translate }}"
                        maxlength="80"
                        [(ngModel)]="baselinePoint.baselineDefinitionId"
                        data-automation="baselineDefinitionId"
                        data-url-persist
                        required
                        appSourceValid
                    />
                    <mat-error
                        *ngIf="
                            baselineDefinitionId.invalid && (baselineDefinitionId.dirty || baselineDefinitionId.touched)
                        "
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="baselineDefinitionId.errors.required">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'registration.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title"
                        >{{ 'baseline_point.create.baseline_definition_id' | translate }}:</mat-label
                    >
                    <mat-label class="info-value" data-automation="baselineDefinitionId">
                        {{ baselinePoint.baselineDefinitionId }}
                    </mat-label>
                </ng-container>
            </div>
            <!--Source ID-->
            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'baseline_point.create.source_id' | translate }}</mat-label>
                    <input
                        matInput
                        id="baseline-sourceId_{{ index }}"
                        #sourceId="ngModel"
                        type="text"
                        [ngClass]="{ failure: sourceId.invalid && (sourceId.dirty || sourceId.touched) }"
                        name="baseline-sourceId_{{ index }}"
                        placeholder="{{ 'baseline_point.create.placeholder.source_id' | translate }}"
                        minlength="6"
                        maxlength="80"
                        pattern="[a-zA-Z0-9-_\s]+"
                        [(ngModel)]="baselinePoint.sourceId"
                        (input)="onSourceIdChange($event)"
                        (paste)="onSourceIdPaste($event)"
                        (blur)="checkSourceId($event)"
                        data-automation="sourceId"
                        data-url-persist
                        required
                        appSourceValid
                    />
                    <mat-error
                        *ngIf="sourceId.invalid && (sourceId.dirty || sourceId.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="sourceId.errors.required">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'registration.validation.required' | translate }}
                            </span>
                        </mat-error>
                        <mat-error *ngIf="sourceId.errors.pattern">
                            <span class="input-title"></span>
                            <span data-automation="patternError">{{
                                'registration.validation.pattern' | translate
                            }}</span>
                        </mat-error>
                        <mat-error *ngIf="sourceId.errors.sourceInvalid">
                            <span class="input-title"></span>
                            <span id="sourceIDInvalid" data-automation="sourceIDInvalid">
                                {{ 'registration.validation.sourceInvalid' | translate }}
                            </span>
                        </mat-error>
                        <mat-error *ngIf="sourceId.errors.minlength">
                            <span class="input-title"></span>
                            <span data-automation="lengthError">
                                {{ 'registration.validation.minLength' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'baseline_point.create.source_id' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="sourceId">
                        {{ baselinePointSourceId }}
                    </mat-label>
                </ng-container>
            </div>
        </div>
    </mat-expansion-panel>
</div>
