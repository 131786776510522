<div class="transform-rule viewing" *ngIf="rule && template">
    <div class="divider" *ngIf="index !== 0"></div>
    <div class="form-wrapper">
        <h4 data-automation="rule-template">{{ template.display_label }}</h4>
        <div class="form-entry form-entry-column">
            <mat-label class="info-title">{{ 'transform_rules.variables' | translate }}:</mat-label>
            <div class="variables">
                <app-transform-rule-variables
                    [index]="index"
                    [(variables)]="rule.variables"
                    [schema]="template.variables"
                    [availabilityPoints]="availabilityPoints"
                ></app-transform-rule-variables>
            </div>
        </div>

        <div class="form-entry form-entry-column">
            <mat-label class="info-title">{{ 'transform_rules.apply' | translate }}:</mat-label>
            <mat-label class="info-value" data-automation="rule-apply">{{
                'transform_rules.apply_' + apply | translate
            }}</mat-label>
        </div>
    </div>
</div>
