import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Injectable()
export class FormValidatorService {
  constructor() {}

  hasRequiredDirtyFields(frmGrp: UntypedFormGroup) {
    let formIsDirty = false;

    if (!frmGrp || (frmGrp.pristine && frmGrp.invalid)) {
      return false;
    }

    for (let controlKey in frmGrp.controls) {
      const control: any = frmGrp.get(controlKey);
      try {
        if (typeof control.controls === 'undefined') {
          if (this.isRequiredField(control)) {
            if (this.isFieldDirty(control)) {
              formIsDirty = true;
              break;
            }
          }
        } else {
          // has controls need to recursively look at controls
          if (this.hasRequiredDirtyFields(control as UntypedFormGroup)) {
            formIsDirty = true;
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    return formIsDirty;
  }
  resetFieldDefaultLocaleField(control) {
    control.reset();
  }
  isRequiredField(control: AbstractControl) {
    if (control.validator) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const validator = control.validator({} as AbstractControl);
      return validator && validator.required;
    }
    return false;
  }

  isFieldDirty(control: AbstractControl) {
    if (
      (control.dirty && control.value !== '' && control.value !== null && control.value !== '-1') ||
      control.invalid
    ) {
      return true;
    }
    return false;
  }

  triggerSourceIdValidation(frmGroup: UntypedFormGroup) {
    let baselinePointsArr: any[] = [];
    let telemetryPointsArr: any[] = [];
    for (const formName in frmGroup?.controls) {
      if (formName.match(/^baselinePointsForm_(\d)*$/) || formName.match(/^telemetryPointsForm_(\d)*$/)) {
        baselinePointsArr.push(formName.match(/^baselinePointsForm_(\d)*$/));
        telemetryPointsArr.push(formName.match(/^telemetryPointsForm_(\d)*$/));
        for (const ctrlValue in frmGroup.controls[formName].value) {
          if (
            (baselinePointsArr.length >= 1 && ctrlValue && (ctrlValue.match(/^baseline-sourceId_(\d)*$/))) || (telemetryPointsArr.length >= 1 && ctrlValue && (ctrlValue.match(/^sourceId_(\d)*$/)))
          ) {
            if (frmGroup.controls[formName].value[ctrlValue]) {
              frmGroup.controls[formName]['controls'][ctrlValue].markAsDirty();
              frmGroup.controls[formName]['controls'][ctrlValue].markAsTouched();
            }
          }
        }
      }
    }
  }

  triggerFormValidation(frmGroup: UntypedFormGroup) {
    if (Object.keys(frmGroup.controls).length > 0) {
      for (const formName in frmGroup?.controls) {
        for (const ctrlValue in frmGroup.controls[formName]['controls']) {
          frmGroup.controls[formName]['controls'][ctrlValue].updateValueAndValidity()
        }
      }
    }
  }

  triggerValidation(frmGroup: UntypedFormGroup) {
    let baselinePointsArr: any[] = [];
    let telemetryPointsArr: any[] = [];
    for (const formName in frmGroup?.controls) {
      if (formName.match(/^baselinePointsForm_(\d)*$/) || formName.match(/^telemetryPointsForm_(\d)*$/)) {
        baselinePointsArr.push(formName.match(/^baselinePointsForm_(\d)*$/));
        telemetryPointsArr.push(formName.match(/^telemetryPointsForm_(\d)*$/));
        for (const ctrlValue in frmGroup.controls[formName].value) {
          if (
            (baselinePointsArr.length === 1 && 
            ctrlValue && 
            (ctrlValue.match(/^channel_(\d)*$/) || 
            ctrlValue.match(/^baseline-sourceId_(\d)*$/) ||
            ctrlValue.match(/^dataProvider_(\d)*$/) || 
            ctrlValue.match(/^reportingInterval_(\d)*$/))) || 
            (telemetryPointsArr.length === 1 &&
            ctrlValue &&
            (ctrlValue.match(/^channel_(\d)*$/) ||
            ctrlValue.match(/^dataProvider_(\d)*$/) ||  
            ctrlValue.match(/^sourceId_(\d)*$/) || 
            ctrlValue.match(/^reportingInterval_(\d)*$/)))
            ) {
            frmGroup.controls[formName].markAsUntouched({
              onlySelf: true,
            });
          }
          if (
            (baselinePointsArr.length >= 1 &&
             ctrlValue &&
             (ctrlValue.match(/^channel_(\d)*$/) ||
             ctrlValue.match(/^baseline-sourceId_(\d)*$/) ||
             ctrlValue.match(/^dataProvider_(\d)*$/) || 
             ctrlValue.match(/^reportingInterval_(\d)*$/))) ||
             (telemetryPointsArr.length >= 1 &&
             ctrlValue &&
             (ctrlValue.match(/^channel_(\d)*$/) ||
             ctrlValue.match(/^dataProvider_(\d)*$/) || 
             ctrlValue.match(/^sourceId_(\d)*$/) ||
             ctrlValue.match(/^reportingInterval_(\d)*$/)))
          ) {
            if (frmGroup.controls[formName].value[ctrlValue]) {
              frmGroup.controls[formName].markAsDirty();
              frmGroup.controls[formName].markAsTouched();
              frmGroup.controls[formName].updateValueAndValidity()
            }
          }
        }
      }
    }
  }



  clearFormControlErrors(frmGroup: UntypedFormGroup) {
    Object.keys(frmGroup.controls).forEach(key => {
      const control: any = frmGroup.get(key);
      if (typeof control.controls === 'undefined') {
        control.setErrors(null);
        control.markAsUntouched({ onlySelf: true });
      } else {
        this.clearFormControlErrors(control);
      }
    });
  }
}
