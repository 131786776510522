<div class="variables" *ngFor="let dvar of display_vars" data-automation="rule-vars-{{ dvar.display_label }}">
    <div *ngIf="schema[dvar.name].type === 'array'">
        <div *ngFor="let item of dvar.value" class="variable-array-item">
            <app-transform-rule-variables
                [variables]="item"
                [schema]="schema[dvar.name].items.properties"
                [availabilityPoints]="availabilityPoints"
            ></app-transform-rule-variables>
        </div>
    </div>
    <div *ngIf="!['object', 'array'].includes(schema[dvar.name].type)">
        <mat-label class="info-title">{{ dvar.display_label }}</mat-label>
        <mat-label class="info-value">{{ dvar.value_display }}</mat-label>
    </div>
</div>
<div *ngIf="display_vars?.length === 0" class="placeholder" data-automation="rule-empty-vars">
    {{ 'transform_rules.no_variables' | translate }}
</div>
