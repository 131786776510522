import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AtlasPagesService } from 'atlas-pages-loader';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from './cookie.service';
import { ApiService } from './api.service';
import { Site } from '../model/site.model';

@Injectable()
export class SitesService {
  atlasPagesService: AtlasPagesService;
  site$ = new BehaviorSubject<any>(null);
  readonly selectedSiteId$ = new BehaviorSubject<string>(null);
  private readonly _loadingSite$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private apiService: ApiService, private httpClient: HttpClient, private cookieService: CookieService) {
    this.initPageLoader();
  }

  initPageLoader() {
    this.atlasPagesService = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  async getSite(id: string): Promise<Site> {
    try {
      this._loadingSite$.next(true);
      let site = await this.apiService.get(`sites/${id}`);
      this.selectedSiteId$.next(id);
      this.site$.next(site);
      return site;
    } catch (err) {
      console.log(`Could not get site`, err);
      throw err;
    }
  }
}
