<div class="app-registration-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>edit_note</mat-icon>
                </div>
                <h1 id="editTitle" data-automation="editTitle">{{ 'registration.bulk.edit.title' | translate }}</h1>
                <p class="x-desktop-only subtitle" data-automation="editSubtitle">
                    {{ 'registration.bulk.edit.subtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                color="gray"
                (click)="handleCancel()"
                data-automation="cancel-bulk-edit"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'registration.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                (click)="handleSubmit()"
                data-automation="submit-bulk-edit"
                [disabled]="bulkForm.status !== 'VALID'"
            >
                <mat-icon *ngIf="!saving">done</mat-icon>
                <div *ngIf="saving" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'registration.button.save' | translate }}</span>
            </button>
        </div>
    </header>

    <br />
    <br />
    <br />

    <ng-container *ngIf="!loadingPage" [ngSwitch]="registrationType">
        <app-bulk-edit-dispatchable-form class="x-card" [bulkForm]="bulkForm" *ngSwitchCase="'dispatchable'">
        </app-bulk-edit-dispatchable-form>
        <app-bulk-edit-settlement-form class="x-card" [bulkForm]="bulkForm" *ngSwitchCase="'settlement'">
        </app-bulk-edit-settlement-form>
    </ng-container>

    <ng-container *ngIf="!loadingPage">
        <app-bulk-list class="x-card"></app-bulk-list>
    </ng-container>
</div>
