/* * * ANGULAR * * */
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* * * SHARED * * */
import { GlobalFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';
import { MultiLocaleModule } from 'multi-locale-input';
import { BulkModule } from './bulk/bulk.module';
import { MaterialModule } from './material.module';

import { MinMaxValidator } from './shared/directives/max-greater-than-min.directive';
import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { TreeSelectorModule } from 'ngx-tree-selector';
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { CookieService } from './shared/services/cookie.service';
import { GlobalAlertService } from './shared/services/global-alert.service';
import { GlobalAlertModule } from './global-alert/global-alert.module';
import { I18nService } from './shared/services/i18n.service';
import { RegistrationsService } from './shared/services/registrations.service';
import { TransformRulesService } from './shared/services/transform-rules.service';
import { DrTypeService } from './shared/services/dr-type.service';

import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';

/* * * COMPONENTS * * */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { ViewComponent } from './view/view.component';
import { DefaultComponent } from './default/default.component';
import { RegistrationDetailsComponent } from './details/registration/registration-details.component';
import { TransformRulesComponent } from './transform-rules/transform-rules.component';
import { TransformRuleComponent } from './transform-rules/transform-rule/transform-rule.component';
import { TransformRuleVariablesComponent } from './transform-rules/transform-rule-variables/transform-rule-variables.component';
import { TransformRuleVariablesEditComponent } from './transform-rules/transform-rule-variables-edit/transform-rule-variables-edit.component';
import { TransformRuleEditComponent } from './transform-rules/transform-rule-edit/transform-rule-edit.component';
import { DeleteDialogComponent } from './dialogs/delete/delete-dialog.component';
import { DeleteBaseLineDialogComponent } from './dialogs/deleteBaseLine/delete-dialog-BaseLine.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RegistrationSetDetailsComponent } from './details/registration-set/registration-set-details.component';
import { BaselinePointDetailsComponent } from './details/baseline-points/baseline-point-details.component';
import { TelemetryPointDetailsComponent } from './details/telemetry-point/telemetry-point-details.component';

import { ApiService } from './shared/services/api.service';
import { DeeplinksService } from './shared/services/deeplinks.service';
import { LocalesService } from './shared/services/locales.service';
import { ProductsService } from './shared/services/products.service';
import { StatusesService } from './shared/services/statuses.service';
import { TimezonesService } from './shared/services/timezones.service';
import { TransformTemplatesService } from './shared/services/transform-templates.service';
import { SelectedSpaceService } from './shared/services/selected-space.service';
import { ProgramsService } from './shared/services/programs.service';
import { PortfoliosService } from './shared/services/portfolios.service';
import { EquipmentService } from './shared/services/equipment.service';
import { FlexibleAssetsService } from './shared/services/flexible-assets.service';
import { SidenavService } from './shared/services/sidenav.service';
import { RegistrationDetailsService } from './shared/services/registration-details.service';
import { OrganizationsService } from './shared/services/organizations.service';
import { RegistrationSetsService } from './shared/services/registration-sets.service';
import { FormValidatorService } from './shared/services/form-validator.service';
import { TelemetryPointService } from './shared/services/telemetry-point.service';
import { BaselinePointService } from './shared/services/baseline-point.service';
import { NavService } from './shared/services/global-nav.service';
import { SupportingDataService } from './shared/services/supporting-data.service';
import { ToggleElementsService } from './shared/services/toggle-elements.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { StartEndDateValidator } from './shared/directives/start-end-date.directive';
import { SourceValidator } from './shared/directives/source-id-valid.directive';
import { SelectValidator } from './shared/directives/select-valid.directive';
import {RegistrationDialogComponent} from "./dialogs/registration/registration-dialog.component";
import { SitesService } from './shared/services/sites.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    CreateComponent,
    EditComponent,
    RegistrationDetailsComponent,
    TelemetryPointDetailsComponent,
    BaselinePointDetailsComponent,
    TransformRulesComponent,
    TransformRuleComponent,
    TransformRuleVariablesEditComponent,
    TransformRuleVariablesComponent,
    TransformRuleEditComponent,
    DeleteDialogComponent,
    DeleteBaseLineDialogComponent,
    ViewComponent,
    DefaultComponent,
    MinMaxValidator,
    StartEndDateValidator,
    RegistrationSetDetailsComponent,
    SourceValidator,
    SelectValidator,
    NotFoundComponent,
    RegistrationDialogComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    GlobalFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalAlertModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    AppRoutingModule,
    MaterialModule,
    BulkModule,
    NgxDeeplinkerModule,
    TreeSelectorModule,
    MultiLocaleModule,
    DragDropModule,
  ],
  providers: [
    TranslateService,
    AnalyticsService,
    NgxCookieService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    HttpClient,
    ApiService,
    ProductsService,
    GlobalAlertService,
    I18nService,
    DrTypeService,
    RegistrationsService,
    TransformRulesService,
    StatusesService,
    TimezonesService,
    TransformTemplatesService,
    LocalesService,
    DeeplinksService,
    SelectedSpaceService,
    ProgramsService,
    PortfoliosService,
    EquipmentService,
    FlexibleAssetsService,
    SidenavService,
    RegistrationDetailsService,
    OrganizationsService,
    FormValidatorService,
    SupportingDataService,
    RegistrationSetsService,
    TelemetryPointService,
    BaselinePointService,
    NavService,
    ToggleElementsService,
    SitesService,
    { provide: NOC_ANALYTICS, useValue: analytics },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
