export class RegistrationSet {
  constructor(
    public id = '',
    public displayLabel = '',
    public displayLabels: any = {},
    public programId = '',
    public defaultLocale = 'en_US',
    public timezone = 'America/New_York',
    public status = 'ACTIVE',
    public drType = 'RegistrationSet',
    public registrationIds = [],
    public descriptions: any = {},
    public description: '',
    public minimumValue: number,
    public maximumValue: number,
    public offerAtSet: boolean = true,
    public percentChange: number,
    public offerDimension?: string,
    public defaultScheduleConf: DefaultScheduleConf = {
      applyDefaultOffers: false,
      defaultScheduleStart: null,
      defaultScheduleEnd: null,
    },
    public registrations?,
  ) {}
}
export class DefaultScheduleConf {
  constructor(
    public applyDefaultOffers: boolean,
    public defaultScheduleStart?: string,
    public defaultScheduleEnd?: string,
  ) {}
}
