import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import * as convertKeys from 'convert-keys';

@Injectable()
export class SupportingDataService {
  dataProviders$: BehaviorSubject<any[]>;
  channels$: BehaviorSubject<any[]>;
  reportingIntervals$: BehaviorSubject<any[]>;
  offerUnits$: BehaviorSubject<any[]>;
  private telemetryWhitelist: String[] = [];
  private baselineWhitelist: String[] = [];

  constructor(private apiService: ApiService) {
    this.dataProviders$ = new BehaviorSubject<any>([]);
    this.channels$ = new BehaviorSubject<any[]>(null);
    this.reportingIntervals$ = new BehaviorSubject<any[]>([]);
    this.offerUnits$ = new BehaviorSubject<any[]>([]);
  }

  async setDataProviders(id: string) {
    try {
      const dataProviders = await this.apiService.get(`data-providers/${id}`);
      this.dataProviders$.next(dataProviders.map(convertKeys.toCamel));
    } catch (err) {
      console.log('Could not fetch data providers ', err);
    }
  }

  async getChannels() {
    try {
      const channels = await this.apiService.get('channel-categories');
      this.channels$.next(convertKeys.toCamel(channels));
    } catch (err) {
      console.log('Could not load channels.', err);
    }
  }

  getBaselineChannels() {
    if(this.channels$.getValue()){
      return this.channels$.getValue().filter(channel => this.baselineWhitelist.includes(channel.id));
    }
    return [];
  }

  getTelemetryChannels() {
    if(this.channels$.getValue()){
      return this.channels$.getValue().filter(channel => this.telemetryWhitelist.includes(channel.id));
    }
    return [];
  }

  async setReportingIntervals() {
    try {
      const reportingIntervals = await this.apiService.get('granularities');
      this.reportingIntervals$.next(convertKeys.toCamel(reportingIntervals));
    } catch (err) {
      console.log('Could not load reporting intervals.', err);
    }
  }

  async getOfferUnits() {
    try {
      const offerUnits = await this.apiService.get('offer-units');
      this.offerUnits$.next(convertKeys.toCamel(offerUnits));
    } catch (err) {
      console.log('Could not load offer units.', err);
    }
  }

  async fetchChannelWhitelists() {
    try {
      const response = await this.apiService.get('point-channel-whitelists');
      const whitelists: any[] = convertKeys.toCamel(response);
      if(whitelists) {
        this.baselineWhitelist = whitelists.find(whitelist => whitelist.pointType == "BASELINE_POINT").channels;
        this.telemetryWhitelist = whitelists.find(whitelist => whitelist.pointType == "TELEMETRY_POINT").channels;
      }
    } catch (err) {
      console.log('Could not load channel whitelists.', err);
    }
  }
}
