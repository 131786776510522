import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { BaselinePoint } from '../model/baseline-point.model';
import { ApiService } from './api.service';
import { BaselineDefinition } from '../model/baseline-definition.model';
import { Registration } from '../model/registration.model';
import * as convertKeys from 'convert-keys';
@Injectable()
export class BaselinePointService {
  redirectUrl: string;
  readonly brokenBaselinePoints$: BehaviorSubject<BaselinePoint[]> = new BehaviorSubject<BaselinePoint[]>([]);
  private brokenBaselinePoints: BaselinePoint[] = [];
  baselinePoints$: Subject<BaselinePoint[]>;
  baselineDefinitions$: BehaviorSubject<BaselineDefinition[]>;
  constructor(private apiService: ApiService) {
    this.apiService = apiService;
    this.baselinePoints$ = new Subject<BaselinePoint[]>();
    this.baselineDefinitions$ = new BehaviorSubject<BaselineDefinition[]>(null);
  }

  get hasBrokenBaselinePoints() {
    return Boolean(this.brokenBaselinePoints$.getValue().length);
  }

  addBrokenBaselinePoint(baselinePoint: BaselinePoint) {
    this.brokenBaselinePoints.push(baselinePoint);
  }

  clearBrokenBaselinePoints() {
    this.brokenBaselinePoints$.next([]);
    this.brokenBaselinePoints = [];
  }

  pushBrokenBaselinePoints() {
    this.brokenBaselinePoints$.next(this.brokenBaselinePoints);
    this.brokenBaselinePoints = [];
  }

  async automaticBaselinePoints(registration: Registration) {
    const payload = {
      displayLabels: registration.displayLabels,
      productId: registration.productId,
      timezone: registration.timezone,
    };
    return await this.apiService.post(`registrations/${registration.id}/generate-baselines`, payload);
  }

  async updateBaselinePoint(baselinePoint: BaselinePoint) {
    try {
      const id = baselinePoint.id;
      const dto = baselinePoint.toPayload();
      return await this.apiService.put(`baseline-points/${id}`, dto);
    } catch (error) {
      baselinePoint.toEdit = true;
      this.addBrokenBaselinePoint(baselinePoint);
    }
  }

  async deleteBaselinePoint(baselinePoint: BaselinePoint) {
    try {
      const id = baselinePoint.id;
      return await this.apiService.delete(`baseline-points/${id}`);
    } catch (error) {
      baselinePoint.deleted = true;
      this.addBrokenBaselinePoint(baselinePoint);
    }
  }

  async createBaselinePoint(regId: string, baselinePoint: BaselinePoint) {
    try {
      return await this.apiService.post(`registrations/${regId}/baseline-points`, baselinePoint.toPayload());
    } catch (e) {
      baselinePoint.toEdit = true;
      this.addBrokenBaselinePoint(baselinePoint);
    }
  }

  async getBaselinePointsForRegistration(id: string) {
    try {
      const resp = await this.apiService.get(`registrations/${id}/baseline-points`);
      return resp.map(point => new BaselinePoint(point));
    } catch (error) {
      console.log('Error fetching baseline points', error);
    }
  }

  async getBaselineDefinitions(productId: string = '') {
    try {
      const resp = await this.apiService.get(`baseline-definitions/?product_id=${productId}`);
      this.baselineDefinitions$.next(resp.map(definition => new BaselineDefinition(convertKeys.toCamel(definition))));
    } catch (e) {
      this.baselineDefinitions$.next([]);
    }
  }

  async createOrUpdateBaselinePoints(regId: string, baselinePoints: BaselinePoint[]) {
    this.clearBrokenBaselinePoints();

    for (const baselinePoint of baselinePoints) {
      if (baselinePoint.deleted) {
        if (baselinePoint.id) {
          await this.deleteBaselinePoint(baselinePoint);
        }
      } else if (baselinePoint.id) {
        await this.updateBaselinePoint(baselinePoint);
      } else {
        await this.createBaselinePoint(regId, baselinePoint);
      }
    }
    const total = baselinePoints.length;
    const failed = this.brokenBaselinePoints.length;

    if (this.brokenBaselinePoints.length) {
      this.brokenBaselinePoints = [
        ...this.brokenBaselinePoints,
        ...(await this.getBaselinePointsForRegistration(regId)),
      ];
    }
    this.pushBrokenBaselinePoints();
    return { total: total, failed: failed };
  }
}
