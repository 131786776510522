import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { DispatchConfig, Registration, MarketConfig } from '../../shared/model/registration.model';
import { BaselinePoint } from '../../shared/model/baseline-point.model';
import { TelemetryPoint } from '../../shared/model/telemetry-point.model';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { Status } from '../../shared/model/status.model';
import { LOAStatus } from '../../shared/model/loa-status.model';
import { Timezone } from '../../shared/model/timezone.model';
import { SDP } from '../../shared/model/sdp.model';
import { Product } from '../../shared/model/product.model';
import { ProductsService } from '../../shared/services/products.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { RegistrationsService } from '../../shared/services/registrations.service';
import { RegistrationDetailsService } from '../../shared/services/registration-details.service';
import { TransformRule } from '../../shared/model/transform-rule.model';
import { LocalesService } from '../../shared/services/locales.service';
import { StatusesService } from '../../shared/services/statuses.service';
import { TimezonesService } from '../../shared/services/timezones.service';
import { DeeplinksService } from '../../shared/services/deeplinks.service';
import { PortfoliosService } from '../../shared/services/portfolios.service';
import { FlexibleAsset } from '../../shared/model/flexible-asset.model';
import { FlexibleAssetsService } from '../../shared/services/flexible-assets.service';
import { SupportingDataService } from 'src/app/shared/services/supporting-data.service';
import { TelemetryPointService } from 'src/app/shared/services/telemetry-point.service';
import { FormValidatorService } from '../../shared/services/form-validator.service';
import { BaselinePointService } from 'src/app/shared/services/baseline-point.service';
import { BaselineDefinition } from '../../shared/model/baseline-definition.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RegistrationSetsService } from 'src/app/shared/services/registration-sets.service';
import { SitesService } from '../../shared/services/sites.service';
import { Site } from '../../shared/model/site.model';

@Component({
  selector: 'app-registration',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class RegistrationDetailsComponent implements OnInit, OnDestroy {
  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  CREATE_NEW_PRODUCT = 'Create New Product';
  CREATE_NEW_FLEXIBLE_ASSET = 'Create New Flexible Asset';
  CREATE_NEW_SITE = 'Create New Site';
  REQUIRED = 'required';

  clone = false;
  org = '';
  sdps = [];
  selectedSDP: SDP;
  selectedAsset: FlexibleAsset;
  selectedProduct: Product;
  locales: Locale[] = [];
  statuses: Status[] = [];
  loaStatuses: LOAStatus[] = [];
  loaStatus: string;
  timezones: Timezone[] = [];
  appPrefix = 'reg';
  deeplinkerData: any = {};
  displayLabel = 'displayLabel';
  marketDeeplinkerConfig: any = {};
  customerDeeplinkerConfig: any = {};
  assetDeeplinkerConfig: any = {};
  assetAdminURL: string;
  customerAdminURL: string;
  registrationName = '';
  energyReductionPlan = '';
  selectedSite: '';
  selectedSite_label: string;
  registrationTypes: any[] = [];
  ecrmPattern = /^[A-Za-z0-9]{18}$/;
  cloneRegistration;
  ownerId: string;
  site: Site;

  // Telemetry Points
  selectedChannels: any[] = [];
  selectedDataProvider: string;
  loadingDataProviders = true;
  sourceIds: any[] = [];
  reportingIntervals: any[];
  dataProviders: any[];
  description: any[] = [];
  // Baseline Points
  baselineDefinitions: BaselineDefinition[] = [];
  selectedBaselineChannels: any[] = [];
  userLocale = 'en_US';

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  cloneLabel = 'Clone';
  namePlaceholder = '';
  nameLabel = '';
  descriptionPlaceholder = '';
  descriptionLabel = '';
  planPlaceholder = '';
  planLabel = '';
  errorTooltip = false;

  private _mode: string;
  private _registration: Registration;
  private _baselinePoints: BaselinePoint[] = [];
  private _points: TelemetryPoint[] = [];
  private _data: any = {};
  private _isRetired = false;
  private subscriptions: Subscription[] = [];
  MinMaxValue: any;
  currentRegId: any;
  erpIconTooltip: MatIconRegistry;
  erpWarningIconTooltip: MatIconRegistry;
  regBaselineDefinition;

  sdp_label_to_display: string = '';
  sdp_changed: boolean = false;

  constructor(
    private productsService: ProductsService,
    private ngxCookieService: NgxCookieService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private route: ActivatedRoute,
    private registrationsService: RegistrationsService,
    private registrationsDetailService: RegistrationDetailsService,
    private localesService: LocalesService,
    private statusesService: StatusesService,
    private timezonesService: TimezonesService,
    private orgSelectorService: ContextSelectorService,
    private deeplinksInternalService: DeeplinksService,
    private translateService: TranslateService,
    private portfoliosService: PortfoliosService,
    private flexibleAssetService: FlexibleAssetsService,
    private portfolioService: PortfoliosService,
    private _adapter: DateAdapter<any>,
    private supportingDataService: SupportingDataService,
    private telemetryPointService: TelemetryPointService,
    private baselinePointService: BaselinePointService,
    private formValidatorService: FormValidatorService,
    private sitesService: SitesService,
    private form: NgForm,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.erpIconTooltip = iconRegistry.addSvgIcon(
      'general-info',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/general-info.svg'),
    );

    this.erpWarningIconTooltip = iconRegistry.addSvgIcon(
      'warning',
      sanitizer.bypassSecurityTrustResourceUrl('assets/svg/warning.svg'),
    );

    this._registration = new Registration();
    this._registration.descriptions = {};
    this.org = this.ngxCookieService.getCookie('preferred-org-id') || '';
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this._adapter.setLocale(this.userLocale);

    const routeSub = this.route.queryParams.subscribe(async queryParams => {
      if (queryParams.clone) {
        this.clone = true;
        this.cloneRegistration = queryParams.clone;
      }
    });

    const contextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      this.org = org.id;
      if (org.id) {
        this.loadingDataProviders = true;
        await this.supportingDataService.setDataProviders(org.id);
        this.loadingDataProviders = false;
      }
    });

    const statusesSub = this.statusesService.statuses$.subscribe(async statuses => {
      if (statuses) {
        this.statuses = statuses;
      }
    });

    const timezonesSub = this.timezonesService.timezones$.subscribe(async timezones => {
      if (timezones) {
        this.timezones = timezones;
      }
    });

    const loaStatusesSub = this.statusesService.loaStatuses$.subscribe(async loaStatuses => {
      if (loaStatuses) {
        this.loaStatuses = loaStatuses;
      }
    });

    const registrationTypesSub = this.registrationsService.registrationTypes$.subscribe(async registrationTypes => {
      if (registrationTypes) {
        this.registrationTypes = registrationTypes;
      }
    });

    const dataProvidersSub = this.supportingDataService.dataProviders$.subscribe(async dataProviders => {
      if (dataProviders) {
        this.dataProviders = dataProviders;
      }
    });

    const siteDeeplinkSub = this.deeplinksInternalService.customersDeeplink$.subscribe(async deeplinkUrl => {
      if (deeplinkUrl) {
        this.customerDeeplinkerConfig.destinationUrl = deeplinkUrl;
        this.customerAdminURL = deeplinkUrl;
      }
    });

    const marketDeeplinkSub = this.deeplinksInternalService.marketDeeplink$.subscribe(async deeplinkUrl => {
      if (deeplinkUrl) {
        this.marketDeeplinkerConfig.destinationUrl = deeplinkUrl;
      }
    });

    const assetDeeplinkSub = this.deeplinksInternalService.assetDeeplink$.subscribe(async deeplinkUrl => {
      if (deeplinkUrl) {
        this.assetDeeplinkerConfig.destinationUrl = deeplinkUrl + '/create';
        this.assetAdminURL = deeplinkUrl;
      }
    });

    const sdpsSub = this.productsService.sdps$.subscribe(sdps => {
      if (sdps) {
        this.sdps = sdps;
        this._data.loadingSDPs = false;
      }
    });

    const routeParamsSub = this.route.params.subscribe(async params => {
      if (params && params.id) {
        this.currentRegId = params.id;
      }
    });

    const reportingIntervalsSub = this.supportingDataService.reportingIntervals$.subscribe(reportingIntervals => {
      reportingIntervals.sort((a: any, b: any) => {
        if (parseInt(a.id, 10) < parseInt(b.id, 10)) {
          return -1;
        } else if (parseInt(a.id, 10) > parseInt(b.id, 10)) {
          return 1;
        } else {
          return 0;
        }
      });

      this.reportingIntervals = reportingIntervals;
    });

    const baselineDefSub = this.baselinePointService.baselineDefinitions$.subscribe(
      (baselineDefinitions: BaselineDefinition[]) => {
        if (this.registration.productId) {
          this.baselineDefinitions = baselineDefinitions;
          if (baselineDefinitions && baselineDefinitions.length > 0 && !this.regBaselineDefinition) {
            const defaultBaseline = baselineDefinitions.filter(baselineDef => baselineDef.defaultInd == true);
            if (defaultBaseline.length == 1) {
              this.regBaselineDefinition = defaultBaseline[0].id;
            }
          }
        }
      },
    );

    this.translateService.get('registration.create.button.create_new_product').subscribe((result: string) => {
      this.CREATE_NEW_PRODUCT = result;
      this.CREATE_NEW_FLEXIBLE_ASSET = this.translateService.instant(
        'registration.create.button.create_new_flexible_asset',
      );
      this.REQUIRED = this.translateService.instant('registration.validation.required');
      this.cloneLabel = this.translateService.instant('registration.create.clone');
      this.nameLabel = this.translateService.instant('registration.registration_name');
      this.namePlaceholder = this.translateService.instant('registration.create.placeholder.registration_name');
      this.descriptionLabel = this.translateService.instant('registration.description');
      this.descriptionPlaceholder = this.translateService.instant('registration.create.placeholder.description');
      this.planLabel = this.translateService.instant('registration.energy_reduction_plan');
      this.planPlaceholder = this.translateService.instant('registration.create.placeholder.energy_reduction_plan');
      this.setDeeplinkConfigs();
    });

    this.subscriptions.push(
      ...[
        contextSub,
        routeSub,
        routeParamsSub,
        statusesSub,
        timezonesSub,
        loaStatusesSub,
        registrationTypesSub,
        reportingIntervalsSub,
        sdpsSub,
        assetDeeplinkSub,
        marketDeeplinkSub,
        siteDeeplinkSub,
        dataProvidersSub,
        baselineDefSub,
      ],
    );
  }

  @Input() transformRules: TransformRule[];

  @Input() availabilityPoints?: { id: string; display_label: string }[];

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  @Input()
  set appData(appData: any) {
    this._data = appData;
  }

  get appData() {
    return this._data;
  }

  get registration() {
    return this._registration;
  }

  get points(): TelemetryPoint[] {
    return this._points;
  }
  get baselinePoints(): BaselinePoint[] {
    return this._baselinePoints;
  }
  set isRetired(status) {
    this._isRetired = status;
  }

  get isRetired() {
    return this._isRetired;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  get hasPoints() {
    return Boolean(this._points && this._points.find(p => !p.deleted));
  }
  get hasBaselinePoints() {
    return Boolean(this._baselinePoints && this._baselinePoints.find(p => !p.deleted));
  }

  async ngOnInit() {
    const baselineSub = this.baselinePointService.brokenBaselinePoints$.subscribe(async baselinePoints => {
      if (this.isEditMode) {
        const brokenPointsIds = baselinePoints.filter(point => point.toEdit).map(point => point.id);
        const existingPoints = baselinePoints.filter(point => !brokenPointsIds.includes(point.id));
        const brokenPoints = baselinePoints.filter(point => point.toEdit);
        this._baselinePoints = [...existingPoints, ...brokenPoints];
        this.updateSelectedBaselinePointChannels();
      }
    });
    const telemetrySub = this.telemetryPointService.brokenPoints$.subscribe(async telemetryPoints => {
      if (this.isEditMode) {
        const brokenPointsIds = telemetryPoints.filter(point => point.toEdit).map(point => point.id);
        const existingPoints = telemetryPoints.filter(point => !brokenPointsIds.includes(point.id));
        const brokenPoints = telemetryPoints.filter(point => point.toEdit);
        this._points = [...existingPoints, ...brokenPoints];
        this.updateSelectedChannels();
      }
    });
    const registrationSub = this.registrationsDetailService.registration$.subscribe(async (reg: Registration) => {
      if (this.clone && this.isCreateMode) {
        this._points = await this.telemetryPointService.getPointsForRegistration(this.cloneRegistration);
        if (this._points && Array.isArray(this._points)) {
          this._points.forEach(value => {
            value.id = null;
            value.registrationId = null;
          });
        }
        this._baselinePoints = await this.baselinePointService.getBaselinePointsForRegistration(this.cloneRegistration);
        if (this._baselinePoints && Array.isArray(this._baselinePoints)) {
          this.baselinePoints.forEach(value => {
            value.id = null;
            value.registrationId = null;
          });
        }
      }
      if (reg) {
        if ((this.clone || !this.isCreateMode) && reg.id) {
          // Avoid cloning telemetry / baseline points
          await this.populateRegistration(reg);

          if (!this.clone && this.currentRegId === reg.id) {
            // If in view don't bother with broken points
            if (this.isViewMode) {
              this._points = await this.telemetryPointService.getPointsForRegistration(reg.id);
              this._baselinePoints = await this.baselinePointService.getBaselinePointsForRegistration(reg.id);
            }
            // Only call points if there is no broken points
            if (this.isEditMode && !this.telemetryPointService.hasBrokenPoints) {
              this._points = await this.telemetryPointService.getPointsForRegistration(reg.id);
              this.updateSelectedChannels();
            }

            if (this.isEditMode && !this.baselinePointService.hasBrokenBaselinePoints) {
              this._baselinePoints = await this.baselinePointService.getBaselinePointsForRegistration(reg.id);
              this.updateSelectedBaselinePointChannels();
            }
          }
        }
      }
    });

    const localesSub = this.localesService.locales$.subscribe(locales => {
      if (locales?.length) {
        this.locales = locales;
        const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
        this.multiLocaleConfig.defaultLocale = locale;

        if (this.isCreateMode) {
          this.multiLocaleConfig.supportedLocales = [locale];
          this.registration.defaultLocale = this.userLocale;
        } else if (this.isEditMode) {
          this.buildSupportedLocales();
        }
      }
    });

    this.subscriptions.push(baselineSub, telemetrySub, registrationSub, localesSub);

    this.timezonesService.fetchTimezones();
    this.statusesService.fetchStatuses();
    this.statusesService.fetchLOAStatuses();
    this.localesService.fetchLocales();
    this.registrationsService.fetchRegistrationTypes();
    this.deeplinksInternalService.setMarketAdminUrl();
    this.deeplinksInternalService.setAssetAdminUrl();
    this.deeplinksInternalService.setCustomersAdminUrl();
    this.supportingDataService.fetchChannelWhitelists();

    const deeplinkerData = this.ngxDeeplinkerService.getReturnData(this.appPrefix);
    if (deeplinkerData) {
      if (Object.keys(deeplinkerData).length) {
        const {
          name,
          regId,
          sdp,
          product,
          assetId,
          anticipatedKW,
          description,
          maxConsecutiveDays,
          maxConsecutiveHours,
          maxEventsPerSeason,
          maxHoursPerSeason,
          maxHoursPerDay,
          maximumValue,
          minimumValue,
          minutesBetweenEvents,
          isFullReg,
          crmId,
          registrationType,
          controlType,
          portfolioId,
          effectiveDttm,
          terminationDttm,
          site,
          controlTimeoutOverride,
          controlTimeOverride,
          restoreTimeOverride,
          initialNotifTimeOverride,
          restoreNotifTimeOverride,
          dropToValue,
          rampUpRate,
          rampDownRate,
          baselineValue,
          energyReductionPlan,
          projectManagerName,
          projectManagerId,
          accountManagerName,
          accountManagerId,
          readyToTest,
          locationId,
        } = deeplinkerData;

        if (site) {
          await this.onSiteChange(site);
        }

        this._registration.name = name || this._registration.name;
        this._registration.productId = product || this._registration.productId;
        this._registration.sdpId = sdp || this._registration.sdpId;
        this._registration.regId = regId || this._registration.regId;
        this._registration.crmId = crmId || this._registration.crmId;
        this._registration.description = description || this._registration.description;
        this.selectedSite = site;

        if (isFullReg === 'true') {
          this.toggleFullRegistration();
          this._registration.flexibleAssetId = assetId || this._registration.flexibleAssetId;
          this._registration.portfolioId = portfolioId || this._registration.portfolioId;
          this._registration.registrationType = registrationType || this._registration.registrationType;
          this._registration.maximumValue = maximumValue || this._registration.maximumValue;
          this._registration.minimumValue = minimumValue || this._registration.minimumValue;
          this._registration.effectiveDttm = effectiveDttm || this._registration.effectiveDttm;
          this._registration.terminationDttm = terminationDttm || this._registration.terminationDttm;
          this._registration.controlType = controlType || this._registration.controlType;
          this._registration.controlTimeoutOverride =
            controlTimeoutOverride || this._registration.controlTimeoutOverride;
          this._registration.controlTimeOverride = controlTimeOverride || this._registration.controlTimeOverride;
          this._registration.restoreTimeOverride = restoreTimeOverride || this._registration.restoreTimeOverride;
          this._registration.initialNotifTimeOverride =
            initialNotifTimeOverride || this._registration.initialNotifTimeOverride;
          this._registration.restoreNotifTimeOverride =
            restoreNotifTimeOverride || this._registration.restoreNotifTimeOverride;
          this._registration.dispatchConf = new DispatchConfig();
          this._registration.marketConf = new MarketConfig();
          this._registration.marketConf.locationId = locationId || this._registration.marketConf.locationId;
          this._registration.dispatchConf.anticipatedKw =
            anticipatedKW || this._registration.dispatchConf.anticipatedKw;
          this._registration.dispatchConf.maxConsecutiveDays =
            maxConsecutiveDays || this._registration.dispatchConf.maxConsecutiveDays;
          this._registration.dispatchConf.maxConsecutiveHours =
            maxConsecutiveHours || this._registration.dispatchConf.maxConsecutiveHours;
          this._registration.dispatchConf.maxEventsPerSeason =
            maxEventsPerSeason || this._registration.dispatchConf.maxEventsPerSeason;
          this._registration.dispatchConf.maxHoursPerSeason =
            maxHoursPerSeason || this._registration.dispatchConf.maxHoursPerSeason;
          this._registration.dispatchConf.minutesBetweenEvents =
            minutesBetweenEvents || this._registration.dispatchConf.minutesBetweenEvents;
          this._registration.maxHoursPerDay = maxHoursPerDay || this._registration.maxHoursPerDay;
          this._registration.dropToValue = dropToValue || this._registration.dropToValue;
          this._registration.dispatchConf.rampUpRate = rampUpRate || this._registration.dispatchConf.rampUpRate;
          this._registration.dispatchConf.rampDownRate = rampDownRate || this._registration.dispatchConf.rampDownRate;
          this._registration.baselineValue = baselineValue || this._registration.baselineValue;
          this._registration.energyReductionPlan = energyReductionPlan || this._registration.energyReductionPlan;
          this._registration.projectManagerName = projectManagerName || this._registration.projectManagerName;
          this._registration.projectManagerId = projectManagerId || this._registration.projectManagerId;
          this._registration.accountManagerName = accountManagerName || this._registration.accountManagerName;
          this._registration.accountManagerId = accountManagerId || this._registration.accountManagerId;
          this._registration.readyToTest = readyToTest || this._registration.readyToTest;
        }
      }
    }
  }

  async populateRegistration(registrationData) {
    setTimeout(() => {
      this.appData.isFullReg = registrationData.fullReg;
    });

    this._registration = { ...registrationData };
    this.updateRegistrationName('', false);

    if (registrationData.baselineDefId) {
      this.regBaselineDefinition = registrationData.baselineDefId;
    }

    if (registrationData.sdpId && registrationData.sdpId !== '') {
      this.selectedSDP = await this.productsService.fetchSDP(registrationData.sdpId);
    }

    if (registrationData.sdpId && registrationData.sdpId !== '') {
      this.loaStatus = await this.registrationsService.fetchLoaStatus(registrationData.sdpId);
      if (this.loaStatus) {
        this._registration.loaStatus = this.loaStatus;
      }
    }

    if (registrationData.flexibleAssetId && registrationData.flexibleAssetId !== -1) {
      const asset: any = await this.flexibleAssetService.fetchFlexibleAsset(registrationData.flexibleAssetId);
      this.selectedSite = asset.ownerId;
      this.ownerId = asset.ownerId;
      this.site = await this.sitesService.getSite(this.ownerId);
      if (this.isEditMode || this.clone) {
        this.onSiteChange(asset.ownerId);
      }
      if (this.clone) {
        this.selectedSite = asset.ownerId;
        await this.onSiteChange(asset.ownerId);
        this._registration.flexibleAssetId = null;
      } else {
        this.selectedAsset = asset;
        this._registration.flexibleAssetId = asset.id;
      }
    }

    if (registrationData.programId) {
      this.appData.loadingPortfolios = true;
      this.portfolioService.setPortfolios(registrationData.programId);
    }

    if (registrationData.status && registrationData.status === 'RETIRED') {
      if (!this.clone) {
        this.isRetired = true;
      }
    } else {
      this.isRetired = false;
    }

    if (this.clone) {
      this._registration.status = 'ACTIVE';
      delete this._registration.id;
      this._registration.displayLabels = { [this.userLocale]: `${this._registration.name} ${this.cloneLabel}` };
      this._registration.regId = null;
      this._registration.crmId = null;
      this._registration.registrationExternalReferenceId = null;
      this._registration.registrationSetId = null;
    }

    if (this._registration.productId) {
      this.baselinePointService.getBaselineDefinitions(this._registration.productId);
    }

    this.buildSupportedLocales();
    this.formValidatorService.triggerFormValidation(this.form.form);
  }

  updateRegistrationName(nameChanged: any, changedEvent: boolean) {
    if (changedEvent) {
      this.registrationName = nameChanged[this.userLocale];
    } else {
      this.registrationName = this._registration.displayLabels[this.userLocale];
    }
  }

  updateEnergyReductionPlan(planChanged: any, changedEvent: boolean) {
    if (changedEvent) {
      this.energyReductionPlan = planChanged[this.userLocale];
    } else {
      this.energyReductionPlan = this._registration.energyReductionPlan[this.userLocale];
    }
  }

  onChangeName(nameChanged: any) {
    let changedEvent = true;
    this.updateRegistrationName(nameChanged, changedEvent);
  }

  onChangePlan(planChanged: any) {
    let changedEvent = true;
    this.updateEnergyReductionPlan(planChanged, changedEvent);
  }

  resetSdps() {
    this.productsService.resetSdps();
  }

  setDeeplinkConfigs() {
    this.marketDeeplinkerConfig = {
      appPrefix: 'reg',
      destinationUrl: '',
      idToPopulate: 'product',
      displayLabel: this.CREATE_NEW_PRODUCT,
    };
    this.assetDeeplinkerConfig = {
      appPrefix: 'reg',
      destinationUrl: '',
      idToPopulate: 'asset',
      displayLabel: this.CREATE_NEW_FLEXIBLE_ASSET,
    };
    this.customerDeeplinkerConfig = {
      appPrefix: 'reg',
      destinationUrl: '',
      idToPopulate: 'site',
      displayLabel: this.CREATE_NEW_SITE,
    };
  }

  resetDeeplinkerData() {
    this.deeplinkerData = {};
  }

  resetForm() {
    this.resetSdps();
    this.resetDeeplinkerData();
  }

  getProductForRegistration() {
    if (this._data.products && this._data.products.length > 0 && this._registration.productId) {
      return (
        this._data.products.find(product => this._registration.productId.indexOf(product.id) !== -1) || new Product()
      );
    }
    return new Product();
  }

  getDefinitionForRegistration() {
    // Check if baselineDefinitions is empty or not defined
    if (!this.baselineDefinitions || this.baselineDefinitions.length === 0) {
      return 'registration.no_baseline_definitions';
    }
    let selectedBaselineDef = null;
    // Check if a baseline definition ID is set for registration
    if (this._registration.baselineDefId) {
      selectedBaselineDef = this.baselineDefinitions.find(def => def.id === this._registration.baselineDefId);
      if (selectedBaselineDef) {
        return selectedBaselineDef.displayLabel || selectedBaselineDef.blTemplateName;
      } else {
        return 'registration.baseline_definition_id_not_found';
      }
    }
    // Find a default baseline definition if no ID is set
    selectedBaselineDef = this.baselineDefinitions.find(def => def.defaultInd === true);
    if (selectedBaselineDef) {
      return selectedBaselineDef.displayLabel || selectedBaselineDef.blTemplateName;
    }
    // Return a not found message if no default is found
    return 'registration.default_baseline_definition_not_found';
  }

  getBaselineDefinitionsTooltip() {
    // Check if baselineDefinitions is empty or not defined
    if (!this.baselineDefinitions || this.baselineDefinitions.length === 0) {
      this.errorTooltip = true;
      return 'registration.tooltip.no_definitions_configured';
    }
    // Check if a baseline definition ID is set for registration
    if (this._registration.baselineDefId) {
      const baselineDefById = this.baselineDefinitions.find(def => def.id === this._registration.baselineDefId);
      if (baselineDefById) {
        this.errorTooltip = false;
        return 'registration.tooltip.baseline_definitions';
      }
      this.errorTooltip = true;
      return 'registration.tooltip.no_baseline_definition_found';
    }

    // If no specific ID is set, check for default definitions
    const defaultDefs = this.baselineDefinitions.filter(def => def.defaultInd === true);
    if (defaultDefs.length === 0) {
      this.errorTooltip = true;
      return 'registration.tooltip.no_default_definition_found';
    }
    if (defaultDefs.length > 1) {
      this.errorTooltip = true;
      return 'registration.tooltip.more_than_one_default_definition';
    }
    this.errorTooltip = false;
    return 'registration.tooltip.baseline_definition';
  }

  getBaselineDefinitionPlaceholder() {
    if (!this.baselineDefinitions || this.baselineDefinitions.length === 0) {
      return 'registration.no_baseline_definitions';
    }
    return 'registration.select_baseline_definition';
  }

  getTimezoneForRegistration() {
    if (this.timezones && this.timezones.length > 0) {
      return (
        this.timezones.find(timezone => this._registration.timezone?.indexOf(timezone.timezoneName) !== -1) ||
        new Timezone()
      );
    }
    return new Timezone();
  }

  getStatusForRegistration() {
    if (this.statuses && this.statuses.length > 0) {
      return this.statuses.find(status => this._registration.status === status.code) || new Status();
    }
    return new Status();
  }

  getLOAStatusForRegistration() {
    if (this.loaStatuses && this.loaStatuses.length > 0) {
      return this.loaStatuses.find(loaStatus => this._registration.loaStatus === loaStatus.code);
    }
    return;
  }

  getLocaleForRegistration() {
    if (this.locales && this.locales.length > 0) {
      return (
        this.locales.find(locale => this._registration.defaultLocale.indexOf(locale.localeName) !== -1) || new Locale()
      );
    }
    return new Locale();
  }

  getSDPDisplayLabel() {
    if (this._registration.sdpId && this.selectedSDP) {
      return this.selectedSDP.displayLabel;
    }
    return;
  }

  filterSDPsByActiveSA(SDPs: any[]) {
    const activeSDPs = SDPs.filter(sdp => {
     const SAs: any[] = sdp.serviceAccounts;
     const activeSAs = SAs.filter(sa => {
       return sa.status === 'ACTIVE';
     });
     return activeSAs.length > 0
    });
    return activeSDPs;
  }

  getAssetDisplayLabel() {
    if (this._registration.flexibleAssetId && this.selectedAsset) {
      return this.selectedAsset.displayLabel;
    }
    return;
  }

  getAssetId() {
    if (this._registration) {
      return this._registration.flexibleAssetId;
    }
  }

  getPortfolioForRegistration() {
    if (this._data.portfolios && this._data.portfolios.length > 0 && this._registration.portfolioId) {
      const foundPortfolio = this._data.portfolios.find(portfolio => portfolio.id === this._registration.portfolioId);
      if (foundPortfolio) {
        return foundPortfolio.displayLabel;
      }
    }
    return '';
  }

  getControlTypeForRegistration() {
    if (this._data.controlTypes && this._data.controlTypes.length > 0 && this._registration.controlType) {
      const foundControlType = this._data.controlTypes.find(
        controlType => controlType.code === this._registration.controlType,
      );
      if (foundControlType) {
        return foundControlType.displayLabel;
      }
    }
    return '';
  }

  getRegistrationTypeDisplayLabel() {
    if (this.registrationTypes && this.registrationTypes.length > 0 && this._registration.registrationType) {
      const foundRegType = this.registrationTypes.find(type => type.code === this._registration.registrationType);
      if (foundRegType) {
        return foundRegType.displayLabel;
      }
    }
    return '';
  }

  async onSiteChange(siteId: string) {
    if (!siteId || siteId === '') {
      return;
    }
    if (!location.href.includes('create') && !location.href.includes('edit')) {
      this.site = await this.sitesService.getSite(siteId);
    }
    this._data.loadingAssets = true;
    void this.flexibleAssetService.fetchFlexibleAssets(siteId);
    this._data.loadingSDPs = true;
    this.ownerId = siteId;
    this.productsService.fetchSDPs(siteId);
    this._data.sites.forEach(site => {
      if (site.id === siteId) {
        this.selectedSite_label = site.displayLabel;
      }
    });
    if (!this.clone) {
      this._registration.sdpId = '';
      this._registration.flexibleAssetId = '';
    }
  }

  onSdpChange(ids) {
    this.sdp_changed = true;
    const sdpId = ids.split('_')[0];
    const said = ids.split('_')[1];
    this.sdps.forEach(sdp => {
      if (sdp.id === sdpId) {
        sdp.serviceAccounts.forEach(sa => {
          if (sa.id === said) {
            this.sdp_label_to_display = sa.displayLabel;
          }
        });
      }
    });
  }

  onProductChange($event) {
    if (this._data.products && this._data.products.length > 0) {
      const product = this._data.products.find(product => {
        return product.id === $event.value;
      });

      if (product) {
        this.regBaselineDefinition = null;
        this.selectedProduct = product;
        this._data.loadingPortfolios = true;
        this._data.loadingBaselineDefinitions = true;
        this._registration.programId = product.programId;
        this._registration.portfolioId = '';
        this.portfoliosService.setPortfolios(product.programId);
        this.baselinePointService.getBaselineDefinitions(product.id);
      }
    }
  }

  toggleFullRegistration() {
    this._registration.fullReg = !this._registration.fullReg;
    if (!this._registration.dispatchConf) {
      this._registration.dispatchConf = new DispatchConfig();
    }
    if (!this._registration.marketConf) {
      this._registration.marketConf = new MarketConfig();
    }
    if (!this._registration.preauthorized) {
      this._registration.preauthorized = true;
    }
    if (this.isEditMode) {
      this.formValidatorService.triggerValidation(this.form.form);
    }
  }

  getAssetAdminUrl(assetId, page) {
    if (this.assetAdminURL) {
      return `${this.assetAdminURL}/details/${assetId}/${page}`;
    }
  }

  getCustomerAdminUrl(siteId: string) {
    if (this.customerAdminURL) {
      return `${this.customerAdminURL}/details/${siteId}/view?type=site`;
    }
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    } else if (this.multiLocaleConfig.supportedLocales.length === 1) {
      this.formValidatorService.resetFieldDefaultLocaleField(
        this.form.form['controls']['app-registration']['controls'].defaultLocale,
      );
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this.registration && this.locales) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.registration.displayLabels);
      for (let key of keys) {
        const localeFound = this.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  pointPlaceholder(): TelemetryPoint {
    return new TelemetryPoint({
      channel_id: '-1',
      data_provider: '-1',
      default_locale: this._registration.defaultLocale,
      delivered_channel_id: '-1',
      display_labels: { en_US: '' },
      reporting_interval: '-1',
      registration_id: this._registration.id,
      source_id: '',
      timezone: this._registration.timezone,
      vee_bypass: true,
    });
  }

  baselinePointPlaceholder(): BaselinePoint {
    return new BaselinePoint({
      display_labels: { en_US: '' },
      channel_id: '-1',
      baseline_definition_id: '',
      dataprovider_id: '-1',
      default_locale: this._registration.defaultLocale,
      reporting_interval: '-1',
      registration_id: this._registration.id,
      source_id: '',
      status: 'ACTIVE',
      timezone: this._registration.timezone,
      vee_bypass: true,
    });
  }

  addDynamicPoint() {
    const point: TelemetryPoint = this.pointPlaceholder();
    this.updateSelectedChannels();
    if (this._points) {
      this._points.push(point);
    } else {
      this._points = [point];
    }
  }

  deleteDynamicPoint(index: number) {
    const editedPoints = this._points.map((point: any, pointIndex) => {
      if (pointIndex === index) {
        return { ...point, deleted: true };
      }

      return point;
    });

    this.clearTelemetryPoints();

    setTimeout(() => {
      this._points = editedPoints;
      this.updateSelectedChannels();
      this.clearSourceIds();
    }, 250);
  }

  addDynamicBaselinePoint() {
    const baselinePoint: BaselinePoint = this.baselinePointPlaceholder();
    this.updateSelectedBaselinePointChannels();
    if (this._baselinePoints) {
      this._baselinePoints.push(baselinePoint);
    } else {
      this._baselinePoints = [baselinePoint];
    }
  }

  deleteDynamicBaselinePoint(index: number) {
    const editedBaselinePoints = this._baselinePoints.map((baselinePointElement: any, pointIndex) => {
      if (pointIndex === index) {
        return {
          ...baselinePointElement,
          deleted: true,
        };
      }

      return baselinePointElement;
    });

    this.clearBaselinePoints();

    setTimeout(() => {
      this._baselinePoints = editedBaselinePoints;
      this.updateSelectedBaselinePointChannels();
      this.clearSourceIds();
    }, 250);
  }

  updateSelectedChannels() {
    if (this._points && Array.isArray(this._points)) {
      return (this.selectedChannels = this._points.map(point =>
        point.deleted != true ? `${point.deliveredChannelId}` : null,
      ));
    }
  }
  updateSelectedBaselinePointChannels() {
    if (this._baselinePoints && Array.isArray(this._baselinePoints)) {
      return (this.selectedBaselineChannels = this._baselinePoints.map(baselinePoint =>
        baselinePoint.deleted != true ? `${baselinePoint.deliveredChannelId}` : null,
      ));
    }
  }

  updateSourceIds() {
    this.sourceIds = this._points.map(point => point.sourceId);
  }

  updateBaselinePointSourceIds() {
    this.sourceIds = this._baselinePoints.map(baselinePoint => baselinePoint.sourceId);
  }
  clearSourceIds() {
    this.sourceIds = [];
  }

  clearTelemetryPoints() {
    this._points = [];
  }
  clearBaselinePoints() {
    this._baselinePoints = [];
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
  onKeydown(event: KeyboardEvent): boolean {
    return !(
      event.key == '-' ||
      event.key == '+' ||
      event.key == '*' ||
      event.key == '/' ||
      event.key == '.' ||
      event.key == ','
    );
  }

  onKeydownNegativeValues(event: KeyboardEvent): boolean {
    return !(event.key == '+' || event.key == '*' || event.key == '/' || event.key == '.' || event.key == ',');
  }

  changeBaselineDefinition() {
    const selectedDefinition = this.baselineDefinitions.find(
      baselineDef => baselineDef.id === this.regBaselineDefinition,
    );
    const onlyOneDefault = this.baselineDefinitions.filter(def => def.defaultInd == true).length == 1;
    if (selectedDefinition.defaultInd === true && onlyOneDefault) {
      this._registration.baselineDefId = null;
    } else {
      this._registration.baselineDefId = this.regBaselineDefinition;
    }
  }

  getBaslineDefinitions() {
    return this.registration.productId ? this.baselineDefinitions : [];
  }

  getErpTooltip() {
    return this.translateService.instant('registration.tooltip.energy_reduction_plan');
  }
}
