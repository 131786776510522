<mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{ 'registration.bulk.edit.common_fields_selection.label' | translate }}</mat-label>
    <mat-select
        id="fields"
        class="input-entry"
        data-automation="fieldsSelector"
        multiple
        placeholder="{{ 'registration.bulk.edit.common_fields_selection.placeholder' | translate }}"
        [(ngModel)]="selectedCommonFields"
        (selectionChange)="onCommonFieldsChange($event)"
    >
        <mat-option *ngFor="let field of allCommonFields" [value]="field">
            {{ 'registration.' + field | translate }}
        </mat-option>
    </mat-select>
</mat-form-field>

<form [formGroup]="bulkForm" class="registration-bulk-form">
    <!--- BULK EDIT --->
    <div class="form-wrapper">
        <div *ngIf="selectedCommonFields.includes('description')" class="form-entry">
            <!--- description --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.description' | translate }}</mat-label>
                <input
                    id="description"
                    class="input-entry"
                    formControlName="description"
                    data-automation="description"
                    placeholder="{{ 'registration.create.placeholder.description' | translate }}"
                    matInput
                />
                <mat-error *ngIf="bulkForm.get('description').hasError('required')">
                    {{ 'registration.validation.required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('terminationDttm')" class="form-entry">
            <!--- terminationDttm --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.terminationDttm' | translate }}</mat-label>
                <input
                    id="terminationDttm"
                    class="input-entry"
                    formControlName="terminationDttm"
                    data-automation="terminationDttm"
                    placeholder="{{ 'registration.create.placeholder.terminationDttm' | translate }}"
                    matInput
                />
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('minimumValue')" class="form-entry">
            <!--- minimumValue --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.minimumValue' | translate }}</mat-label>
                <input
                    id="minimumValue"
                    class="input-entry"
                    formControlName="minimumValue"
                    data-automation="minimumValue"
                    placeholder="{{ 'registration.create.placeholder.minimumValue' | translate }}"
                    matInput
                />
                <mat-error *ngIf="bulkForm.get('minimumValue').hasError('required')">
                    {{ 'registration.validation.required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('maximumValue')" class="form-entry">
            <!--- maximumValue --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.maximumValue' | translate }}</mat-label>
                <input
                    id="maximumValue"
                    class="input-entry"
                    formControlName="maximumValue"
                    data-automation="maximumValue"
                    placeholder="{{ 'registration.create.placeholder.maximumValue' | translate }}"
                    matInput
                />
                <mat-error *ngIf="bulkForm.get('maximumValue').hasError('required')">
                    {{ 'registration.validation.required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('controlTimeoutOverride')" class="form-entry">
            <!--- controlTimeoutOverride --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.controlTimeoutOverride' | translate }}</mat-label>
                <input
                    id="controlTimeoutOverride"
                    class="input-entry"
                    formControlName="controlTimeoutOverride"
                    data-automation="controlTimeoutOverride"
                    placeholder="{{ 'registration.create.placeholder.controlTimeoutOverride' | translate }}"
                    matInput
                />
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('controlTimeOverride')" class="form-entry">
            <!--- controlTimeOverride --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.controlTimeOverride' | translate }}</mat-label>
                <input
                    id="controlTimeOverride"
                    class="input-entry"
                    formControlName="controlTimeOverride"
                    data-automation="controlTimeOverride"
                    placeholder="{{ 'registration.create.placeholder.controlTimeOverride' | translate }}"
                    matInput
                />
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('maxHoursPerDay')" class="form-entry">
            <!--- maxHoursPerDay --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.maxHoursPerDay' | translate }}</mat-label>
                <input
                    id="maxHoursPerDay"
                    class="input-entry"
                    formControlName="maxHoursPerDay"
                    data-automation="maxHoursPerDay"
                    placeholder="{{ 'registration.create.placeholder.maxHoursPerDay' | translate }}"
                    matInput
                />
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('crmId')" class="form-entry">
            <!--- crmId --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.crmId' | translate }}</mat-label>
                <input
                    id="crmId"
                    class="input-entry"
                    formControlName="crmId"
                    data-automation="crmId"
                    placeholder="{{ 'registration.create.placeholder.crmId' | translate }}"
                    matInput
                />
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('sdpId')" class="form-entry">
            <!--- sdpId --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.sdpId' | translate }}</mat-label>
                <input
                    id="sdpId"
                    class="input-entry"
                    formControlName="sdpId"
                    data-automation="sdpId"
                    placeholder="{{ 'registration.create.placeholder.sdpId' | translate }}"
                    matInput
                />
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('registrationExternalReferenceId')" class="form-entry">
            <!--- registrationExternalReferenceId --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.registrationExternalReferenceId' | translate }}</mat-label>
                <input
                    id="registrationExternalReferenceId"
                    class="input-entry"
                    formControlName="registrationExternalReferenceId"
                    data-automation="registrationExternalReferenceId"
                    placeholder="{{ 'registration.create.placeholder.registrationExternalReferenceId' | translate }}"
                    matInput
                />
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('preauthorized')" class="form-entry">
            <!--- preauthorized --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.preauthorized' | translate }}</mat-label>
                <input
                    id="preauthorized"
                    class="input-entry"
                    formControlName="preauthorized"
                    data-automation="preauthorized"
                    placeholder="{{ 'registration.create.placeholder.preauthorized' | translate }}"
                    matInput
                />
            </mat-form-field>
        </div>
    </div>
</form>
