import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BulkService } from '../bulk.service';
import { Registration } from '../../shared/model/registration.model';
import { LoadingService } from '../../shared/services/loading.service';

@Component({
  selector: 'app-bulk-list',
  templateUrl: './bulk.list.component.html',
  styleUrls: ['./bulk.list.component.scss'],
})
export class BulkListComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  loading$ = this.loader.loading$;
  registrationList: Registration[] = [];
  itemSize: number;
  failedRegs = [];
  succeededRegs = [];

  constructor(private router: Router, private bulkService: BulkService, private loader: LoadingService) {}

  async ngOnInit() {
    const bulkSelectionSub = this.bulkService.bulkRegistrationsWithData$.subscribe(regs => {
      if (regs) {
        this.registrationList = regs;
        this.itemSize = regs.length;
      }
    });

    const failedRegsSub = this.bulkService.failedRegistrations$.subscribe(failedRegs => {
      this.failedRegs = failedRegs;
    });

    const succeededRegsSub = this.bulkService.succeededRegistrations$.subscribe(succeededRegs => {
      this.succeededRegs = succeededRegs;
    });

    this.subscriptions.push(...[bulkSelectionSub, failedRegsSub, succeededRegsSub]);
  }

  isFailedReg(regId: string): boolean {
    return this.failedRegs.find(reg => reg.id === regId);
  }

  isSucceededReg(regId: string): boolean {
    return this.succeededRegs.find(reg => reg.id === regId);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
