import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ToggleElementsService {
  private _editButton = new BehaviorSubject<boolean>(false);
  public readonly editButton$ = this._editButton.asObservable();

  constructor() {}

  enable() {
    this._editButton.next(true);
  }

  disable() {
    this._editButton.next(false);
  }
}
