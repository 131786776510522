export class TransformRule {
  public id: string;
  public template: string;
  public apply: string[];
  public registration_id: string;
  public order: number;
  public variables: any;
  public deleted: boolean;

  constructor(payload) {
    this.id = payload.id;
    this.template = payload.template;
    this.apply = payload.apply;
    this.registration_id = payload.registration_id;
    this.order = payload.order;
    this.variables = payload.variables;
    this.deleted = false;
  }

    toPayload() {
        return {
            template: this.template,
            apply: this.apply,
            order: this.order,
            variables: this.variables,
        }
    }
}
