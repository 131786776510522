import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Timezone } from '../model/timezone.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TimezonesService {
  timezones$: BehaviorSubject<Timezone[]>;

  constructor(private apiService: ApiService) {
    this.timezones$ = new BehaviorSubject<Timezone[]>([]);
  }

  async fetchTimezones() {
    try {
      const timezones = await this.apiService.get(`timezones`);
      this.timezones$.next(convertKeys.toCamel(timezones));
    } catch (err) {
      console.log('Could not load statuses.', err);
    }
  }
}
