<ng-container *ngIf="loading$ | async">
    <div class="empty-page">
        <mat-spinner diameter="60"></mat-spinner>
        <h2>{{ 'registration.loading' | translate }}...</h2>
    </div>
</ng-container>

<ng-container *ngIf="!(loading$ | async)">
    <h2>
        {{
            itemSize === 0
                ? ('registration.bulk.edit.registrations_list.no_regs_selected' | translate)
                : ('registration.bulk.edit.registrations_list.title' | translate)
        }}
    </h2>
    <div class="separator"></div>
    <cdk-virtual-scroll-viewport class="virtual-scroll-viewport" [itemSize]="50" minBufferPx="20" maxBufferPx="40">
        <div *cdkVirtualFor="let registration of registrationList | sort: 'name'" class="virtual-scroll-item">
            <div class="bulk-list-item" [attr.data-automation]="'bulk-list-item-' + registration?.id">
                {{ registration?.name }}
                <mat-icon
                    id="alertIcon_{{ registration?.id }}"
                    matTooltip="{{ 'registration.bulk.edit.tooltip.failed' | translate }}"
                    class="report-problem"
                    *ngIf="isFailedReg(registration?.id)"
                >
                    report_problem
                </mat-icon>
                <mat-icon
                    id="successIcon_{{ registration?.id }}"
                    matTooltip="{{ 'registration.bulk.edit.tooltip.succeeded' | translate }}"
                    class="success-icon"
                    *ngIf="isSucceededReg(registration?.id)"
                >
                    check
                </mat-icon>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</ng-container>
