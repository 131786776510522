import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EquipmentService {
  controlTypes$: BehaviorSubject<any[]>;

  constructor(private apiService: ApiService) {
    this.controlTypes$ = new BehaviorSubject<any[]>([]);
  }

  async setControlTypes() {
    try {
      const controlTypes = await this.apiService.get('control-types');
      const controlTypesArr = Object.values(controlTypes);

      this.controlTypes$.next(convertKeys.toCamel(controlTypesArr));
    } catch (err) {
      console.log('Could not load reporting intervals.', err);
    }
  }
}
