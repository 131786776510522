export class Product {
  static create(product: any) {
    return new Product(
      product.id,
      product.displayLabel,
      product.programId,
      product.drType,
      product.locale,
      product.timezone,
      product.status,
    );
  }

  // tslint:disable-next-line:variable-name
  display_label: string;

  constructor(
    public id = '',
    public displayLabel = '',
    public programId = '',
    public drType = 'Product',
    public locale = 'en_US',
    public timezone = 'America/New_York',
    public status = statuses[0].display_label,
  ) {}
}

export let statuses = [
  {
    id: 'ACTIVE',
    display_label: 'product.create.active',
  },
  {
    id: 'INACTIVE',
    display_label: 'product.create.inactive',
  },
];
