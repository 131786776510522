<ng-container *ngIf="dialogState === 'DEFAULT'">
    <h1 mat-dialog-title>{{ 'registration.dialog.bulk.edit.title' | translate }}</h1>
    <div mat-dialog-content>
        <p data-automation="dialogText">
            {{ 'registration.dialog.bulk.edit.confirmation' | translate: { num: data.registrations.length } }}
        </p>
    </div>
    <div mat-dialog-actions>
        <button
            class="x-btn-secondary dialog-secondary"
            color="gray"
            aria-label="Cancel"
            (click)="onNoClick()"
            data-automation="dialogCancel"
        >
            <span> {{ 'registration.button.cancel' | translate }}</span>
        </button>

        <button
            class="x-btn-primary dialog-primary"
            color="accent"
            aria-label="Confirm"
            (click)="handleSubmit()"
            data-automation="dialogConfirm"
        >
            <span> {{ 'registration.button.save' | translate }}</span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'UPDATING'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <div class="spinner-container">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
            <span data-automation="dialogText">{{ 'registration.dialog.bulk.edit.updating' | translate }}</span>
            <mat-dialog-content class="mat-typography">
                {{ 'registration.bulk.edit.dialog.failed' | translate }}: {{ failedRegistrations.length }}
                {{ 'registration.bulk.edit.dialog.succeeded' | translate }}: {{ succeededRegistrations.length }}
                {{ 'registration.bulk.edit.dialog.total' | translate }}: {{ data.registrations.length }}
            </mat-dialog-content>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'UPDATE_SUCCESS'">
    <div mat-dialog-content>
        <div class="dialog-result">
            <mat-icon>check_circle</mat-icon>
            <span data-automation="dialogText">{{ 'registration.dialog.bulk.edit.success' | translate }}</span>
            <span data-automation="bulkResults">
                {{ 'registration.bulk.edit.dialog.failed' | translate }}: {{ failedRegistrations.length }}
                {{ 'registration.bulk.edit.dialog.succeeded' | translate }}: {{ succeededRegistrations.length }}
                {{ 'registration.bulk.edit.dialog.total' | translate }}: {{ data.registrations.length }}
            </span>
        </div>
        <button
            class="x-btn-primary dialog-primary"
            color="accent"
            aria-label="Submit"
            (click)="onSubmit()"
            cdkFocusInitial
            data-automation="dialogOk"
        >
            <span> {{ 'registration.button.ok' | translate }}</span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'UPDATE_FAILED'">
    <div class="dialog-result">
        <h3 mat-dialog-title>
            <mat-icon>warning</mat-icon>
            <span data-automation="dialogText">{{ 'registration.dialog.bulk.edit.failure' | translate }}</span>
        </h3>
        <mat-dialog-content class="mat-typography">
            <span data-automation="bulkResults">
                {{ 'registration.bulk.edit.dialog.failed' | translate }}: {{ failedRegistrations.length }}
                {{ 'registration.bulk.edit.dialog.succeeded' | translate }}: {{ succeededRegistrations.length }}
                {{ 'registration.bulk.edit.dialog.total' | translate }}: {{ data.registrations.length }}
            </span>
        </mat-dialog-content>
        <mat-dialog-actions *ngIf="failedRegistrations.length > 0">
            <button
                class="x-btn-primary dialog-primary"
                color="accent"
                aria-label="Retry"
                (click)="handleRetryAll()"
                cdkFocusInitial
                data-automation="dialogRetry"
            >
                <span> {{ 'registration.button.retry_all' | translate: { num: failedRegistrations.length } }}</span>
            </button>
            <button
                class="x-btn-primary dialog-primary"
                color="accent"
                aria-label="Ignore"
                (click)="onNoClick()"
                cdkFocusInitial
                data-automation="dialogOk"
            >
                <span> {{ 'registration.button.ignore' | translate: { num: failedRegistrations.length } }}</span>
            </button>
        </mat-dialog-actions>
    </div>
</ng-container>
