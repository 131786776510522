<div class="app-registration-create content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>library_add</mat-icon>
                </div>
                <h1 id="create-title" data-automation="createTitle">
                    {{ selectedCreateType.createTitleKey | translate }}
                </h1>
                <p class="subtitle" data-automation="createSubtitle">
                    {{ selectedCreateType.createSubtitleKey | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                aria-label="Cancel"
                (click)="handleCancel()"
                data-automation="regCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'registration.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="Submit"
                (click)="handleSubmit()"
                [disabled]="!create.valid || saving"
                type="submit"
                data-automation="regSubmit"
            >
                <mat-icon>done</mat-icon>
                <div *ngIf="saving" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'registration.button.submit' | translate }}</span>
            </button>
        </div>
    </header>

    <form #create="ngForm" class="registration-form" data-app-prefix="reg" [ngClass]="{ saving: saving }">
        <div class="form-wrapper">
            <div class="form-entry create-type">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.create.create_type' | translate }}</mat-label>
                    <mat-select
                        id="createType"
                        name="createType"
                        #createType="ngModel"
                        [ngClass]="{
                            failure: createType.invalid && (createType.dirty || createType.touched),
                            'create-select': true
                        }"
                        [(ngModel)]="this.selectedCreateType"
                        (selectionChange)="handleSelectionChange($event)"
                        data-automation="createType"
                        data-url-persist
                    >
                        <mat-option
                            *ngFor="let createType of createTypes"
                            [value]="createType"
                            id="createType_{{ createType.name }}"
                            >{{ createType.displayLabelKey | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <ng-container [ngSwitch]="selectedCreateType.name">
            <app-registration
                *ngSwitchCase="'Registration'"
                [mode]="mode"
                [appData]="appData"
                [transformRules]="transformRules"
            ></app-registration>
            <app-registration-set
                *ngSwitchCase="'RegistrationSet'"
                [mode]="mode"
                [appData]="appData"
            ></app-registration-set>
        </ng-container>
    </form>
</div>
