import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DeeplinksService {
  marketDeeplink$: BehaviorSubject<string>;
  customersDeeplink$: BehaviorSubject<string>;
  assetDeeplink$: BehaviorSubject<string>;

  constructor(private apiService: ApiService) {
    this.marketDeeplink$ = new BehaviorSubject<string>('');
    this.assetDeeplink$ = new BehaviorSubject<string>('');
    this.customersDeeplink$ = new BehaviorSubject<string>('');
  }

  async setMarketAdminUrl() {
    try {
      const url = await this.apiService.get('deeplinks/markets/');
      this.marketDeeplink$.next(url + '/create');
    } catch (err) {
      console.log('Could not load market admin url.', err);
    }
  }

  async setCustomersAdminUrl() {
    try {
      const url = await this.apiService.get('deeplinks/customers/');
      this.customersDeeplink$.next(url);
    } catch (err) {
      console.log('Could not load site admin url.', err);
    }
  }

  async setAssetAdminUrl() {
    try {
      const url = await this.apiService.get('deeplinks/assets/');
      this.assetDeeplink$.next(url);
    } catch (err) {
      console.log('Could not load asset admin url.', err);
    }
  }
}
