import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Registration } from '../../shared/model/registration.model';
import { TelemetryPoint } from '../../shared/model/telemetry-point.model';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { LocaleBaseConfig } from '../../shared/model/locale.model';
import { TranslateService } from '@ngx-translate/core';
import { SupportingDataService } from 'src/app/shared/services/supporting-data.service';
import { Subscription } from 'rxjs';
import { FormValidatorService } from '../../shared/services/form-validator.service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-telemetry-point',
  templateUrl: './telemetry-point-details.component.html',
  styleUrls: ['./telemetry-point-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemetryPointDetailsComponent implements OnInit {
  channels: any[];
  panelOpenedState: boolean;
  usedChannels: any[] = [];

  loadingPointTypes = true;
  loadingChannels = true;
  loadingReportingIntervals = true;
  loadingSiteMachines: boolean;
  loadingDataProviders = true;

  parent: Registration;
  registrationDisplayLabels: any;
  selectedSiteId = '';
  selectedRegistrationName = '';
  selectedDataProvider: string = '-1';

  localeConfig: LocaleBaseConfig;
  namePlaceholder = '';
  nameLabel = '';
  displayLabelsId = 'telemetry_point_display_labels';
  descriptionPlaceholder = '';
  descriptionLabel = '';

  @Output() addDynamicPoint: EventEmitter<any> = new EventEmitter();
  @Output() deleteDynamicPoint: EventEmitter<any> = new EventEmitter();
  @Output() updateSelectedChannels: EventEmitter<any> = new EventEmitter();
  @Output() updateSourceIds: EventEmitter<any> = new EventEmitter();

  @ViewChild('telemetryPanel') telemetryPanel: MatExpansionPanel;

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';
  private _mode: string;
  private _retired: boolean;
  private _point: TelemetryPoint;
  private _appData: any;
  private _reportingIntervals: any[];
  private _index: number;
  private _count: number;
  private _selectedChannels: any[];
  private _sourceIds: any[];
  private _dataProviders: any[];
  private subscriptions: Subscription[] = [];
  private clone: boolean = false;
  firstTime: boolean = true;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    if (this._retired) {
      return this.VIEW;
    }
    return this._mode;
  }

  @Input()
  set retired(retired: boolean) {
    this._retired = retired;
  }

  get isRetired() {
    return this._retired;
  }

  @Input()
  set point(point: any) {
    this._point = point;
    this.selectedDataProvider = point.dataProvider;
    if (this.clone) {
      this._point.sourceId = '';
    }
  }

  get point() {
    return this._point;
  }

  @Input()
  set registrationName(name: string) {
    this.selectedRegistrationName = name;
    this.updateDisplayLabel(this.point.deliveredChannelId);
  }

  @Input()
  set registration(registration: Registration) {
    this.parent = registration;
    this.registrationDisplayLabels = registration.displayLabels;
  }

  @Input()
  set multiLocaleConfig(config: any) {
    this.localeConfig = config;
  }

  get multiLocaleConfig() {
    return this.localeConfig;
  }

  @Input()
  set siteId(siteId: string) {
    this.selectedSiteId = siteId;
  }

  @Input()
  set appData(appData: any) {
    this._appData = appData;
  }

  get appData() {
    return this._appData;
  }

  @Input()
  set reportingIntervals(reportingIntervals: any) {
    if (reportingIntervals.length > 0) {
      this.loadingReportingIntervals = false;
    }
    this._reportingIntervals = reportingIntervals;
  }

  get reportingIntervals() {
    return this._reportingIntervals;
  }

  @Input()
  set dataProviders(dataProviders: any) {
    if (dataProviders.length > 0) {
      this.loadingDataProviders = false;
    }
    this._dataProviders = dataProviders;
  }

  get dataProviders() {
    return this._dataProviders;
  }

  @Input()
  set index(index: any) {
    this._index = index;
  }

  get index() {
    return this._index;
  }

  @Input()
  set selectedChannels(selectedChannels: any) {
    this._selectedChannels = selectedChannels;
  }

  get selectedChannels() {
    return this._selectedChannels;
  }

  @Input()
  set count(count: any) {
    this._count = count;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  @Input()
  set sourceIds(sourceIds: any) {
    this._sourceIds = sourceIds;
  }

  get sourceIds() {
    return this._sourceIds;
  }

  @Input() userLocale;

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  get displayLabel() {
    if (this.point.displayLabels && this.point.displayLabels[this.userLocale]) {
      return this.point.displayLabels[this.userLocale];
    }
    return this.point.displayLabel;
  }

  get pointChannel() {
    if (this._point.deliveredChannelId && this.channels && this.channels.length > 0) {
      const selectedChannel = this.channels.find((channel: any) => this._point.deliveredChannelId + '' === channel.id);
      if (selectedChannel) {
        return selectedChannel.displayLabel;
      }
    }
  }

  get pointSourceId() {
    if (this._point) {
      return this._point.sourceId;
    }
  }

  get canDelete() {
    return this._count > 1;
  }

  constructor(
    private supportingDataService: SupportingDataService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxCookieService: NgxCookieService,
    private translateService: TranslateService,
    private formValidatorService: FormValidatorService,
    private form: NgForm,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    const routeSub = this.route.queryParams.subscribe(async queryParams => {
      if (queryParams.clone) {
        this.clone = true;
      }
    });
    this.nameLabel = this.translateService.instant('telemetry_point.create.name');
    this.namePlaceholder = this.translateService.instant('telemetry_point.create.placeholder.name');
    this.descriptionLabel = this.translateService.instant('telemetry_point.create.description');
    this.descriptionPlaceholder = this.translateService.instant('telemetry_point.create.placeholder.description');
    const channelsSub = this.supportingDataService.channels$.subscribe(async channels => {
      if (channels !== null) {
        this.channels = this.supportingDataService.getTelemetryChannels();
        this.loadingChannels = false;
      }
    });
    this.panelOpenedState = false;
    this.subscriptions.push(...[channelsSub]);
  }

  handleEdit(e: Event) {
    e.stopImmediatePropagation();
    if (this.parent) {
      const { id } = this.parent;
      this.router.navigate([`details/${id}/edit`]);
    }
  }

  addPoint(e: Event) {
    e.stopImmediatePropagation();
    this.addDynamicPoint.emit();
  }

  deletePoint(e: Event, index: number) {
    e.stopImmediatePropagation();
    this.deleteDynamicPoint.emit(index);
  }

  onChannelChange($event) {
    this._updateChannelId($event.value);
    this.updateUsedChannels($event.value);
    this.updateDisplayLabel($event.value);
  }

  updateUsedChannels(channelId: string) {
    if (!this.usedChannels || !this.usedChannels.includes(channelId)) {
      this.updateSelectedChannels.emit();
    }
  }

  updateDisplayLabel(channelId) {
    if (this.isViewMode || (this.firstTime && channelId !== '-1')) {
      this.firstTime = false;
      return;
    }
    this.firstTime = false;
    let pointName = '';
    this.selectedRegistrationName = this.selectedRegistrationName ? this.selectedRegistrationName.trim() : '';
    if (this.selectedRegistrationName) {
      pointName = this.selectedRegistrationName;
    }
    if (channelId !== '-1' && this.channels) {
      const channel = this.channels.find(channel => channel.id == channelId);
      if (channel) {
        pointName = `${pointName} - ${channel.displayLabel}`;
      }
    }
    this._point.displayLabels[this.userLocale] = pointName;
  }

  private _updateChannelId(deliveredChannelId: string) {
    if (typeof deliveredChannelId != 'undefined') {
      let canonicalChannel = this.channels.find(channel => {
        if (channel.isCanonical === true && channel.conversions && channel.conversions.inboundConversions) {
          const requiredChannelIds = channel.conversions.inboundConversions.reduce((acc, currentConversion) => {
            return [...acc, ...currentConversion.requiredChannelIds];
          }, []);
          return requiredChannelIds.includes(deliveredChannelId);
        }
      });
      this._point.channelId = canonicalChannel?.id.toString() || deliveredChannelId.toString();
    }
  }

  public onInputUpdate() {
    for (const ctrlValue in this.form.form['controls']) {
      if (ctrlValue.match(/^telemetryPointsForm_(\d)*$/)) {
        this.form.form['controls'][ctrlValue]['controls'].displayLabels?.markAsTouched();
        this.form.form['controls'][ctrlValue]['controls'].displayLabels?.markAsDirty();
        this.form.form['controls'][ctrlValue]['controls'].displayLabels?.setErrors(null);
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  checkExistingPointChannels(channelId: string) {
    return this._selectedChannels && this._selectedChannels.includes(channelId);
  }

  checkSourceId($event) {
    if (this._sourceIds && !this._sourceIds.includes($event.target.value)) {
      this.updateSourceIds.emit();
    }
  }

  channelCompare(channel1: any, channel2: any) {
    if (typeof channel1 === 'string') {
      channel1 = parseInt(channel1);
    }

    if (typeof channel2 === 'string') {
      channel2 = parseInt(channel2);
    }
    return channel1 === channel2;
  }

  reportingIntervalCompare(interval1: any, interval2: any) {
    if (typeof interval1 === 'string') {
      interval1 = parseInt(interval1);
    }

    if (typeof interval2 === 'string') {
      interval2 = parseInt(interval2);
    }
    return interval1 === interval2;
  }

  typeCompare(type1: any, type2: any) {
    return type1 && type2 ? type1 === type2 : false;
  }

  getPointReportingInterval() {
    if (this._reportingIntervals.length > 0 && this._point) {
      const reportingInterval = this._reportingIntervals.find(
        (interval: any) => this._point.reportingInterval == interval.durationInMilliseconds,
      );
      if (reportingInterval) {
        return reportingInterval.displayLabel;
      }

      return '';
    }
    return '';
  }

  getDisplayLabelsId() {
    return `${this.displayLabelsId}_${this._index}`;
  }

  getDescriptionLabelsId() {
    return `telemetry_descriptions_${this._index}`;
  }

  getSelectedDataProviderLabel() {
    if (this.selectedDataProvider && this._dataProviders && this._dataProviders.length > 0) {
      const provider = this._dataProviders.find(provider => provider.id === this.selectedDataProvider);
      if (provider) {
        return provider.displayLabel;
      }
      return '';
    }

    return '';
  }

  handleDataProviderChange($event) {
    this.selectedDataProvider = $event.value;
  }

  async ngOnInit() {
    await this.supportingDataService.getChannels();
    await this.supportingDataService.setReportingIntervals();

    if (this.point.toEdit) {
      this.telemetryPanel.open();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  expandPanel() {
    this.formValidatorService.triggerValidation(this.form.form);
    this.panelOpenedState = true;
  }

  onSourceIdChange() {
    this.formValidatorService.triggerSourceIdValidation(this.form.form);
  }
  onSourceIdPaste() {
    setTimeout(() => {
      this.formValidatorService.triggerSourceIdValidation(this.form.form);
    }, 7000);
  }
}
