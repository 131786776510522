import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface RegistrationData {
  name: string;
}

@Component({
  selector: 'registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss'],
})
export class RegistrationDialogComponent {
  dialogState: 'DEFAULT' | 'SAVING_REGISTRATION' | 'SAVING_BASELINES';

  constructor(
    public dialogRef: MatDialogRef<RegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RegistrationData,
  ) {
    this.dialogRef.backdropClick().subscribe(() => this.onNoClick());
  }

  onNoClick(): void {
    if (this.dialogState == 'DEFAULT') {
      this.dialogRef.close();
    }
  }

  changeState(newState: 'DEFAULT' | 'SAVING_REGISTRATION' | 'SAVING_BASELINES') {
    if(newState === 'DEFAULT') {
      this.dialogRef.close();
    }
    this.dialogState = newState;
  }
}
