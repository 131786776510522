import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Product } from '../model/product.model';
import { Program } from '../model/program.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProgramsService {
  programs$: BehaviorSubject<Program[]>;
  products$: BehaviorSubject<Product[]>;
  private programsArr;
  private productsArr;

  constructor(private apiService: ApiService) {
    this.programs$ = new BehaviorSubject<Program[]>(null);
    this.products$ = new BehaviorSubject<Product[]>(null);
    this.programsArr = [];
    this.productsArr = [];
  }

  async fetchPrograms() {
    try {
      let programsAndProductsPromises = [];
      programsAndProductsPromises.push(this.getAllPagesForType(`program`));
      programsAndProductsPromises.push(this.getAllPagesForType(`product`));
      let programsAndProducts = await Promise.all(programsAndProductsPromises);
      let programs = programsAndProducts[0];
      let products = programsAndProducts[1];

      let mappedProducts = [];

      if (products && Array.isArray(products)) {
        products = products.map(product => Product.create(convertKeys.toCamel<any>(product)));
        mappedProducts = this.mapToParent(products, 'programId');
      }
      if (programs && Array.isArray(programs)) {
        programs = programs.map(program => Program.create(convertKeys.toCamel<any>(program)));
        if (Object.keys(mappedProducts).length) {
          programs = this.addChildren(programs, mappedProducts);
        }
      }

      this.programs$.next(programs || []);
      this.products$.next(products || []);
    } catch (err) {
      console.log('Could not load programs list.', err);
    }
  }

  async getAllPagesForType(type: string) {
    // atlas currently only allows page size of 200 for some reason on their spacegroup endpoint
    const PAGE_SIZE = 1000;
    let initialResp = await this.apiService.get(`types/${type.toLowerCase()}`);
    let numPages = 1;
    let pagePromises = [];
    // calculate the number of pages we will call for
    if (initialResp.hasOwnProperty(`meta`) && initialResp.meta.hasOwnProperty(`total`)) {
      numPages = Math.ceil(initialResp.meta.total / PAGE_SIZE);
    }
    if (numPages === 1) {
      return initialResp.spaces;
    }
    // call for all pages past the 1st page (we already fetched the 1st page)
    for (let page = 2; page <= numPages; page++) {
      pagePromises.push(await this.apiService.get(`types/${type.toLowerCase()}`, { page }));
    }
    let results = await Promise.all(pagePromises);
    // break the pages apart and concatenate back into one list
    return results.reduce((listOfObjects: any[], result: any) => {
      if (result.hasOwnProperty(`spaces`)) {
        listOfObjects.push(...result.spaces);
      }
      return listOfObjects;
    }, initialResp.spaces);
  }

  async getPortfolio(id: string) {
    try {
      const portfolio = await this.apiService.get(`portfolios/${id}`);
      return convertKeys.toCamel(portfolio);
    } catch (err) {
      console.log(`Unable to fetch Portfolio ${id}`, err);
    }
  }

  mapToParent(objects: any[], parentPropName: string) {
    return objects.reduce((acc: any, obj: any) => {
      acc[obj[parentPropName]] = acc[obj[parentPropName]] || [];
      if (obj.hasOwnProperty(parentPropName)) {
        acc[obj[parentPropName]].push(obj);
      }
      return acc;
    }, {});
  }

  addChildren(objects: any[], childrenMap: any, includeEmptyParents: boolean = true) {
    return objects.reduce((acc: any[], obj: any) => {
      if (childrenMap[obj['id']]) {
        acc.push({ ...obj, children: childrenMap[obj['id']] });
      } else {
        if (includeEmptyParents) {
          acc.push(obj);
        }
      }

      // If no children do not add to the array.
      return acc;
    }, []);
  }
}
