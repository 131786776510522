import { Product } from './product.model';

export class Program {
  static create(program: any) {
    return new Program(
      program.id,
      program.accountNumberDisplayLabel,
      program.advanceNotice,
      program.alternateIds,
      program.availabilitySchedule,
      program.baselineCapacity,
      program.bonusMinutes,
      program.children,
      program.currencyCode,
      program.locale || program.defaultLocale,
      program.defaultNominationSchedule,
      program.description,
      program.discontinueDttm,
      program.dispatchMethod,
      program.displayLabel || program.name,
      program.drType,
      program.endDttm,
      program.enrollmentOffset,
      program.enrollmentValueTypeName,
      program.enrollmentValueUom,
      program.equipmentIntervalFrequency,
      program.granularityName,
      program.holidayCalendarName,
      program.includeNominationInComms,
      program.lastStatusDttm,
      program.mandatoryHours,
      program.marketContextUrl,
      program.marketExpectations,
      program.marketGranularity,
      program.maxDispatchDuration,
      program.maxDispatchHoursPerSeason,
      program.maxDispatchesPerSeason,
      program.maxDurationBetweenDispatches,
      program.maximumConsecutiveDurations,
      program.maximumInvocationsPerDuration,
      program.maximumNotificationduration,
      program.maximumResponseDuration,
      program.minDispatchDuration,
      program.minDurationBetweenDispatches,
      program.minDurationBetweenEvents,
      program.minimumEconomicRequirement,
      program.minimumNotificationDuration,
      program.minimumRecoveryDuration,
      program.minimumRemunerationRate,
      program.minimumResponseDuration,
      program.minimumStartsPerDuration,
      program.multiActionDispatches,
      program.nominatedDays,
      program.nominatedHours,
      program.nominationLayout,
      program.notificationSchedule,
      program.oadrSimpleLevelContextNormalValue,
      program.oadrSimpleLevelContextUpperLimit,
      program.operatorId,
      program.programDays,
      program.programHours,
      program.programMonths,
      program.programName,
      program.reportBackDuration,
      program.reportInterval,
      program.reportSpecifier,
      program.requiredStartupRemuneration,
      program.responseTime,
      program.shortDisplayLabel,
      program.simpleLevelContext,
      program.startDttm,
      program.status,
      program.timezone,
      program.unavailabilitySchedule,
      program.voluntaryHours,
      program.warrants,
      program.weatherCeilingLimitPct,
      program.weatherFactorId,
      program.weatherFloorLimitPct,
      program.weatherWindowBasis,
      program.weatherWindowEndOffset,
      program.weatherWindowStartOffset,
    );
  }

  // tslint:disable-next-line:variable-name
  display_label: string;

  constructor(
    public id = '',
    public accountNumberDisplayLabel = 'string',
    public advanceNotice = { hours: 0, minutes: 0, seconds: 0 },
    public alternateIds = {},
    public availabilitySchedule = 'string',
    public baselineCapacity = true,
    public bonusMinutes = 0,
    public children: Product[],
    public currencyCode = 'string',
    public defaultLocale = 'en_US',
    public defaultNominationSchedule = true,
    public description = 'string',
    public discontinueDttm = '2019-12-20T18:10:23.898Z',
    public dispatchMethod = ['string'],
    public displayLabel = 'string',
    public drType = 'string',
    public endDttm = '2019-12-20T18:10:23.898Z',
    public enrollmentOffset = 'string',
    public enrollmentValueTypeName = 'string',
    public enrollmentValueUom = 'string',
    public equipmentIntervalFrequency = 'string',
    public granularityName = 'string',
    public holidayCalendarName = 'string',
    public includeNominationInComms = true,
    public lastStatusDttm = '2019-12-20T18:10:23.898Z',
    public mandatoryHours = [0],
    public marketContextUrl = 'string',
    public marketExpectations = 'string',
    public marketGranularity = 'string',
    public maxDispatchDuration = { hours: 0, minutes: 0, seconds: 0 },
    public maxDispatchHoursPerSeason = 0,
    public maxDispatchesPerSeason = 0,
    public maxDurationBetweenDispatches = 0,
    public maximumConsecutiveDurations = 0,
    public maximumInvocationsPerDuration = 0,
    public maximumNotificationduration = 0,
    public maximumResponseDuration = 0,
    public minDispatchDuration = { hours: 0, minutes: 0, seconds: 0 },
    public minDurationBetweenDispatches = 0,
    public minDurationBetweenEvents = { hours: 0, minutes: 0, seconds: 0 },
    public minimumEconomicRequirement = 0,
    public minimumNotificationDuration = 0,
    public minimumRecoveryDuration = 0,
    public minimumRemunerationRate = 'string',
    public minimumResponseDuration = 0,
    public minimumStartsPerDuration = 0,
    public multiActionDispatches = true,
    public nominatedDays = ['string'],
    public nominatedHours = [0],
    public nominationLayout = 'string',
    public notificationSchedule = 'string',
    public oadrSimpleLevelContextNormalValue = 'string',
    public oadrSimpleLevelContextUpperLimit = 'string',
    public operatorId = 'string',
    public programDays = ['string'],
    public programHours = [0],
    public programMonths = ['string'],
    public programName = 'string',
    public reportBackDuration = 'string',
    public reportInterval = 'string',
    public reportSpecifier = 'string',
    public requiredStartupRemuneration = 'string',
    public responseTime = 0,
    public shortDisplayLabel = 'string',
    public simpleLevelContext = 'string',
    public startDttm = '2019-12-20T18:10:23.898Z',
    public status = statuses[0].id,
    public timezone = 'America/New_York',
    public unavailabilitySchedule = 'string',
    public voluntaryHours = [0],
    public warrants = 'string',
    public weatherCeilingLimitPct = 0,
    public weatherFactorId = 0,
    public weatherFloorLimitPct = 0,
    public weatherWindowBasis = true,
    public weatherWindowEndOffset = 0,
    public weatherWindowStartOffset = 0,
  ) {
    this.display_label = this.displayLabel;
  }
}

export let statuses = [
  {
    id: 'ACTIVE',
    display_label: 'program.create.active',
  },
  {
    id: 'INACTIVE',
    display_label: 'program.create.inactive',
  },
];
