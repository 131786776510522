<div
    *ngFor="let dvar of display_vars"
    [attr.data-automation]="'var-variable-' + dvar.name"
    [ngClass]="{ 'form-entry': dvar.type !== 'array' }"
>
    <!-- arrays -->
    <div *ngIf="dvar.type === 'array'">
        <div *ngFor="let subvar of dvar.value; let i = index" class="var-array-list">
            <app-transform-rule-variables-edit
                [index]="i"
                [variables]="dvar.value[i]"
                [schema]="schema[dvar.name].items.properties"
                [rule]="rule"
                [availabilityPoints]="availabilityPoints"
                (variablesChange)="updateNested(dvar.name, i, $event)"
            ></app-transform-rule-variables-edit>
            <div class="delete-rule-row-wrapper form-entry">
                <mat-icon
                    data-automation="delete-rule-row"
                    *ngIf="dvar.value.length > 1"
                    class="delete-rule-row"
                    svgIcon="delete"
                    (click)="deleteRuleRow(dvar.name, i)"
                ></mat-icon>
            </div>
        </div>
        <mat-icon
            data-automation="add-rule-row"
            class="add-rule-row"
            svgIcon="plusButton"
            (click)="addRuleRow(dvar.name)"
        ></mat-icon>
    </div>

    <!--- individual variables --->
    <mat-form-field *ngIf="dvar.type !== 'array'" appearance="outline" floatLabel="always">
        <mat-label>{{ dvar.display_label }}</mat-label>

        <div ngModelGroup="transform-rule-variables_{{ index }}">
            <!-- select -->
            <mat-select
                id="variable_{{ index }}"
                *ngIf="dvar.choices"
                class="input-entry create-select"
                [ngClass]="{ failure: rule.errors?.variables }"
                [(ngModel)]="variables[dvar.name]"
                (change)="variablesChange.emit(variables)"
                name="select"
                [required]="dvar.required"
            >
                <mat-option class="variable-dropdown-left_{{ choice.display }}"  *ngFor="let choice of dvar.choices" [value]="choice.value">
                    {{ choice.display }}
                </mat-option>
            </mat-select>

            <!-- string -->
            <input
                matInput
                *ngIf="!dvar.choices && dvar.type === 'string'"
                type="string"
                class="input-entry"
                [ngClass]="{ failure: (rule.errors?.variables)[dvar.name] }"
                [(ngModel)]="variables[dvar.name]"
                (change)="variablesChange.emit(variables)"
                name="string"
                [required]="dvar.required"
            />

            <!-- number -->
            <input
                matInput
                *ngIf="!dvar.choices && dvar.type === 'number'"
                id="variable-number_{{ index }}"
                type="number"
                step="0.5"
                class="input-entry variable-number_{{dvar.name}}"
                [ngClass]="{ failure: (rule.errors?.variables)[dvar.name] }"
                placeholder="0"
                maxlength="80"
                [max]="dvar.maximum"
                [min]="dvar.minimum"
                [(ngModel)]="variables[dvar.name]"
                (change)="variablesChange.emit(variables)"
                [name]="dvar.name"
                [required]="dvar.required"
                data-automation="variable-number"
            />
        </div>
    </mat-form-field>
</div>
<div *ngIf="display_vars?.length === 0" data-automation="no-variables" class="placeholder">
    {{ 'transform_rules.no_variables' | translate }}
</div>
