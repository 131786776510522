import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { SDP } from '../model/sdp.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProductsService {
  sdps$: BehaviorSubject<SDP[]>;

  constructor(private apiService: ApiService) {
    this.sdps$ = new BehaviorSubject<SDP[]>([]);
  }

  async fetchSDPs(siteId: string) {
    try {
      const data = await this.apiService.get(`sites/${siteId}/sdps`);
      const sdps = convertKeys
        .toCamel<any[]>(data)
        .map(
          sdp => new SDP(sdp.id, sdp.commodity, sdp.displayLabel, sdp.descriptions, sdp.sdpType, sdp.serviceAccounts),
        );
      this.sdps$.next(sdps);
    } catch (err) {
      console.log('Could not load SDP list for site: ' + siteId, err);
    }
  }

  async fetchSDP(id): Promise<SDP> {
    try {
      const sdp = await this.apiService.get(`sdps/${id}`);      
      return convertKeys.toCamel(sdp);
    } catch (err) {
      console.log(`Problem fetching service delivery point ${id}`);
    }
  }

  resetSdps() {
    this.sdps$.next([]);
  }
}
