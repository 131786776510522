import { Directive } from '@angular/core';
import { AbstractControl, UntypedFormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

function validateSelectFactory(): ValidatorFn {
  return (control: AbstractControl) => {
    const selectValidatorExp = new RegExp(/^(?!-1$).*/);
    const isValid = selectValidatorExp.test(control.value);

    if (isValid) {
      return null;
    } else {
      return {
        selectValid: true,
      };
    }
  };
}

@Directive({
  selector: '[appSelectValid][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SelectValidator, multi: true }],
})
export class SelectValidator implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = validateSelectFactory();
  }

  validate(control: UntypedFormControl) {
    return this.validator(control);
  }
}
