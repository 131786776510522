<ng-container *ngIf="dialogState === 'DEFAULT'">
    <h1 *ngIf="drType == 'Registration'" mat-dialog-title>{{ 'registration.dialog.delete.title' | translate }}</h1>
    <h1 *ngIf="drType == 'RegistrationSet'" mat-dialog-title>
        {{ 'registration_set.dialog.delete.title' | translate }}
    </h1>
    <h1 *ngIf="drType == 'BaseLine'" mat-dialog-title>{{ 'registration.dialog.deleteBaseLine.title' | translate }}</h1>
    <div *ngIf="drType == 'Registration'" mat-dialog-content>
        <p data-automation="dialogText">{{ 'registration.dialog.delete.text' | translate }}</p>
    </div>
    <div *ngIf="drType == 'RegistrationSet'" mat-dialog-content>
        <p data-automation="dialogText">{{ 'registration_set.dialog.delete.text' | translate }}</p>
    </div>
    <div *ngIf="drType == 'BaseLine'" mat-dialog-content>
        <p data-automation="dialogText">{{ 'registration.dialog.deleteBaseLine.text' | translate }}</p>
    </div>
    <div mat-dialog-actions>
        <!--    <button mat-button (click)="onNoClick()">{{ 'registration.edit.button.cancel' | translate }}</button>-->
        <!--    <button mat-button>{{ 'registration.edit.button.delete' | translate }}</button>-->

        <button
            class="x-btn-secondary dialog-secondary"
            color="gray"
            aria-label="Cancel"
            (click)="onNoClick()"
            data-automation="dialogCancel"
        >
            <span> {{ 'registration.button.cancel' | translate }}</span>
        </button>

        <button
            class="x-btn-primary dialog-primary"
            color="accent"
            aria-label="Delete"
            (click)="handleDelete()"
            data-automation="dialogDelete"
        >
            <span> {{ 'registration.button.delete' | translate }}</span>
        </button>
    </div>
</ng-container>
<ng-container *ngIf="drType === 'Registration'">
    <ng-container *ngIf="dialogState === 'DELETING'">
        <div mat-dialog-content>
            <div class="dialog-spinner">
                <div class="spinner-container">
                    <mat-spinner diameter="24"></mat-spinner>
                </div>
                <span data-automation="dialogText">{{ 'registration.dialog.delete.deleting' | translate }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="dialogState === 'DELETE_FAILED'">
        <div mat-dialog-content>
            <div class="dialog-result">
                <mat-icon>warning</mat-icon>
                <span data-automation="dialogText">{{ 'registration.dialog.delete.failed' | translate }}</span>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="drType == 'RegistrationSet'">
    <ng-container *ngIf="dialogState === 'DELETING'">
        <div mat-dialog-content>
            <div class="dialog-spinner">
                <div class="spinner-container">
                    <mat-spinner diameter="24"></mat-spinner>
                </div>
                <span data-automation="dialogText">{{ 'registration_set.dialog.delete.deleting' | translate }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="dialogState === 'DELETE_SUCCESS'">
        <div mat-dialog-content>
            <div class="dialog-result">
                <mat-icon>check_circle</mat-icon>
                <span data-automation="dialogText">{{ 'registration_set.dialog.delete.success' | translate }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="dialogState === 'DELETE_FAILED'">
        <div mat-dialog-content>
            <div class="dialog-result">
                <mat-icon>warning</mat-icon>
                <span data-automation="dialogText">{{ 'registration_set.dialog.delete.failed' | translate }}</span>
            </div>
        </div>
    </ng-container>
</ng-container>
