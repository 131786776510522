import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { BulkEditComponent } from './edit/bulk.edit.component';
import { DispatchableFormComponent } from './forms/dispatchable-form/dispatchable.form.component';
import { SettlementFormComponent } from './forms/settlement-form/settlement.form.component';
import { BulkListComponent } from './bulk-list/bulk.list.component';
import { BulkEditDialogComponent } from './dialogs/bulk-edit-submit/bulk-edit.dialog.component';

import { BulkService } from './bulk.service';
import { MatListModule } from '@angular/material/list';
import { BrowserModule } from '@angular/platform-browser';
import { MultiLocaleModule } from 'multi-locale-input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ArraySortPipe } from '../shared/pipes/sort.pipe';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatListModule,
    MultiLocaleModule,
    MatProgressBarModule,
  ],
  declarations: [
    BulkEditComponent,
    BulkListComponent,
    DispatchableFormComponent,
    SettlementFormComponent,
    BulkEditDialogComponent,
    ArraySortPipe,
  ],
  providers: [BulkService, NgForm],
})
export class BulkModule {}
