import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { RegistrationsService } from '../shared/services/registrations.service';
import { LoadingService } from '../shared/services/loading.service';
import { GlobalAlertService } from '../shared/services/global-alert.service';

@Injectable()
export class BulkService {
  readonly selectedRegistrations$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  readonly bulkRegistrationsWithData$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  readonly bulkMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly selectedBulkFormType$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly failedRegistrations$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  readonly succeededRegistrations$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private subscriptions: Subscription[] = [];

  constructor(
    private registrationsService: RegistrationsService,
    private loader: LoadingService,
    private messageService: GlobalAlertService,
  ) {
    const selectedRegsSub = this.selectedRegistrations$.subscribe(async regs => {
        await this.onBulkSelection(regs);
    });

    this.subscriptions.push(selectedRegsSub);
  }

  async getBulkRegistration(ids: string[]) {
    return await this.registrationsService.getMultipleRegistrations(ids);
  }

  private async onBulkSelection(newSelection: []) {
    this.loader.show();
    let count = 0;
    let registrationsWithData: any;
    let batchPromises = [];

    while (count < newSelection.length) {
      let end = count + 50;
      end = end > newSelection.length ? newSelection.length : end;
      const batch = this.getBulkRegistration(newSelection.slice(count, end));
      batchPromises.push(batch);
      count += 50;
    }
    try {
      registrationsWithData = await Promise.all(batchPromises);
      registrationsWithData = registrationsWithData.flat();
      registrationsWithData = registrationsWithData.map((regData: any) => {
        regData.sdp_id = regData.service_delivery_point_id;

        const convertedRegData = convertKeys.toCamel<any>(regData);
        convertedRegData.displayLabels = regData.display_labels;
        convertedRegData.descriptions = regData.descriptions;
        return convertedRegData;
      });

      this.bulkRegistrationsWithData$.next(registrationsWithData);
    } catch (e) {
      this.bulkRegistrationsWithData$.next([]);
      this.messageService.setError('Oops, it looks like we have some errors !', 3000);
    }

    this.loader.hide();
  }

  removeEmpty = obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmpty(obj[key]);
      } else if (obj[key] === null || obj[key] === '') {
        delete obj[key];
      }
    });
  };

  selectRegistration(registration: any) {
    this.selectedRegistrations$.next(registration);
  }

  async setSelectRegistration(registration: any) {
    await this.selectedRegistrations$.next(convertKeys.toCamel(registration));
  }

  async clearAllSelections() {
    await this.selectedRegistrations$.next([]);
  }

  async setSelectedBulkFormType(type: string) {
    await this.selectedBulkFormType$.next(type);
  }

  async setFailedRegistrations(registration: any) {
    await this.failedRegistrations$.next(registration);
  }

  async resetFailedRegistrations() {
    await this.failedRegistrations$.next([]);
  }

  async setSucceededRegistrations(registration: any) {
    await this.succeededRegistrations$.next(registration);
  }

  async resetSucceededRegistrations() {
    await this.succeededRegistrations$.next([]);
  }

  async setBulkModeOff() {
    await this.bulkMode$.next(false);
  }

  async setBulkModeOn() {
    await this.bulkMode$.next(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
