import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Timezone } from '../../../shared/model/timezone.model';
import { Status } from '../../../shared/model/status.model';
import { Locale, LocaleBaseConfig } from '../../../shared/model/locale.model';

import { GlobalAlertService } from '../../../shared/services/global-alert.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { LocalesService } from '../../../shared/services/locales.service';
import { TimezonesService } from '../../../shared/services/timezones.service';
import { ProductsService } from '../../../shared/services/products.service';
import { StatusesService } from '../../../shared/services/statuses.service';
import { ProgramsService } from '../../../shared/services/programs.service';
import { RegistrationsService } from '../../../shared/services/registrations.service';
import { BulkService } from '../../bulk.service';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { LoadingService } from '../../../shared/services/loading.service';

@Component({
  selector: 'app-bulk-edit-settlement-form',
  templateUrl: './settlement.form.component.html',
  styleUrls: ['./settlement.form.component.scss', '../../../shared/shared.styles.scss'],
})
export class SettlementFormComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = [];

  loadingPage: boolean = true;
  loadingBulkList$ = this.loader.loading$;
  selectedDRType: any;
  selectedRegs: {}[] = [];
  errorMessages = [];
  allCommonFields = ['status', 'timezone', 'productId'];
  selectedCommonFields = [];

  userLocale = 'en_US';
  locales: Locale[];
  loadingLocales: boolean = true;
  timezones: Timezone[];
  loadingTimezones: boolean = true;
  statuses: Status[];
  loadingStatuses: boolean = true;
  products = [];
  loadingProducts: boolean = true;
  programs = [];
  loadingPrograms: boolean = true;

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  descriptionLabel: string;
  descriptionPlaceholder: string;

  @Input('bulkForm') bulkForm: UntypedFormGroup;
  supportedLocales: AbstractControl;
  displayLabels: any;
  description: '';

  constructor(
    private router: Router,
    private ngxCookieService: NgxCookieService,
    private translateService: TranslateService,
    private messagingService: GlobalAlertService,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private productsService: ProductsService,
    private programsService: ProgramsService,
    private statusesService: StatusesService,
    private registrationsService: RegistrationsService,
    private bulkService: BulkService,
    private loader: LoadingService,
  ) {
    const routeSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url !== '/bulk/edit') {
          this.router.navigate([`/`]);
        }
      });

    const translateSub = this.translateService.get('registration.description').subscribe((result: string) => {
      this.descriptionLabel = result;
      this.descriptionPlaceholder = this.translateService.instant('registration.create.placeholder.description');
    });
    this.subscriptions.push(...[routeSub, translateSub]);
  }

  ngAfterViewInit() {
    this.supportedLocales = this.bulkForm.get('supportedLocales');
    this.programsService.fetchPrograms();
    this.timezonesService.fetchTimezones();
    this.localesService.fetchLocales();
    this.statusesService.fetchStatuses();
  }

  async ngOnInit() {
    const localesSub = this.localesService.locales$.subscribe(locales => {
      if (locales && locales.length > 0) {
        this.locales = locales;
        this.loadingLocales = false;
        const defaultLocale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
        this.multiLocaleConfig.defaultLocale = defaultLocale;
        this.supportedLocales?.patchValue([defaultLocale]);
      }
    });

    this.localesService.locales$.subscribe(locales => {});

    const statusesSub = this.statusesService.statuses$.subscribe((statuses: any) => {
      if (statuses && statuses.length > 0) {
        this.statuses = statuses;
        this.loadingStatuses = false;
      }
    });

    const timezonesSub = this.timezonesService.timezones$.subscribe((timezones: any) => {
      if (timezones && timezones.length > 0) {
        this.timezones = timezones;
        this.loadingTimezones = false;
      }
    });

    const programsSub = this.programsService.programs$.subscribe((programs: any) => {
      if (programs && programs.length > 0) {
        this.programs = programs;
        this.loadingPrograms = false;
      }
    });

    const bulkSelectionSub = this.bulkService.bulkRegistrationsWithData$.subscribe(regs => {
      if (regs.length === 0) this.selectedCommonFields = [];
      this.selectedRegs = regs;
      this.loadingPage = false;
    });

    this.subscriptions.push(...[localesSub, timezonesSub, statusesSub, programsSub, bulkSelectionSub]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onCommonFieldsChange($event: MatSelectChange) {
    this.allCommonFields.forEach((field: string) => {
      if (!this.selectedCommonFields.includes(field)) {
        const removedField = this.bulkForm.get(field);
        removedField.disable({ onlySelf: true, emitEvent: true });
        removedField.setValue(null);
      } else {
        this.bulkForm.get(field).enable({ onlySelf: true, emitEvent: true });
      }

      if (this.selectedCommonFields.length === 0) {
        this.bulkForm.markAsUntouched();
      }
    });
  }

  buildSupportedLocales() {
    if (this.supportedLocales.value && this.displayLabels.value && this.locales) {
      this.supportedLocales.patchValue([]);
      const keys = Object.keys(this.displayLabels.value);
      for (let key of keys) {
        const localeFound = this.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.supportedLocales.patchValue([...this.supportedLocales.value, localeFound]);
        }
      }
    }
  }

  triggerValidation() {
    this.bulkForm.get('descriptions').updateValueAndValidity();
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  handleSupportedLocaleChange() {
    setTimeout(() => {
      this.triggerValidation();
    }, 100);

    if (this.supportedLocales.value.length === 0) {
      this.supportedLocales.patchValue([...this.supportedLocales.value, this.multiLocaleConfig.defaultLocale]);
    }
  }
}
