<h2 data-automation="bulk-fields-title">{{ 'registration.bulk.edit.common_fields_selection.title' | translate }}</h2>
<div class="separator"></div>
<mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{ 'registration.bulk.edit.common_fields_selection.label' | translate }}</mat-label>
    <mat-select
        id="fields"
        class="input-entry"
        data-automation="fieldsSelector"
        multiple
        placeholder="{{ 'registration.bulk.edit.common_fields_selection.placeholder' | translate }}"
        [(ngModel)]="selectedCommonFields"
        [disabled]="(loadingBulkList$ | async) || selectedRegs.length === 0"
        (selectionChange)="onCommonFieldsChange($event)"
    >
        <mat-option *ngFor="let field of allCommonFields" [value]="field">
            {{ 'registration.' + field | translate }}
        </mat-option>
    </mat-select>
</mat-form-field>

<form [formGroup]="bulkForm" class="registration-bulk-form">
    <!--- BULK EDIT --->
    <div *ngIf="selectedCommonFields.length" class="bulk-form">
        <div *ngIf="selectedCommonFields.includes('productId')" class="form-entry">
            <!--- product --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.product' | translate }}</mat-label>
                <mat-select
                    id="productId"
                    formControlName="productId"
                    class="input-entry"
                    [class.loading-field]="loadingPrograms"
                    data-automation="product"
                    placeholder="{{
                        (programs?.length > 0
                            ? ('registration.create.placeholder.product_selector' | translate)
                            : ('registration.create.placeholder.no_products' | translate)
                        ) | translate
                    }}"
                >
                    <mat-optgroup *ngFor="let program of programs" [label]="program.displayLabel">
                        <mat-option
                            *ngFor="let product of program.children"
                            [value]="product.id"
                            id="{{ program.id }}_{{ product.id }}"
                        >
                            {{ product.displayLabel }}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
                <mat-error data-automation="validationError" *ngIf="bulkForm.get('productId').hasError('required')">
                    {{ 'registration.validation.required' | translate }}
                </mat-error>
                <mat-hint *ngIf="loadingPrograms" align="end">{{ 'registration.loading' | translate }}</mat-hint>
                <mat-spinner *ngIf="loadingPrograms" matSuffix diameter="23"></mat-spinner>
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('status')" class="form-entry">
            <!--- status --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.status' | translate }}</mat-label>
                <mat-select
                    id="status"
                    class="input-entry"
                    formControlName="status"
                    data-automation="status"
                    placeholder="{{ 'registration.create.placeholder.status_selector' | translate }}"
                >
                    <mat-option *ngFor="let status of statuses" [value]="status.code">{{
                        status.displayLabel
                    }}</mat-option>
                </mat-select>
                <mat-error data-automation="validationError" *ngIf="bulkForm.get('status').hasError('required')">
                    {{ 'registration.validation.required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('timezone')" class="form-entry">
            <!--- timezone --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.timezone' | translate }}</mat-label>
                <mat-select
                    id="timezone"
                    class="input-entry"
                    formControlName="timezone"
                    data-automation="timezone"
                    placeholder="{{ 'registration.create.placeholder.timezone_selector' | translate }}"
                >
                    <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">{{
                        timezone.displayLabel
                    }}</mat-option>
                </mat-select>
                <mat-error data-automation="validationError" *ngIf="bulkForm.get('timezone').hasError('required')">
                    {{ 'registration.validation.required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="selectedCommonFields.includes('defaultLocale')" class="form-entry">
            <!--- default locale --->
            <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{ 'registration.locale' | translate }}</mat-label>
                <mat-select
                    id="defaultLocale"
                    class="input-entry"
                    formControlName="defaultLocale"
                    data-automation="defaultLocale"
                    placeholder="{{ 'registration.create.placeholder.locale_selector' | translate }}"
                >
                    <mat-option *ngFor="let locale of locales" [value]="locale.localeName">{{
                        locale.displayLabel
                    }}</mat-option>
                </mat-select>
                <mat-error data-automation="validationError" *ngIf="bulkForm.get('defaultLocale').hasError('required')">
                    {{ 'registration.validation.required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</form>
