export class BaselineDefinition {
  public id: string;
  public blTemplateName: string;
  public displayLabel: string;
  public defaultInd: boolean;

  constructor(payload) {
    this.id = payload.id;
    this.blTemplateName = payload.blTemplateName;
    this.displayLabel = payload.displayLabel;
    this.defaultInd = payload.defaultInd;
  }
}
