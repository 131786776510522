export class BaselinePoint {
  public id?: string;
  public displayLabels: any;
  public displayLabel: string;
  public baselineDefinitionId: string;
  public channelId: string;
  public deliveredChannelId: string;
  public dataProviderId: string;
  public defaultLocale?: string;
  public description?: string;
  public descriptions: any;
  public reportingInterval: string;
  public registrationId: string;
  public sourceId: string;
  public timezone?: string;
  public status: string;
  public veeBypass: boolean;
  public deleted?: boolean;
  public toEdit?: boolean;

  constructor(payload) {
    this.id = payload.id;
    this.displayLabels = payload.display_labels;
    this.displayLabel = payload.display_label;
    this.channelId = payload.channel_id;
    this.deliveredChannelId = payload.delivered_channel_id;
    this.status = payload.status;
    this.baselineDefinitionId = payload.baseline_definition_id;
    this.dataProviderId = payload.dataprovider_id ? payload.dataprovider_id : payload.data_provider;
    this.defaultLocale = payload.default_locale;
    this.descriptions = payload.descriptions;
    this.description = this.descriptions ? this.descriptions[this.defaultLocale] : '';
    this.reportingInterval = payload.reporting_interval_ms || payload.reporting_interval;
    this.registrationId = payload.registration_id;
    this.sourceId = payload.alternate_ids ? payload.alternate_ids['SOURCE_ID'] : payload.source_id;
    this.timezone = payload.timezone;
    this.veeBypass = payload.vee_bypass;
  }

  toPayload() {
    return {
      display_labels: this.displayLabels,
      display_label: this.displayLabel,
      channel_id: String(this.channelId),
      delivered_channel_id: String(this.deliveredChannelId),
      baseline_definition_id: this.baselineDefinitionId,
      dataprovider_id: this.dataProviderId,
      default_locale: this.defaultLocale,
      reporting_interval: String(this.reportingInterval),
      registration_id: this.registrationId,
      descriptions: this.descriptions,
      source_id: this.sourceId,
      status: this.status,
      timezone: this.timezone,
      vee_bypass: this.veeBypass,
    };
  }
}
