import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { Space } from '../model/space.model';

@Injectable()
export class FlexibleAssetsService {
  flexibleAssets$: BehaviorSubject<Space[]>;

  constructor(private apiService: ApiService) {
    this.flexibleAssets$ = new BehaviorSubject<Space[]>([]);
  }

  async fetchFlexibleAssets(siteId: string) {
    try {
      const flexibleAssets = await this.apiService.get(`sites/${siteId}/flexible-assets`);
      this.flexibleAssets$.next(convertKeys.toCamel(flexibleAssets));
    } catch (err) {
      console.log('Could not load flexible assets.', err);
    }
  }

  async fetchFlexibleAsset(id: string) {
    try {
      const asset = await this.apiService.get(`flexible-assets/${id}`);
      return convertKeys.toCamel(asset);
    } catch (err) {
      console.log(`Unable to fetch flexible asset ${id}`, err);
    }
  }
}
