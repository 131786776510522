import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const DR_TYPES = [
  {
    name: 'Registration',
    displayLabelKey: 'registration.registration',
    createTitleKey: 'registration.create.page_title',
    createSubtitleKey: 'registration.create.subtitle',
    editSubtitleKey: 'registration.edit.subtitle',
  },
  {
    name: 'RegistrationSet',
    displayLabelKey: 'registration_set.registration_set',
    createTitleKey: 'registration_set.create.page_title',
    createSubtitleKey: 'registration_set.create.subtitle',
    editSubtitleKey: 'registration_set.edit.subtitle',
  },
];

@Injectable()
export class DrTypeService {
  queryParams: URLSearchParams;
  drTypes: any[] = DR_TYPES;
  readonly selectedDRType$ = new BehaviorSubject<any>(null);

  constructor() {
    this.queryParams = new URLSearchParams(document.location.search);
    if (this.queryParams.has('drType')) {
      this.selectDrType(this.queryParams.get('drType'));
    } else {
      this.selectDrType(this.drTypes[0].name);
    }
  }

  selectDrType(drType: string) {
    if (drType) {
      let drTypeMatch = this.drTypes.find(type => type.name === drType);
      if (drTypeMatch) {
        this.selectedDRType$.next(drTypeMatch);
        this.updateURLQueryParam(drTypeMatch.name);
      }
    }
  }

  updateURLQueryParam(drType: string) {
    const queryParams = new URLSearchParams(document.location.search);
    queryParams.set('drType', drType);
    this.setURLQueryParams(queryParams);
  }

  setURLQueryParams(queryParams: URLSearchParams) {
    const queryParamString = decodeURIComponent(queryParams.toString() ? `?${queryParams.toString()}` : '');
    if (history.pushState) {
      let newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + queryParamString;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }
}
