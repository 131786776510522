import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { RegistrationsService } from '../shared/services/registrations.service';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { RegistrationDetailsComponent } from '../details/registration/registration-details.component';
import { RegistrationSetDetailsComponent } from '../details/registration-set/registration-set-details.component';
import { TranslateService } from '@ngx-translate/core';
import { SelectedSpaceService } from '../shared/services/selected-space.service';
import { ProgramsService } from '../shared/services/programs.service';
import { PortfoliosService } from '../shared/services/portfolios.service';
import { EquipmentService } from '../shared/services/equipment.service';
import { FlexibleAssetsService } from '../shared/services/flexible-assets.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { RegistrationDetailsService } from '../shared/services/registration-details.service';
import { RegistrationSetsService } from '../shared/services/registration-sets.service';
import { Registration } from '../shared/model/registration.model';
import { TransformRulesService } from '../shared/services/transform-rules.service';
import { TransformRule } from '../shared/model/transform-rule.model';
import { TelemetryPointService } from '../shared/services/telemetry-point.service';
import { DrTypeService, DR_TYPES } from '../shared/services/dr-type.service';
import { Subscription } from 'rxjs';
import { BaselinePointService } from '../shared/services/baseline-point.service';
import {RegistrationDialogComponent} from "../dialogs/registration/registration-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BaselineDefinition} from "../shared/model/baseline-definition.model";

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss', '../shared/shared.styles.scss'],
})
export class CreateComponent implements OnInit, AfterViewInit, OnDestroy {
  clone = false;
  form: UntypedFormGroup;
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your registration';
  REG_SET_NOT_CREATED = 'Oops, There was a problem updating your registration set';
  REQUIRED = 'required';
  TELEMETRY_POINTS_CREATED = 'Successfully created';
  TELEMETRY_POINTS_FAILED = 'Failed to create';
  TELEMETRY_POINTS = 'Telemetry Points';
  BASELINE_POINTS_CREATED = 'Successfully created';
  BASELINE_POINTS_FAILED = 'Failed to create';
  BASELINE_POINTS = 'Baseline Points';
  MISSING_REQUIRED_FIELDS = 'You are unable to create a registration, missing options on some required fields';
  BASELINE_POINTS_SUCCESS_DETAILED = 'Baseline Points were generated';
  BASELINE_POINTS_FAILED_DETAILED = 'Baseline Points were generated | Please create points manually and review the baseline definition';
  MISSING_BASELINE_DEFINITION = 'No baselines configured for this Product. Please review Baseline Admin'
  appPrefix = 'reg';
  appData: any = {};
  createTypes = DR_TYPES;
  selectedCreateType: any;
  baselineDefinitions: BaselineDefinition[] = [];
  cloneRegistration;

  transformRules: TransformRule[] = [];
  org = '';
  loadingRegistration = false;
  saving = false;

  readonly mode = 'create';
  private registrationComponentView: RegistrationDetailsComponent;
  private registrationSetComponentView: RegistrationSetDetailsComponent;
  private subscriptions: Subscription[] = [];

  @ViewChild(RegistrationDetailsComponent, {static: false})
  set registrationComponent(registrationDetailsComponent: RegistrationDetailsComponent) {
    this.registrationComponentView = registrationDetailsComponent;
  }

  get registrationComponent() {
    return this.registrationComponentView;
  }

  @ViewChild(RegistrationSetDetailsComponent, {static: false})
  set registrationSetComponent(registrationSetDetailsComponent: RegistrationSetDetailsComponent) {
    this.registrationSetComponentView = registrationSetDetailsComponent;
  }

  get registrationSetComponent() {
    return this.registrationSetComponentView;
  }

  constructor(
      private ngxDeeplinkerService: NgxDeeplinkerService,
      private router: Router,
      private route: ActivatedRoute,
      private messageService: GlobalAlertService,
      private registrationsService: RegistrationsService,
      private registrationSetsService: RegistrationSetsService,
      private registrationDetailsService: RegistrationDetailsService,
      private translateService: TranslateService,
      private orgSelectorService: ContextSelectorService,
      private organizationsService: OrganizationsService,
      private spaceService: SelectedSpaceService,
      private programsService: ProgramsService,
      private portfolioService: PortfoliosService,
      private equipmentService: EquipmentService,
      private flexibleAssetsService: FlexibleAssetsService,
      private transformRulesService: TransformRulesService,
      private telemetryPointService: TelemetryPointService,
      private baselinePointService: BaselinePointService,
      private selectedSpaceService: SelectedSpaceService,
      private drTypeService: DrTypeService,
      public dialog: MatDialog,
  ) {
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }

    this.translateService.get('registration.notification.created_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('registration.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('registration.notification.not_created');
      this.REG_SET_NOT_CREATED = this.translateService.instant('registration_set.notification.not_created');
      this.REQUIRED = this.translateService.instant('registration.validation.required');
      this.TELEMETRY_POINTS_CREATED = this.translateService.instant('telemetry_point.create.success');
      this.TELEMETRY_POINTS_FAILED = this.translateService.instant('telemetry_point.create.failed');
      this.TELEMETRY_POINTS = this.translateService.instant('telemetry_point.create.points');
      this.BASELINE_POINTS_CREATED = this.translateService.instant('baseline_point.create.success');
      this.BASELINE_POINTS_FAILED = this.translateService.instant('baseline_point.create.failed');
      this.BASELINE_POINTS = this.translateService.instant('baseline_point.create.points');
      this.MISSING_REQUIRED_FIELDS = this.translateService.instant('registration.create.missing_required_fields');
      this.BASELINE_POINTS_SUCCESS_DETAILED = this.translateService.instant('baseline_point.create.notification.success');
      this.BASELINE_POINTS_FAILED_DETAILED = this.translateService.instant('baseline_point.create.notification.failed');
      this.MISSING_BASELINE_DEFINITION = this.translateService.instant('baseline_point.create.notification.missing');
    });

    const contextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      this.appData.loadingSites = true;
      this.appData.sites = [];
      const org = orgs[0];
      this.org = org.id;
      this.loadDynamicLists(org.id);
    });

    const sitesSub = this.spaceService.sites$.subscribe(sites => {
      this.checkEmptyField(sites);
      this.appData.sites = sites || [];
      this.appData.loadingSites = false;
    });

    const flexibleAssetsSub = this.flexibleAssetsService.flexibleAssets$.subscribe(flexibleAssets => {
      this.appData.flexibleAssets = flexibleAssets;
      this.appData.loadingAssets = false;
    });

    const programsSub = this.programsService.programs$.subscribe(programs => {
      this.checkEmptyField(programs);
      this.appData.programs = programs || [];
      this.appData.loadingPrograms = false;
    });

    const productsSub = this.programsService.products$.subscribe(products => {
      this.checkEmptyField(products);
      this.appData.products = products || [];
      this.appData.loadingProducts = false;
    });

    const portfoliosSub = this.portfolioService.portfolios$.subscribe(portfolios => {
      this.appData.portfolios = portfolios || [];
      this.appData.loadingPortfolios = false;
    });

    const controlTypesSub = this.equipmentService.controlTypes$.subscribe(controlTypes => {
      this.appData.controlTypes = controlTypes;
      this.appData.loadingControlTypes = false;
    });

    this.appData.loadingProducts = true; //programs & products
    this.programsService.fetchPrograms();
    this.appData.loadingControlTypes = true;
    this.equipmentService.setControlTypes();

    const selectedDRTypeSub = this.drTypeService.selectedDRType$.subscribe(async currentDrType => {
      if (currentDrType) {
        this.selectedCreateType = currentDrType;
      }
    });

    const baselineDefinitionsSub = this.baselinePointService.baselineDefinitions$.subscribe( async currentDefinitions => {
      if(currentDefinitions) {
        this.baselineDefinitions = currentDefinitions;
      }
    })

    const routeSub = this.route.queryParams.subscribe(async queryParams => {
      if (queryParams.clone) {
        this.clone = true;
        const {clone: id} = queryParams;
        this.loadingRegistration = true;
        await this.selectedSpaceService.setSelectedID(id);
        await this.registrationDetailsService.getRegistrationDetails(id);
      }
    });
    this.transformRules = [];

    const registrationSub = this.registrationDetailsService.registration$.subscribe(async (reg: Registration) => {
      let flexibleAssetId = reg ? reg.flexibleAssetId : null;
      let sdpId = reg ? reg.sdpId : null;

      try {
        if (flexibleAssetId && this.organizationsService.isInit) {
          const orgId = await this.registrationsService.getOrgFromAsset(flexibleAssetId);
          this.organizationsService.getOrgs(orgId);
        } else if (sdpId && this.organizationsService.isInit) {
          const orgId = await this.registrationsService.getOrgFromSdp(sdpId);
          this.organizationsService.getOrgs(orgId);
        } else {
          // fallback in case no FA or SDP
          this.organizationsService.getOrgs();
        }
      } catch (err) {
        // fallback in case FA or SDP fail
        this.organizationsService.getOrgs();
      }

      this.loadingRegistration = false;
    });

    this.subscriptions.push(
        ...[
          contextSub,
          selectedDRTypeSub,
          routeSub,
          sitesSub,
          registrationSub,
          productsSub,
          programsSub,
          portfoliosSub,
          flexibleAssetsSub,
          controlTypesSub,
          baselineDefinitionsSub,
        ],
    );
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
    const routeSub = this.route.queryParams.subscribe(async queryParams => {
      if (queryParams.clone) {
        this.clone = true;
        this.cloneRegistration = queryParams.clone;
      }
    });
    if (this.clone) {
      this.transformRulesService.getRules(this.cloneRegistration).then(_rules => {
        this.transformRules = _rules;
      });
    }

    if (!this.selectedCreateType) {
      this.selectedCreateType = this.createTypes[0];
    }
  }

  handleCancel() {
    this.ngxDeeplinkerService.returnHandler({appPrefix: this.appPrefix});
    switch (this.selectedCreateType.name) {
      case 'Registration':
        this.registrationComponentView.resetForm();
        break;
      case 'RegistrationSet':
        this.registrationSetComponentView.resetForm();
        break;
    }
    this.router.navigate(['/'], {
      queryParams: {
        drType: this.selectedCreateType.name,
      },
    });
  }

  checkEmptyField(field) {
    if (Array.isArray(field) && field.length === 0) {
      this.messageService.setError(this.MISSING_REQUIRED_FIELDS);
    }
  }

  async handleSubmit() {
    if (!this.form.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      this.saving = true;
      let response;
      let savingDialog: MatDialogRef<RegistrationDialogComponent, any>;
      try {
        let pointDetails: { failed: number; total: number; };
        switch (this.selectedCreateType.name) {
          case 'Registration':
            savingDialog = this.openCreateDialog();
            savingDialog.componentInstance.changeState('SAVING_REGISTRATION');
            const { registration, points, baselinePoints } = this.registrationComponent;
            response = await this.registrationsService.createRegistration(registration);

            //Generate baseline points
            if(this.baselineDefinitions && this.baselineDefinitions.length && registration.fullReg) {
              savingDialog.componentInstance.changeState('SAVING_BASELINES');
              const data = await this.baselinePointService.automaticBaselinePoints(response);
              pointDetails = {
                failed: data.baseline_points_failed,
                total: data.baseline_points_failed + data.baseline_points_created,
              }
            }

            if (points) {
              await this.telemetryPointService.createOrUpdatePoints(response.id, points);
            }
            if (baselinePoints) {
              await this.baselinePointService.createOrUpdateBaselinePoints(response.id, baselinePoints);
            }
            if (this.transformRules) {
              if (this.clone) {
                this.transformRules.forEach(function(rule) {
                  delete rule.id;
                });
              }
              await this.transformRulesService.save(response.id, this.transformRules);
            }
            this.ngxDeeplinkerService.returnHandler({ appPrefix: this.appPrefix, callbackValue: response.id });
            break;
          case 'RegistrationSet':
            const { registrationSet } = this.registrationSetComponent;
            response = await this.registrationSetsService.createRegistrationSet(registrationSet);
            break;
        }

        if (this.baselinePointService.hasBrokenBaselinePoints || this.telemetryPointService.hasBrokenPoints) {
          if (this.baselinePointService.hasBrokenBaselinePoints) {
            this.messageService.setError(this.BASELINE_POINTS_FAILED);
          }
          if (this.telemetryPointService.hasBrokenPoints) {
            this.messageService.setError(this.TELEMETRY_POINTS_FAILED);
          }
          this.handleEdit(response.id);
        } else {
          // If generated baseline points
          if (this.baselineDefinitions && this.baselineDefinitions.length && pointDetails) {
            // If any failed
            if(pointDetails.failed > 0) {
              const statistics = `${pointDetails.total-pointDetails.failed}/${pointDetails.total} `;
              this.messageService.setError(statistics + this.BASELINE_POINTS_FAILED_DETAILED);
            }
            // Full reg with no baseline point definitions on product
            else if(pointDetails.failed < 1 && pointDetails.total < 1) {
              this.messageService.setSuccess(this.SUCCESS);
            }
            // Baseline generation was successful
            else {
              const statistics = `${pointDetails.total}/${pointDetails.total} `;
              this.messageService.setSuccess(statistics + this.BASELINE_POINTS_SUCCESS_DETAILED);
            }
          }
          // Full reg with no baseline points
          else if(this.registrationComponentView.registration.fullReg && !this.baselineDefinitions.length) {
            this.messageService.setError(this.MISSING_BASELINE_DEFINITION);
          }
          // Default success message
          else {
            this.messageService.setSuccess(this.SUCCESS);
          }
          setTimeout(() => {
            switch (this.selectedCreateType.name) {
              case 'Registration':
                this.registrationComponentView.resetSdps();
                this.registrationComponentView.resetDeeplinkerData();
                this.registrationsService.refetchRegistrations();
                break;
              case 'RegistrationSet':
                this.registrationSetsService.refetchRegistrationSets();
                break;
            }
            this.router.navigate([`details/${response.id}/view`], {
              queryParams: {
                drType: this.selectedCreateType.name,
              },
            });
          }, 2000);
        }
      } catch (e) {
        this.saving = false;
        const errorMessage = e.message || e.error.message;
        const drtype = this.selectedCreateType.name === 'RegistrationSet' ? 'registration_set' : 'registration';
        this.NOT_CREATED = this.translateService.instant(`${drtype}.notification.not_created`);
        if (errorMessage === 'ERR_BAD_REQUEST') {
          this.messageService.setError(this.BAD_REQUEST);
        } else {
          if (this.selectedCreateType.name === 'Registration') {
            this.messageService.setError(this.NOT_CREATED);
          } else if (this.selectedCreateType.name === 'RegistrationSet') {
            this.messageService.setError(this.REG_SET_NOT_CREATED);
          }
        }
      }
      finally {
        if (savingDialog) {
          savingDialog.componentInstance.changeState('DEFAULT');
        }
      }
    }
  }

  openCreateDialog() {
    return this.dialog.open(RegistrationDialogComponent, {
      width: '400px',
      data: {
        name: this.registrationComponent.registrationName,
      },
      disableClose: true,
    });
  }

  private async handleEdit(id: string) {
    this.saving = false;
    this.registrationComponentView.resetSdps();
    this.registrationComponentView.resetDeeplinkerData();
    this.registrationsService.refetchRegistrations();
    this.router.navigate([`details/${id}/edit`], {
      queryParams: {
        drType: this.selectedCreateType.name,
      },
    });
  }

  private async loadDynamicLists(orgId: string) {
    if (orgId) {
      this.spaceService.setOrgSites(orgId);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngAfterViewInit(): void {}

  async handleSelectionChange({ value }) {
    this.drTypeService.selectDrType(value.name);
  }
}
