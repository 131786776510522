import { ToggleElementsService } from '../shared/services/toggle-elements.service';
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RegistrationDetailsComponent } from '../details/registration/registration-details.component';
import { RegistrationsService } from '../shared/services/registrations.service';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ProgramsService } from '../shared/services/programs.service';
import { EquipmentService } from '../shared/services/equipment.service';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { PortfoliosService } from '../shared/services/portfolios.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { RegistrationDetailsService } from '../shared/services/registration-details.service';
import { Registration } from '../shared/model/registration.model';
import { TransformRulesService } from '../shared/services/transform-rules.service';
import { TransformRule } from '../shared/model/transform-rule.model';
import { RegistrationSetsService } from '../shared/services/registration-sets.service';
import { RegistrationSet } from '../shared/model/registration-set.model';
import { RegistrationSetDetailsComponent } from '../details/registration-set/registration-set-details.component';
import { SelectedSpaceService } from '../shared/services/selected-space.service';
import { DrTypeService } from '../shared/services/dr-type.service';
import { BaselinePointService } from '../shared/services/baseline-point.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  CONFLICT = 'There is a data conflict with this registration';
  FORBIDDEN_EDIT_FIELDS = 'You are unable to edit one or more fields on this page';
  form: UntypedFormGroup;
  regId = '';
  appData: any = {};
  transformRules: TransformRule[];
  availabilityPoints: { id: string; display_label: string }[];
  loadingRegistration = false;
  registration: Registration;
  readonly mode = 'view';
  drType: string = 'Registration';
  selectedDRType: any;

  private registrationComponentView: RegistrationDetailsComponent;
  private registrationSetComponentView: RegistrationSetDetailsComponent;
  private subscriptions: Subscription[] = [];

  loadingRegistrationSet: boolean;
  registrationSet: RegistrationSet;
  editButton$ = this.toggleElements.editButton$;

  @ViewChild(RegistrationDetailsComponent)
  set registrationComponent(registrationDetailsComponent: RegistrationDetailsComponent) {
    this.registrationComponentView = registrationDetailsComponent;
  }

  get registrationComponent() {
    return this.registrationComponentView;
  }

  @ViewChild(RegistrationSetDetailsComponent)
  set registrationSetComponent(registrationSetDetailsComponent: RegistrationSetDetailsComponent) {
    this.registrationSetComponentView = registrationSetDetailsComponent;
  }

  get registrationSetComponent() {
    return this.registrationSetComponentView;
  }

  get registrationLabel() {
    if (
      this.registrationComponentView &&
      this.registrationComponentView.registration &&
      this.registrationComponentView.registration.name
    ) {
      return this.registrationComponentView.registration.name;
    }
  }

  get registrationSetLabel() {
    if (
      this.registrationSetComponentView &&
      this.registrationSetComponentView.registrationSet &&
      this.registrationSetComponentView.registrationSet.displayLabel
    ) {
      return this.registrationSetComponentView.registrationSet.displayLabel;
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private registrationsService: RegistrationsService,
    private registrationsDetailsService: RegistrationDetailsService,
    private registrationSetService: RegistrationSetsService,
    private transformRulesService: TransformRulesService,
    private messagingService: GlobalAlertService,
    private translateService: TranslateService,
    private organizationsService: OrganizationsService,
    private programsService: ProgramsService,
    private equipmentService: EquipmentService,
    private portfolioService: PortfoliosService,
    private drTypeService: DrTypeService,
    public dialog: MatDialog,
    private selectedSpaceService: SelectedSpaceService,
    private baselinePointService: BaselinePointService,
    private cdRef: ChangeDetectorRef,
    private toggleElements: ToggleElementsService,
  ) {
    const routeSub = this.route.params.subscribe(async params => {
      if (params.id) {
        const { id } = params;
        this.regId = id;
        if (
          this.route.queryParams['value']['drType'] &&
          this.route.queryParams['value']['drType'] === 'RegistrationSet'
        ) {
          this.loadingRegistrationSet = true;
          this.drType = this.route.queryParams['value']['drType'];
          try {
            this.selectedSpaceService.selectedID$.next(id);
            await this.registrationSetService.getRegistrationSet(id);
          } catch (err) {
            this.handleError();
          }
        } else {
          this.loadingRegistration = true;
          this.drType = 'Registration';
          this.transformRules = null;
          try {
            this.selectedSpaceService.selectedID$.next(id);
            await this.registrationsDetailsService.getRegistrationDetails(id);
          } catch (err) {
            this.handleError();
          }
        }
      }
    });

    const selectedDRTypeSub = this.drTypeService.selectedDRType$.subscribe(currentDrType => {
      if (currentDrType) {
        this.selectedDRType = currentDrType;
      }
    });

    const registrationSub = this.registrationsDetailsService.registration$.subscribe(async (reg: Registration) => {
      if (reg && this.selectedDRType.name === 'Registration') {
        this.registration = reg;
        const { flexibleAssetId, sdpId, status } = reg;
        Promise.all([
          this.transformRulesService.getRules(reg.id),
          this.registrationsService.getRegistrationPoints(reg.id),
        ]).then(([rules, pointResp]) => {
          this.transformRules = rules;
          this.availabilityPoints = pointResp.data || pointResp;
        });

        try {
          if (flexibleAssetId && this.organizationsService.isInit) {
            const orgId = await this.registrationsService.getOrgFromAsset(flexibleAssetId);
            this.organizationsService.getOrgs(orgId);
          } else if (sdpId && this.organizationsService.isInit) {
            const orgId = await this.registrationsService.getOrgFromSdp(sdpId);
            this.organizationsService.getOrgs(orgId);
          } else {
            // fallback in case no FA or SDP
            this.organizationsService.getOrgs();
          }
        } catch (err) {
          // fallback in case FA or SDP fail
          this.organizationsService.getOrgs();
        }

        try {
          this.baselinePointService.getBaselinePointsForRegistration(reg.id);
        } catch (err) {
          this.handleError();
        }
        this.loadingRegistration = false;
      }
    });

    const registrationSetSub = this.registrationSetService.registrationSet$.subscribe(
      async (regSet: RegistrationSet) => {
        if (regSet) {
          this.registrationSet = regSet;
          this.loadingRegistrationSet = false;
          this.organizationsService.getOrgs();
        }
      },
    );

    this.appData.isFullReg = null;
    this.translateService.get('registration.notification.conflict').subscribe((result: string) => {
      this.CONFLICT = result;
    });

    const conflictErrorSub = this.registrationsService.conflictError$.subscribe(() => {
      this.messagingService.setError(this.CONFLICT, 7000);
    });

    const programsSub = this.programsService.programs$.subscribe(programs => {
      this.appData.programs = programs || [];
      this.appData.loadingPrograms = false;
    });

    const productsSub = this.programsService.products$.subscribe(products => {
      this.appData.products = products || [];
      this.appData.loadingProducts = false;
    });

    const controlTypesSub = this.equipmentService.controlTypes$.subscribe(controlTypes => {
      this.appData.controlTypes = controlTypes;
      this.appData.loadingControlTypes = false;
    });

    const portfoliosSub = this.portfolioService.portfolios$.subscribe(portfolios => {
      this.appData.portfolios = portfolios || [];
      this.appData.loadingPortfolios = false;
    });

    const baselinePointsSub = this.baselinePointService.baselinePoints$.subscribe(points => {
      this.appData.baselinePoints = points;
      this.appData.loadingBaselinePoints = false;
    });

    this.appData.loadingPrograms = true;
    this.programsService.fetchPrograms();
    this.appData.loadingControlTypes = true;
    this.equipmentService.setControlTypes();
    this.subscriptions.push(
      ...[
        routeSub,
        registrationSub,
        registrationSetSub,
        conflictErrorSub,
        programsSub,
        productsSub,
        controlTypesSub,
        portfoliosSub,
        selectedDRTypeSub,
        baselinePointsSub,
      ],
    );
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({});
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        drType: this.drType,
        id: this.regId,
        isFullReg: this.appData.isFullReg,
        telemetryPoints: this.registrationComponentView?.points,
        baselinePoints: this.registrationComponentView?.baselinePoints,
        rules: this.transformRules,
      },
    });
  }

  handleEdit() {
    this.router.navigate([`details/${this.regId}/edit`], { queryParams: { drType: this.selectedDRType.name } });
  }

  isRetired() {
    if (this.registration && this.registration.status) {
      return this.registration.status === 'RETIRED';
    }
  }

  handleError() {
    this.router.navigate(['404'], { queryParams: { drType: this.selectedDRType.name } });
  }
}
