import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Space } from '../model/space.model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PortfoliosService {
  portfolios$: BehaviorSubject<Space[]>;

  constructor(private apiService: ApiService) {
    this.portfolios$ = new BehaviorSubject<Space[]>(null);
  }

  async setPortfolios(programId: string) {
    try {
      const rawResp = await this.apiService.get(`programs/${programId}/portfolios`);
      if (typeof rawResp !== undefined && Array.isArray(rawResp) && rawResp.length > 0) {
        rawResp.sort((a, b) => a.display_label.localeCompare(b.display_label));
      }
      this.portfolios$.next(convertKeys.toCamel<Space[]>(rawResp));
    } catch (err) {
      console.log('Could not load Portfolios.', err);
    }
  }
}
