import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TelemetryPoint } from 'src/app/shared/model/telemetry-point.model';
import { TelemetryPointService } from 'src/app/shared/services/telemetry-point.service';
import { TransformRule } from '../../shared/model/transform-rule.model';
import { RegistrationsService } from '../../shared/services/registrations.service';
import { TransformRulesService } from '../../shared/services/transform-rules.service';
import { RegistrationSetsService } from '../../shared/services/registration-sets.service';
import { BaselinePointService } from 'src/app/shared/services/baseline-point.service';
import { BaselinePoint } from 'src/app/shared/model/baseline-point.model';
import { GlobalAlertService } from 'src/app/shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';

export interface RegistrationData {
  drType: string;
  id: string;
  isFullReg: boolean;
  telemetryPoints?: TelemetryPoint[];
  baselinePoints?: BaselinePoint[];
  rules: TransformRule[];
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  drType: string;
  dialogState = 'DEFAULT';
  SUCCESS = 'Deleted Successfully';

  constructor(
    private router: Router,
    private registrationsService: RegistrationsService,
    private registrationSetsService: RegistrationSetsService,
    private telemetryPointService: TelemetryPointService,
    private baseLinePointService: BaselinePointService,
    private transformRulesService: TransformRulesService,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RegistrationData,
  ) {
    this.drType = this.data.drType;
    this.translateService.get('registration.notification.delete').subscribe((result: string) => {
      this.SUCCESS = result;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async handleDelete() {
    console.log('DELETE', this.data);
    try {
      this.dialogState = 'DELETING';

      if (this.data.telemetryPoints) {
        const promises = this.data.telemetryPoints.map(point => this.telemetryPointService.deletePoint(point.id));
        await Promise.all(promises);
      }
      if (this.data.baselinePoints) {
        const promises = this.data.baselinePoints.map(point => this.baseLinePointService.deleteBaselinePoint(point));
        await Promise.all(promises);
      }
      if (this.data.rules) {
        this.data.rules.forEach(rule => (rule.deleted = true));
        await this.transformRulesService.save(this.data.id, this.data.rules);
      }
      if (this.data.id && this.data.drType === 'Registration') {
        await this.registrationsService.deleteRegistration(this.data.id, this.data.isFullReg);
      } else if (this.data.id && this.data.drType === 'RegistrationSet') {
        let regSetDeleted = await this.registrationSetsService.deleteRegistrationSet(this.data.id);
        await this.registrationSetsService.updateRegSetDeleted(regSetDeleted.registration_ids);
      }
      setTimeout(() => {
        this.dialogRef.close();
        this.router.navigate([`list`]);
      }, 2000);
      this.messageService.setSuccess(this.SUCCESS);
    } catch (e) {
      this.dialogState = 'DELETE_FAILED';
      console.log(e);
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);
    }
  }
}
