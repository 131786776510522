<ng-container *ngIf="dialogState === 'DEFAULT'">
    <h1 mat-dialog-title>{{ 'registration.dialog.deleteBaseLine.title' | translate }} {{ data.displayLabel }}?</h1>
    <div mat-dialog-content>
        <p data-automation="dialogText">
            {{ 'registration.dialog.deleteBaseLine.text' | translate }}
        </p>
    </div>
    <div mat-dialog-actions>
        <button
            class="x-btn-secondary dialog-secondary"
            color="gray"
            aria-label="Cancel"
            (click)="onNoClick()"
            data-automation="dialogCancel"
        >
            <span> {{ 'registration.button.cancel' | translate }}</span>
        </button>

        <button
            class="x-btn-primary dialog-primary"
            color="accent"
            aria-label="Delete"
            (click)="handleDelete()"
            data-automation="dialogDelete"
        >
            <span> {{ 'registration.button.delete' | translate }}</span>
        </button>
    </div>
</ng-container>
