<div class="app-registration-view content" data-automation="dataContainer">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingRegistration && !loadingRegistrationSet">assignment</mat-icon>
                    <mat-spinner
                        *ngIf="loadingRegistration || loadingRegistrationSet"
                        matSuffix
                        diameter="30"
                        color="warn"
                    ></mat-spinner>
                </div>
                <h1 id="viewTitle" data-automation="editTitle">
                    <ng-container *ngIf="drType === 'Registration' && !loadingRegistration">{{
                        registrationLabel
                    }}</ng-container>
                    <ng-container *ngIf="drType === 'RegistrationSet' && !loadingRegistrationSet">{{
                        registrationSetLabel
                    }}</ng-container>
                    <ng-container *ngIf="loadingRegistration || loadingRegistrationSet">{{
                        'registration.loading' | translate
                    }}</ng-container>
                </h1>
                <p class="subtitle" data-automation="viewSubtitle" *ngIf="drType === 'Registration'">
                    {{ 'registration.view.subtitle' | translate }}
                </p>
                <p class="subtitle" data-automation="viewSubtitle" *ngIf="drType === 'RegistrationSet'">
                    {{ 'registration_set.view.subtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                [disabled]="this.regId === ''"
                data-automation="regDelete"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'registration.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="primary"
                data-automation="regEdit"
                (click)="handleEdit()"
                [disabled]="this.regId === '' || !(editButton$ | async)"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text">{{ 'registration.button.edit' | translate }}</span>
            </button>
        </div>
    </header>

    <form #view="ngForm" class="registration-form">
        <ng-container [ngSwitch]="drType">
            <app-registration
                *ngSwitchCase="'Registration'"
                [mode]="mode"
                [appData]="appData"
                [transformRules]="transformRules"
                [availabilityPoints]="availabilityPoints"
            ></app-registration>
            <app-registration-set
                *ngSwitchCase="'RegistrationSet'"
                [mode]="mode"
                [appData]="appData"
            ></app-registration-set>
        </ng-container>
    </form>
</div>
