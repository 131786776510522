import { Component, OnDestroy, OnInit } from '@angular/core';
import { RegistrationsService } from '../shared/services/registrations.service';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Router } from '@angular/router';
import { SelectedSpaceService } from '../shared/services/selected-space.service';
import { RegistrationSetsService } from '../shared/services/registration-sets.service';
import { Subscription } from 'rxjs';
import { DrTypeService } from '../shared/services/dr-type.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit, OnDestroy {
  loading = true;
  noRegistrations = false;
  noRegistrationSets = false;
  data;
  selectedDRType: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private registrationsService: RegistrationsService,
    private selectedSpaceService: SelectedSpaceService,
    private organizationsService: OrganizationsService,
    private registrationSetsService: RegistrationSetsService,
    private drTypeService: DrTypeService,
  ) {
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
    this.selectedSpaceService.setSelectedID('');
  }

  async ngOnInit() {
    const selectedDRTypeSub = this.drTypeService.selectedDRType$.subscribe(async currentDrType => {
      if (currentDrType) {
        this.selectedDRType = currentDrType;
        if (this.selectedDRType.name === 'RegistrationSet') {
          await this.registrationSetsService.refetchRegistrationSets();
        } else {
          await this.registrationsService.refetchRegistrations();
        }
      }
    });

    const registrationListSub = this.registrationsService.filteredRegistrationList$.subscribe(registrations => {
      this.data = registrations;
      this.navigateToFirstRow(registrations, 'reg');
    });

    const registrationSetListSub = this.registrationSetsService.registrationSetList$.subscribe(registrationSets => {
      this.data = registrationSets;
      this.navigateToFirstRow(registrationSets, 'regSets');
    });

    this.subscriptions.push(...[selectedDRTypeSub, registrationListSub, registrationSetListSub]);
  }

  navigateToFirstRow(registrations, type) {
    if (registrations && registrations.length > 0) {
      this.loading = true;
      this.noRegistrations = false;
      this.noRegistrationSets = false;
      this.router.navigate([`details/${registrations[0].id}/view`], {
        queryParams: {
          drType: registrations[0].dr_type,
        },
      });
    } else {
      this.loading = false;
      if (type === 'regSets') {
        this.noRegistrations = false;
        this.noRegistrationSets = true;
      }
      if (type === 'reg') {
        this.noRegistrations = true;
        this.noRegistrationSets = false;
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
