import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
      selector: 'app-transform-rule-variables',
      templateUrl: './transform-rule-variables.component.html',
      styleUrls: [
          './transform-rule-variables.component.scss',
          '../../shared/shared.styles.scss'
      ],
      viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TransformRuleVariablesComponent implements OnChanges {
    constructor(
        protected translateService: TranslateService,
    ) {}
    @Input() schema;
    @Input() variables;
    @Input() availabilityPoints?: {id: string, display_label: string}[];

    @Output() variablesChange = new EventEmitter();

    keys = Object.keys;
    display_vars: {display_label: string, value: any}[];

    ngOnChanges() {
        this.display_vars = Object.keys(this.schema).map((dvar) => {
            const schema = this.schema[dvar];
            let value = this.variables[dvar];
            if (value === undefined) {
                value = this.defaultValue(schema);
                this.variables[dvar] = value;
            }
            if (schema['x-source'] === 'monitors') {
                schema.choices = (this.availabilityPoints || []).map((p) => ({
                    value: p.id,
                    display: p.display_label,
                }));
            }
            if (schema.enum) {
                schema.choices = schema.enum.map((choice) => ({
                    value: choice,
                    display: this.translateVariableValue(choice, schema),
                }));
            }
            return {
                ...schema,
                name: dvar,
                value: value,
                value_display: this.translateVariableValue(value, schema),
            };
        });
        this.variablesChange.emit(this.variables);
    }

    defaultValue(schema) {
        if (schema.type === 'array') {
            return [{}];
        }
    }

    translateVariableValue(value, variable): string {
        if (variable.choices) {
            const found = variable.choices.find((choice) => choice.value === value);
            if (found) {
                return found.display;
            }
        }
        if (variable.type === 'string' && variable.enum) {
            return this.translateService.instant('transform_rules.' + value);
        }
        return value + '';
    }
}
