import { Directive, Input } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, Validator } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[startEndDateValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: StartEndDateValidator, multi: true }],
})
export class StartEndDateValidator implements Validator {
  @Input('startControl') startControl: any;
  @Input('endControl') endControl: any;

  validate(theForm: UntypedFormGroup) {
    let isValid = true;

    if (Object.keys(theForm.controls).length > 0 && this.startControl.control.value && this.endControl.control.value) {
      isValid = moment(this.endControl.value).isAfter(this.startControl.value);
    }

    if (isValid) {
      return null;
    } else {
      return {
        startEndDateValid: {
          valid: false,
        },
      };
    }
  }
}
