import { TransformRulesService } from '../../shared/services/transform-rules.service';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TransformRuleComponent } from '../transform-rule/transform-rule.component';
import { ControlContainer, NgForm } from '@angular/forms';
import { TransformTemplatesService } from 'src/app/shared/services/transform-templates.service';

@Component({
  selector: 'app-transform-rule-edit',
  templateUrl: './transform-rule-edit.component.html',
  styleUrls: ['./transform-rule-edit.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TransformRuleEditComponent extends TransformRuleComponent implements OnInit {
  @Input() index: number;

  @Input() availabilityPoints: { id: string; display_label }[];
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private transformRulesService: TransformRulesService,
    protected transformTemplatesService: TransformTemplatesService,
  ) {
    super(transformTemplatesService);
  }

  updateTemplate(e) {
    this.rule.variables = {};
    this.apply = null;
    this.setTemplate();
    this.transformTemplatesService.updateSelectedTemplate(this.index, e.value);
    this.rule.apply = this.template.allowed_apply.slice(0, 1);
    this.apply = this.applyToOption(this.rule.apply);

    this.checkForMeterOffsets();
  }

  setApply(ev: any): void {
    const option = ev.value;
    if (option === 'normalize_offers') {
      this.rule.apply = ['normalize-offers'];
    } else if (option === 'realtime_and_offer') {
      this.rule.apply = ['realtime', 'offer'];
    } else {
      this.rule.apply = [option];
    }
  }

  allowedApplyToOptions(apply: string[]): string[] {
    const options = [];
    if (apply.includes('normalize-offers')) {
      options.push('normalize_offers');
    }
    if (apply.includes('offer')) {
      options.push('offer');
    }
    if (apply.includes('realtime') && apply.includes('offer')) {
      options.push('realtime_and_offer');
    }
    if (apply.includes('realtime')) {
      options.push('realtime');
    }
    return options;
  }

  deleteRule(rule) {
    rule.deleted = true;
    this.onDelete.emit();
    this.transformTemplatesService.deleteSelectedTemplate(this.index);
    this.checkForMeterOffsets();
  }
}
