import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class NavService {
  constructor(private apiService: ApiService) {}

  async getNavUrl() {
    try {
      return await this.apiService.get('links/nav');
    } catch (err) {
      console.log('Unable to get global nav url');
    }
  }
}
