import { Subscription } from 'rxjs';
import { SelectedTemplate } from './../../shared/model/selected-template.model';
import { TransformTemplatesService } from 'src/app/shared/services/transform-templates.service';
import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TransformRule } from '../../shared/model/transform-rule.model';
import { TransformTemplate } from '../../shared/model/transform-template.model';
import { NgModelGroup } from '@angular/forms';

@Component({
  selector: 'app-transform-rule',
  templateUrl: './transform-rule.component.html',
  styleUrls: ['./transform-rule.component.scss', '../../shared/shared.styles.scss'],
})
export class TransformRuleComponent implements OnInit, OnDestroy {
  @Input() rule: TransformRule;
  @Input() templates: TransformTemplate[];
  @Input() index: number;
  @Input() availabilityPoints: {id: string, display_label: string}[];

  template: TransformTemplate;
  apply: string;

  preSelectedTemplates: SelectedTemplate[];
  selectedTemplates: SelectedTemplate[] = [];

  disableMeterOffsets: boolean;

  private subscription: Subscription;

  @ViewChild('transformRules_{{index}}') transformRules: NgModelGroup;

  
  constructor(protected transformTemplatesService: TransformTemplatesService){}
  
  ngOnInit() {
    this.setTemplate();
    
    this.subscription = this.transformTemplatesService.selectedTemplatesChanged.subscribe( (templates: SelectedTemplate[]) => {
      this.selectedTemplates = templates
    }) 
    
    this.preSelectedTemplates = [{id: this.index, type: this.template.template}];
    
    this.transformTemplatesService.addPreSelectedTemplates(this.preSelectedTemplates)

    this.checkForMeterOffsets()
  }

  checkForMeterOffsets(){

    this.selectedTemplates = this.selectedTemplates.reduce((unique, t) => {
      if(!unique.some(obj => obj.id === t.id && obj.type === t.type)) {
        unique.push(t);
      }
      return unique;
  },[]);

     const meterOffsets = this.selectedTemplates.find( (t) =>{
        return t.type === 'meter_offsets'
    })

    if(meterOffsets){
      this.disableMeterOffsets = true;
    } else {
      this.disableMeterOffsets = false;
    }
  }

  setTemplate() {
    this.template = this.templates.find(tmpl => tmpl.template === this.rule.template);
    if (!this.template) return;
    this.apply = this.applyToOption(this.rule.apply);
  }

  /*
   * Convert the "apply" array to a select option
   */
  applyToOption(apply: string[]): string {
    if (apply.includes('normalize-offers')) {
      return 'normalize_offers';
    } else if (apply.includes('realtime') && apply.includes('offer')) {
      return 'realtime_and_offer';
    } else {
      return apply[0];
    }
  }

  ngOnDestroy(){
    this.subscription.unsubscribe()
  }
}
