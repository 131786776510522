    <div ngModelGroup="transformRules_{{index}}">
        <div class="transform-rule-edit" *ngIf="rule && template">
            <div class="edit-rule-actions">
                <mat-icon cdkDragHandle class="drag-handle">drag_indicator</mat-icon>
                <div class="delete-rule" (click)="deleteRule(rule)">
                    <mat-icon svgIcon="delete"></mat-icon>
                </div>
            </div>
            <div class="form-wrapper">
                <div class="form-entry">
                    <!--- template --->
                    <mat-form-field appearance="outline" floatLabel="always" (click)="checkForMeterOffsets()">
                        <mat-label>{{ 'transform_rules.template' | translate }}</mat-label>
                            <mat-select
                                class="input-entry create-select"
                                [ngClass]="{ failure: rule.errors?.template }"
                                [(ngModel)]="rule.template"
                                (selectionChange)="updateTemplate($event)"
                                data-automation="template"
                                name="template"
                                id="template_{{index}}"
                                required
                            >
                                <mat-option
                                id="template-option_{{t.display_label}}"
                                data-automation="template-option" 
                                *ngFor="let t of templates" 
                                [value]="t.template"
                                [disabled]=" disableMeterOffsets ? t.template === 'meter_offsets' : null ">
                                    {{ t.display_label }}
                                </mat-option>
                            </mat-select>
                    </mat-form-field>
                </div>
        
                <div class="form-entry">
                    <!--- apply --->
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'transform_rules.apply' | translate }}</mat-label>
                        <mat-select
                            class="input-entry create-select"
                            [ngClass]="{ failure: rule.errors?.apply }"
                            (selectionChange)="setApply($event)"
                            [(ngModel)]="apply"
                            id="apply_{{ index }}"
                            data-automation="apply"
                            name="apply"
                            required
                        >
                            <mat-option class="apply_{{opt}}" *ngFor="let opt of allowedApplyToOptions(template.allowed_apply)" [value]="opt">
                                {{ 'transform_rules.apply_' + opt | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        
            <div class="form-wrapper variables-row">
                <mat-label class="info-title">{{ 'transform_rules.variables' | translate }}:</mat-label>
            </div>
            <div class="form-wrapper">
                <app-transform-rule-variables-edit
                    [index]="index"
                    [(variables)]="rule.variables"
                    [schema]="template.variables"
                    [rule]="rule"
                    [availabilityPoints]="availabilityPoints"
                ></app-transform-rule-variables-edit>
            </div>
        </div>
    </div>

