<ng-container *ngIf="dialogState === 'SAVING_REGISTRATION'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <mat-spinner diameter="24"></mat-spinner>
        </div>
        <span class="subtitle" data-automation="dialogTextSaveRegistration">{{
            'registration.dialog.save.registration' | translate
            }} {{data.name}}</span>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'SAVING_BASELINES'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <mat-spinner diameter="24"></mat-spinner>
        </div>
        <span class="subtitle" data-automation="dialogTextGenerateBaseline">{{
            'registration.dialog.save.baselines' | translate
            }}</span>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'DEFAULT'">
    <div mat-dialog-content>
        <div class="dialog-spinner">
            <div class="spinner-container">
                <mat-spinner diameter="24"></mat-spinner>
            </div>
        </div>
    </div>
</ng-container>