import { NgForm, ControlContainer } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TransformRule } from '../shared/model/transform-rule.model';
import { TransformTemplate } from '../shared/model/transform-template.model';
import { TransformRulesService } from '../shared/services/transform-rules.service';
import { TransformTemplatesService } from '../shared/services/transform-templates.service';

@Component({
  selector: 'app-transform-rules',
  templateUrl: './transform-rules.component.html',
  styleUrls: ['./transform-rules.component.scss', '../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class TransformRulesComponent implements OnInit {
  @ViewChild('transformRulesForm') transformRulesForm: NgForm;

  constructor(
    private transformRulesService: TransformRulesService,
    private transformTemplatesService: TransformTemplatesService,
  ) {}

  @Input() rules: TransformRule[];
  @Input() templates: TransformTemplate[];
  @Input() availabilityPoints?: { id: string; display_label: string }[];
  @Input() isEditMode: boolean;
  @Input() isCreateMode: boolean;

  ngOnInit() {
    this.transformTemplatesService.setTransformTemplates().then(() => {
      this.templates = this.transformTemplatesService.transformTemplates.filter(
        template =>
          // Don't show the meter offsets rule if we're in create mode,
          // since we can't fetch the availability points until the registration has an id
          template.template !== 'meter_offsets' || !this.isCreateMode,
      );
    });
  }

  ngOnChanges() {
    if (!this.rules) return;
    this.rules.sort((a, b) => (a.order > b.order ? 1 : -1));
  }

  addRule() {
    this.rules.push(
      new TransformRule({
        template: 'linear',
        apply: ['realtime'],
        variables: {
          slope: 1,
          intercept: 5,
        },
      }),
    );
  }

  noRules() {
    return !Boolean(this.rules.find(r => !r.deleted));
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }
}
