import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

// common form
export function SettlementRegBulkFormGroup() {
  return new UntypedFormGroup(
    {
      status: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
      defaultLocale: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
      timezone: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
      productId: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
      descriptions: new UntypedFormControl({ value: {}, disabled: true }, { validators: [Validators.required] }),
    },
    {},
  );
}

// form for each reg
export function SettlementRegistrationFormGroup() {
  return new UntypedFormGroup(
    {
      id: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      name: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      displayLabels: new UntypedFormControl({ disabled: true }, Validators.required),
      status: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      descriptions: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      defaultLocale: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      timezone: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      sdpId: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      productId: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      regId: new UntypedFormControl({ disabled: true }, {}),
      crmId: new UntypedFormControl({ disabled: true }, {}),
      registrationExternalReferenceId: new UntypedFormControl({ disabled: true }, {}),
      fullReg: new UntypedFormControl({ value: false, disabled: true }, {}),
    },
    {},
  );
}

// common form
export const DispatchableRegBulkFormGroup = new UntypedFormGroup(
  {
    status: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
    defaultLocale: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
    timezone: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
    name: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
    description: new UntypedFormControl({ value: '', disabled: true }, {}),
    terminationDttm: new UntypedFormControl({ value: '', disabled: true }, {}),
    minimumValue: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
    maximumValue: new UntypedFormControl({ value: '', disabled: true }, { validators: [Validators.required] }),
    controlTimeoutOverride: new UntypedFormControl({ value: '', disabled: true }, {}),
    controlTimeOverride: new UntypedFormControl({ value: '', disabled: true }, {}),
    maxHoursPerDay: new UntypedFormControl({ value: '', disabled: true }, {}),
    registrationExternalReferenceId: new UntypedFormControl({ value: '', disabled: true }, {}),
    preauthorized: new UntypedFormControl({ value: '', disabled: true }, {}),
  },
  {},
);

// form for each reg
export function DispatchableRegistrationFormGroup() {
  return new UntypedFormGroup(
    {
      id: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      status: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      defaultLocale: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      timezone: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      name: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      description: new UntypedFormControl({ disabled: true }, {}),
      terminationDttm: new UntypedFormControl({ disabled: true }, {}),
      minimumValue: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      maximumValue: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      controlTimeoutOverride: new UntypedFormControl({ disabled: true }, {}),
      controlTimeOverride: new UntypedFormControl({ disabled: true }, {}),
      maxHoursPerDay: new UntypedFormControl({ disabled: true }, {}),
      crmId: new UntypedFormControl({ disabled: true }, {}),
      sdpId: new UntypedFormControl({ disabled: true }, { validators: [Validators.required] }),
      registrationExternalReferenceId: new UntypedFormControl({ disabled: true }, {}),
      preauthorized: new UntypedFormControl({ disabled: true }, {}),
    },
    {},
  );
}
