import { Component, Input, OnInit } from '@angular/core';
import { TransformRule } from '../../shared/model/transform-rule.model';
import { TranslateService } from '@ngx-translate/core';
import { TransformRuleVariablesComponent } from '../transform-rule-variables/transform-rule-variables.component';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';

@Component({
      selector: 'app-transform-rule-variables-edit',
      templateUrl: './transform-rule-variables-edit.component.html',
      styleUrls: [
          './transform-rule-variables-edit.component.scss',
          '../../shared/shared.styles.scss'
      ],
      viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class TransformRuleVariablesEditComponent extends TransformRuleVariablesComponent {

    @Input() index: number;

    constructor(
        protected translateService: TranslateService,
    ) {
        super(translateService);
    }

    @Input() rule: TransformRule;

    updateNested(name, index, value) {
        this.variables[name][index] = value;
        this.variablesChange.emit(this.variables);
    }

    addRuleRow(name) {
        this.variables[name].push({});
    }

    deleteRuleRow(name, index) {
        this.variables[name].splice(index, 1);
    }
}
