import { Component, OnInit } from '@angular/core';
import { OrganizationsService } from '../shared/services/organizations.service';
import { SelectedSpaceService } from '../shared/services/selected-space.service';
import { DrTypeService } from '../shared/services/dr-type.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  loading = true;
  noRegistrations = false;
  noRegistrationSets = false;
  data;
  message: string = '';
  selectedDRType: any;

  constructor(
    private drTypeService: DrTypeService,
    private selectedSpaceService: SelectedSpaceService,
    private organizationsService: OrganizationsService,
  ) {
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
    this.selectedSpaceService.selectedID$.next('');
  }

  async ngOnInit() {
    this.loading = true;
    this.drTypeService.selectedDRType$.subscribe(async currentDrType => {
      if (currentDrType) {
        this.selectedDRType = currentDrType;
        switch (currentDrType.name) {
          case 'RegistrationSet':
            this.noRegistrationSets = true;
            break;
          case 'Registration':
            this.noRegistrations = true;
            break;
        }
      }
    });
  }
}
