<div class="list-header">
    <!--- listType --->
    <mat-form-field class="registration-list-type-dropdown list-selector" appearance="outline" floatLabel="always">
        <mat-label>{{ 'registration.registration_type' | translate }}</mat-label>
        <mat-select
            id="listType"
            name="listType"
            class="input-entry create-select"
            [(ngModel)]="selectedDRType"
            data-automation="listTypeSelector"
            (selectionChange)="handleSelectionChange($event)"
            [disabled]="!orgId"
        >
            <mat-option *ngFor="let drType of drTypes" [value]="drType" id="drType_{{ drType.name }}"
                >{{ drType.displayLabelKey | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="registration-filter-container">
        <mat-form-field
            class="registration-list-filter-dropdown list-selector"
            appearance="outline"
            floatLabel="always"
            [hidden]="selectedDRType.name === 'RegistrationSet'"
        >
            <mat-label>{{ 'registration.list.filter' | translate }}</mat-label>
            <mat-select
                [disabled]="originalDataSource.length === 0 || bulkMode"
                id="registrationListFilter"
                [attr.data-automation]="'registrationListFilterDropdown'"
                [value]="selectedRegistrationFilterTypes"
                (selectionChange)="changeRegistrationFilter($event)"
            >
                <mat-option *ngFor="let type of allRegistrationFilterTypes" [value]="type.name">{{
                    type.displayLabelKey | translate
                }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="registration-list-select-all">
            <mat-checkbox
                *ngIf="bulkMode && !noRegistrations"
                data-automation="bulk-select-all"
                matTooltip="{{ 'Select All' | translate }}"
                matTooltipPosition="above"
                (change)="toggleSelectAll($event.checked)"
            >
            </mat-checkbox>
        </div>
    </div>
</div>
<div
    *ngIf="!noRegistrations && !loading"
    class="list-container"
    [ngClass]="{ 'reg-sets-list': selectedDRType.name === 'RegistrationSet' }"
>
    <table mat-table [dataSource]="dataSource" [ngClass]="{ stickyTableHeader: !noRegistrations || !loading }">
        <!--  Status Column -->
        <ng-container matColumnDef="status">
            <td mat-cell *matCellDef="let element">
                <ng-container [ngSwitch]="element.status">
                    <div
                        *ngSwitchCase="'ACTIVE'"
                        class="status-icon status-icon-active"
                        title="Active"
                        data-automation="statusIndicator"
                    ></div>
                    <div
                        *ngSwitchCase="'RETIRED'"
                        class="status-icon status-icon-retired"
                        title="Retired"
                        data-automation="statusIndicator"
                    ></div>
                    <div
                        *ngSwitchCase="'READY_TO_ACCEPTANCE_TEST'"
                        class="status-icon status-icon-ready"
                        title="Ready to acceptance test"
                        data-automation="statusIndicator"
                    ></div>
                    <div
                        *ngSwitchCase="'ON_HOLD'"
                        class="status-icon status-icon-hold"
                        title="On hold"
                        data-automation="statusIndicator"
                    ></div>
                    <div
                        *ngSwitchCase="'READY_TO_ENROLL'"
                        class="status-icon status-icon-ready-enroll"
                        title="Ready to enroll"
                        data-automation="statusIndicator"
                    ></div>
                    <div
                        *ngSwitchCase="'INACTIVE'"
                        class="status-icon status-icon-inactive"
                        title="Inactive"
                        data-automation="statusIndicator"
                    ></div>
                </ng-container>
            </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="display_label">
            <td mat-cell *matCellDef="let element">
                <div
                    class="reg-label ellipsis"
                    title="{{ element.display_label || element.id }}"
                    data-automation="regTitle"
                >
                    {{ element.display_label || element.id }}
                </div>
                <div class="reg-identifier ellipsis" data-automation="regIdentifier">
                    {{
                        element.alternate_ids && element.alternate_ids.REGISTRATION_IDENTIFIER_NAME
                            ? element.alternate_ids.REGISTRATION_IDENTIFIER_NAME
                            : ''
                    }}
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <td
                mat-cell
                *matCellDef="let element"
                (click)="$event.stopPropagation()"
                class="actions-cell"
                [hidden]="selectedDRType.name === 'RegistrationSet'"
            >
                <div *ngIf="bulkMode">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? bulkSelection.toggle(element.id) : null"
                        [attr.data-automation]="'reg-cbox-' + element.id"
                        [checked]="bulkSelection.isSelected(element.id)"
                        [disabled]="
                            bulkSelection.selected.length >= MAX_BULK_SELECTION && !bulkSelection.isSelected(element.id)
                        "
                    >
                    </mat-checkbox>
                </div>
                <button
                    *ngIf="!bulkMode"
                    mat-icon-button
                    color="accent"
                    aria-label="Clone Registration"
                    class="clone-button"
                    data-automation="clone-button"
                    (click)="cloneRegistration(element.id)"
                >
                    <mat-icon>content_copy</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr
            mat-row
            *matRowDef="let row; let isEven = even; columns: displayedColumns"
            class="list-row"
            [ngClass]="{ 'selected-reg': isListItemSelected(row) }"
            (click)="onRowClicked(row)"
        ></tr>
    </table>
</div>

<div class="spinner-container" *ngIf="loading">
    <mat-spinner mode="indeterminate" diameter="48"></mat-spinner>
</div>
<ng-container *ngIf="noRegistrations && !loading">
    <div class="list-empty">
        <ng-container [ngSwitch]="selectedDRType.name">
            <span *ngSwitchCase="'Registration'" data-automation="emptyListMessage">{{
                'registration.list.none_configured' | translate
            }}</span>
            <span *ngSwitchCase="'RegistrationSet'" data-automation="emptyListMessage">{{
                'registration_set.list.none_configured' | translate
            }}</span>
        </ng-container>
    </div>
</ng-container>

<div class="list-button-container">
    <!-- TEMPORARY: only allow settlement bulk edit -->
    <button
        *ngIf="!noRegistrations && !this.bulkMode"
        [disabled]="isBulkBtnDisabled()"
        id="bulk-button"
        class="icon-btn x-btn-primary button-list"
        [matMenuTriggerFor]="menu"
        color="accent"
        data-automation="bulk-button"
    >
        <mat-icon>list</mat-icon>
        <span>{{ 'registration.button.bulk' | translate }}</span>
    </button>
    <mat-menu #menu="matMenu" class="bulk-menu">
        <button mat-menu-item disabled data-automation="bulk-create-button">
            <mat-icon>add</mat-icon>
            <span>{{ 'registration.button.bulk_create' | translate }}</span>
        </button>
        <button id="edit-bulk" data-automation="bulk-edit-button" (click)="onBulkEditMode()" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>{{ 'registration.button.bulk_edit' | translate }}</span>
        </button>
    </mat-menu>

    <button
        *ngIf="bulkMode"
        (click)="offBulkEditMode()"
        id="cancel-bulk"
        class="icon-btn x-btn-secondary button-list"
        color="accent"
        [disabled]="loadingBulkList$ | async"
    >
        <mat-icon>close</mat-icon>
        <span>{{ 'registration.button.cancel' | translate }}</span>
    </button>

    <button
        *ngIf="bulkMode"
        (click)="routeToBulkEditPage()"
        id="update-bulk"
        data-automation="update-bulk"
        class="icon-btn x-btn-primary button-list"
        color="accent"
        [disabled]="loadingBulkList$ | async"
    >
        <mat-icon>
            {{ bulkLabel === this.editState ? 'done' : 'refresh' }}
        </mat-icon>
        <span>
            {{ bulkLabel }}
        </span>
    </button>

    <button
        *ngIf="!bulkMode"
        (click)="routeToCreatePage()"
        id="create-button"
        data-automation="create-button"
        class="icon-btn x-btn-primary button-list"
        color="accent"
    >
        <mat-icon>add</mat-icon>
        <span>{{ 'shared.create.button' | translate }}</span>
    </button>
</div>
