
<div ngModelGroup = "transformRules">

    <div *ngIf="rules && templates" class="transform-rules">
        <div class="transform-rules-header form-header">
            <h2 data-automation="rules-header">
                {{ 'transform_rules.header' | translate }}
            </h2>
            <mat-icon
                data-automation="add-rule"
                class="add-rule"
                *ngIf="isEditMode || isCreateMode"
                svgIcon="plusButton"
                (click)="addRule()"
            ></mat-icon>
        </div>
    
        <div class="transform-rules-content" [ngClass]="{ editing: isEditMode || isCreateMode}">
            <div *ngIf="!isEditMode && !isCreateMode">
                <div class="rule-container" *ngFor="let rule of rules; let i = index">
                    <app-transform-rule
                        *ngIf="!rule.deleted"
                        [index]="i"
                        [rule]="rule"
                        [templates]="templates"
                        [availabilityPoints]="availabilityPoints"
                    >
                    </app-transform-rule>
                </div>
            </div>
            <div
                *ngIf="isEditMode || isCreateMode"
                cdkDropList
                [cdkDropListData]="rules"
                (cdkDropListDropped)="drop($event, rules)"
                class="edit-rule-list"
                data-automation="rule-container"
            >
                <div *ngFor="let rule of rules, let i = index" class="edit-rule-container" cdkDrag cdkDragLockAxis="y">
                    <app-transform-rule-edit
                        [index]="i"
                        *ngIf="!rule.deleted"
                        [rule]="rule"
                        [templates]="templates"
                        [availabilityPoints]="availabilityPoints"
                    >
                    </app-transform-rule-edit>
                </div>
            </div>
    
            <div class="empty-rules" *ngIf="noRules()">
                <div><mat-icon svgIcon="noData"></mat-icon></div>
                <h6>{{ 'transform_rules.empty_title' | translate }}</h6>
                <div data-automation="empty-rules" class="empty-filler">{{ 'transform_rules.empty' | translate }}</div>
            </div>
        </div>
        <div class="spinner-container" *ngIf="!rules">
            <mat-spinner mode="indeterminate" diameter="48"></mat-spinner>
        </div>
    </div>

</div>
    




