import { OnInit, Component, AfterViewInit } from '@angular/core';
import { RegistrationsService } from '../shared/services/registrations.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { DeeplinksService } from '../shared/services/deeplinks.service';
import { SidenavService } from '../shared/services/sidenav.service';
import { RegistrationSetsService } from '../shared/services/registration-sets.service';
import { SelectedSpaceService } from '../shared/services/selected-space.service';
import { DR_TYPES, DrTypeService } from '../shared/services/dr-type.service';
import { LoadingService } from '../shared/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import { BulkService } from '../bulk/bulk.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, AfterViewInit {
  MAX_BULK_SELECTION: number = 300;
  displayedColumns: string[] = ['status', 'display_label', 'actions'];
  dataSource = [];
  originalDataSource = [];
  loading = true;
  loadingBulkList$ = this.loader.loading$;
  noRegistrations = false;
  selectedRowId = '';
  assetAdminURL: string;
  isInit = true;
  orgId: string;
  selectedDRType: any;
  drTypes = DR_TYPES;
  selectedRegistrationFilterTypes = 'ALL';
  allRegistrationFilterTypes: any[] = [
    { name: 'ALL', displayLabelKey: 'registration.list.all_registrations', isSelected: false },
    { name: 'SETTLEMENT', displayLabelKey: 'registration.list.settlement_data_registrations', isSelected: false },
    { name: 'DISPATCH', displayLabelKey: 'registration.list.dispatchable_registrations', isSelected: false },
  ];
  bulkSelection: SelectionModel<any>;
  orderBy = 'ASCEND';

  bulkMode: boolean = false;
  private subscriptions: Subscription[] = [];
  editState: string = '';
  updateState: string = '';
  labelStates = [this.editState, this.updateState];
  bulkLabel: string;
  filterChanged = false;

  constructor(
    private router: Router,
    private orgSelectorService: ContextSelectorService,
    private registrationsService: RegistrationsService,
    private deeplinksInternalService: DeeplinksService,
    private sidenavService: SidenavService,
    private registrationSetService: RegistrationSetsService,
    private selectedSpaceService: SelectedSpaceService,
    private drTypeService: DrTypeService,
    private translateService: TranslateService,
    private bulkService: BulkService,
    private loader: LoadingService,
  ) {
    this.loading = true;
    this.bulkSelection = new SelectionModel<any>(true, []);

    this.bulkService.bulkMode$.subscribe(mode => {
      this.bulkMode = mode;
      if (!this.bulkMode) {
        this.bulkSelection.clear();
        this.bulkService.clearAllSelections();
        this.bulkLabel = this.editState;
      } else {
        const defaultSelection = this.getDefaultSelection();
        if (defaultSelection) {
          this.onRowClicked(defaultSelection);
        }
      }
    });

    this.deeplinksInternalService.setAssetAdminUrl();
    this.deeplinksInternalService.assetDeeplink$.subscribe(async deeplinkUrl => {
      this.assetAdminURL = deeplinkUrl;
    });

    this.drTypeService.selectedDRType$.subscribe(async currentDrType => {
      if (currentDrType) {
        this.selectedDRType = currentDrType;
        if (this.orgId) {
          switch (currentDrType.name) {
            case 'RegistrationSet':
              await this.fetchRegistrationSets();
              break;
            case 'Registration':
              await this.fetchRegistrations();
              break;
          }
        }

        if (!this.router.isActive('/create', false) && !this.router.isActive('/edit', false) && !this.isInit) {
          this.router.navigate([`/`], {
            queryParams: {
              drType: this.selectedDRType.name,
            },
          });
        }
      }
    });

    this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      if (org && org.id) {
        this.orgId = org.id;
        this.loading = true;

        if (this.selectedDRType.name === 'RegistrationSet') {
          await this.fetchRegistrationSets();
        } else {
          await this.fetchRegistrations();
        }

        this.loading = false;
        if (
          !this.router.isActive('/?drType=Registration', true) &&
          !this.router.isActive('/?drType=RegistrationSet', true) &&
          !this.router.isActive('/create', false) &&
          !this.isInit
        ) {
          this.router.navigate([`/`], {
            queryParams: {
              drType: this.selectedDRType.name,
            },
          });
        }

        this.isInit = false;
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event.url !== '/bulk/edit' && this.bulkMode) this.offBulkEditMode();
    });

    this.registrationSetService.registrationSetList$.subscribe(async registrationSets => {
      this.dataSource = registrationSets;
      this.noRegistrations = registrationSets.length === 0;
    });

    this.registrationsService.filteredRegistrationList$.subscribe(async registrations => {
      this.dataSource = registrations;
      this.noRegistrations = registrations.length === 0;
      if (!this.noRegistrations && !this.isEditMode && !this.isCreateMode && this.filterChanged) {
        this.triggerDefaultSelection();
      }
    });

    this.registrationsService.registrationList$.subscribe(async registrations => {
      this.originalDataSource = registrations;
    });

    this.selectedSpaceService.selectedID$.subscribe(id => {
      this.selectedRowId = id;
    });
  }

  async ngOnInit() {}

  ngAfterViewInit() {
    const translateSub = this.translateService.get('registration').subscribe(() => {
      this.editState = this.translateService.instant('registration.button.edit');
      this.updateState = this.translateService.instant('registration.button.update');

      this.bulkLabel = this.editState;
    });

    this.subscriptions.push(...[translateSub]);
  }

  navigateToFirstRow(data) {
    if (data && data.length > 0) {
      this.noRegistrations = false;
      this.router.navigate([`details/${data[0].id}/view`], {
        queryParams: {
          drType: data[0].dr_type,
        },
      });
    } else {
      this.loading = false;
      this.noRegistrations = true;
    }
  }

  async fetchRegistrations() {
    this.loading = true;
    this.registrationsService.registrationList$.next([]);
    await this.registrationsService.getRegistrations(this.orgId, this.orderBy);
    this.loading = false;
  }

  async fetchRegistrationSets() {
    this.loading = true;
    this.registrationSetService.registrationSetList$.next([]);
    await this.registrationSetService.getRegistrationSets(this.orgId, this.orderBy);
    this.loading = false;
  }

  async onRowClicked(row) {
    await this.sidenavService.closeIfMobile();

    if (this.bulkMode) {
      this.bulkSelection.toggle(row.id);
    } else {
      await this.router.navigate([`details/${row.id}/view`], { queryParams: { drType: this.selectedDRType.name } });
    }
  }

  onBulkEditMode() {
    this.bulkService.bulkMode$.next(true);
  }

  offBulkEditMode() {
    this.bulkLabel = this.editState;
    this.bulkService.setFailedRegistrations([]);
    this.bulkService.setSucceededRegistrations([]);
    this.bulkService.bulkMode$.next(false);
    this.router.navigate([`/`]);
  }

  isBulkEditButtonDisabled(): boolean {
    if (this.selectedRegistrationFilterTypes.length === 1 && !this.noRegistrations) {
      return this.selectedRegistrationFilterTypes.includes('ALL');
    }
    return true;
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], { queryParams: { drType: this.selectedDRType.name } });
  }

  routeToBulkEditPage() {
    this.bulkLabel = this.updateState;
    this.sidenavService.closeIfMobile();
    this.bulkService.setFailedRegistrations([]);
    this.bulkService.setSucceededRegistrations([]);
    this.bulkService.selectedRegistrations$.next(this.bulkSelection.selected);
    this.router.navigate([`bulk/edit`]);
  }

  getAssetAdminUrl(assetId, page) {
    if (this.assetAdminURL) {
      return `${this.assetAdminURL}/details/${assetId}/${page}`;
    }
  }

  getDefaultSelection(): any {
    if (!this.dataSource.length) return null;
    const defaultSelection = this.dataSource.find(listItem => listItem.id === this.selectedRowId);
    if (!defaultSelection) {
      return this.dataSource[0];
    }
    return defaultSelection;
  }

  cloneRegistration(regId: string) {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], { queryParams: { clone: regId, drType: this.selectedDRType.name } });
  }

  async handleSelectionChange({ value }) {
    this.drTypeService.selectDrType(value.name);
  }

  changeRegistrationFilter(event: MatSelectChange) {
    // the DOM will lazy load the equipmentTypes, must ensure they are there to filter with first...
    this.filterChanged = true;
    this.selectedRegistrationFilterTypes = event.value;
    this.registrationsService.updateFilter(event.value);
    if (this.selectedRegistrationFilterTypes == 'DISPATCH') {
      this.bulkService.setSelectedBulkFormType('dispatchable');
    } else {
      this.bulkService.setSelectedBulkFormType('settlement');
    }
  }

  private triggerDefaultSelection() {
    const defaultSelection = this.getDefaultSelection();
    if (defaultSelection) {
      this.onRowClicked(defaultSelection);
    }
  }

  isListItemSelected(row): boolean {
    if (this.bulkMode) {
      return this.bulkSelection.isSelected(row);
    } else {
      return row.id === this.selectedRowId;
    }
  }

  toggleSelectAll(checked: boolean) {
    this.bulkSelection.clear();
    if (checked) {
      if (this.dataSource.length > this.MAX_BULK_SELECTION) {
        this.bulkSelection.select(...this.dataSource.map(reg => reg.id).slice(0, this.MAX_BULK_SELECTION));
      } else {
        this.bulkSelection.select(...this.dataSource.map(reg => reg.id));
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  isBulkBtnDisabled(): boolean {
    return this.selectedRegistrationFilterTypes !== 'SETTLEMENT' || this.selectedDRType.name !== 'Registration';
  }

  get isEditMode() {
    return this.router.url.includes('edit');
  }

  get isCreateMode() {
    return this.router.url.includes('create');
  }
}
