import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, GlobalNavService, Context } from 'ngx-global-nav';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { environment } from 'src/environments/environment.prod';
import { ApiEndpointsService } from './services/api-endpoints.service';
import { ApiService } from './shared/services/api.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './shared/services/sidenav.service';
import { NavService } from './shared/services/global-nav.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  orgList: Context[] = [];
  sidenavOpen = true;
  navUrl = '';
  mobileQuery: MediaQueryList;

  private readonly _mobileQueryListener: () => void;

  constructor(
    private translate: TranslateService,
    private apiEndpointsService: ApiEndpointsService,
    private apiService: ApiService,
    private globalNavService: GlobalNavService,
    private authService: AuthService,
    private cookieService: NgxCookieService,
    private sidenavService: SidenavService,
    private navService: NavService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.getNavUrl();
    this.mobileQuery = media.matchMedia('(max-width: 1280px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    this.matIconRegistry.addSvgIcon(
      'noData',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/no-data.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/general-info.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'plusButton',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/plus-button.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/delete.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/warning.svg'),
    );
  }

  ngOnInit(): void {
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe(result => {
        document.title = result;
      });
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  async getNavUrl() {
    this.navUrl = await this.navService.getNavUrl();
  }
}
