import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-baseline-dialog',
  templateUrl: './delete-dialog-BaseLine.component.html',
  styleUrls: ['./delete-dialog-BaseLine.component.scss'],
})
export class DeleteBaseLineDialogComponent {
  dialogState = 'DEFAULT';
  constructor(public dialogRef: MatDialogRef<DeleteBaseLineDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  async handleDelete() {
    setTimeout(() => {
      this.dialogRef.close('ok');
    }, 2000);
  }
}
