import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Timezone } from '../../../shared/model/timezone.model';
import { Status } from '../../../shared/model/status.model';
import { Locale } from '../../../shared/model/locale.model';

import { GlobalAlertService } from '../../../shared/services/global-alert.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { LocalesService } from '../../../shared/services/locales.service';
import { TimezonesService } from '../../../shared/services/timezones.service';
import { ProductsService } from '../../../shared/services/products.service';
import { StatusesService } from '../../../shared/services/statuses.service';
import { ProgramsService } from '../../../shared/services/programs.service';
import { FlexibleAssetsService } from '../../../shared/services/flexible-assets.service';
import { RegistrationsService } from '../../../shared/services/registrations.service';
import { BulkService } from '../../bulk.service';
import { EquipmentService } from '../../../shared/services/equipment.service';
import { DrTypeService } from '../../../shared/services/dr-type.service';
import { RegistrationSetsService } from '../../../shared/services/registration-sets.service';
import { ControlContainer, UntypedFormGroup, NgForm } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { filter } from 'rxjs/operators';

export type fieldType = 'string' | 'number' | 'select' | 'checkbox' | 'date-time';

@Component({
  selector: 'app-bulk-edit-dispatchable-form',
  templateUrl: './dispatchable.form.component.html',
  styleUrls: ['./dispatchable.form.component.scss', '../../../shared/shared.styles.scss'],
})
export class DispatchableFormComponent implements OnInit, AfterViewInit {
  loadingPage: boolean = true;
  selectedDRType: any;
  selectedRegs: {}[] = [];
  private subscriptions: Subscription[] = [];

  done = false;
  errorMessages = [];
  loadForm = false;
  commonFields = [];
  uncommonFields = [
    'name',
    'id',
    'regId',
    'crmId',
    'sdpId',
    'flexibleAssetId',
    'registrationExternalReferenceId',
    'fullReg',
  ];

  allCommonFields = [
    'description',
    'terminationDttm',
    'minimumValue',
    'maximumValue',
    'controlTimeoutOverride',
    'controlTimeOverride',
    'maxHoursPerDay',
    'registrationExternalReferenceId',
    'preauthorized',
    'dispatchConf.anticipatedKw',
  ];
  selectedCommonFields = [];

  userLocale = 'en_US';
  locales: Locale[];
  timezones: Timezone[];
  statuses: Status[];
  products = [];
  controlTypes: any[];
  registrationTypes: any[];

  @Input('bulkForm') bulkForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private ngxCookieService: NgxCookieService,
    private translateService: TranslateService,
    private messagingService: GlobalAlertService,
    private localesService: LocalesService,
    private timezonesService: TimezonesService,
    private productsService: ProductsService,
    private programsService: ProgramsService,
    private statusesService: StatusesService,
    private flexibleAssetsService: FlexibleAssetsService,
    private equipmentService: EquipmentService,
    private drTypeService: DrTypeService,
    private registrationsService: RegistrationsService,
    private registrationSetService: RegistrationSetsService,
    private bulkService: BulkService,
  ) {
    const routeSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url !== '/bulk/edit') {
          this.router.navigate([`/`]);
        }
      });

    const translateSub = this.translateService.get('registration.registration').subscribe((result: string) => {});

    this.subscriptions.push(...[routeSub, translateSub]);
  }

  ngAfterViewInit() {}
  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  onCommonFieldsChange($event: MatSelectChange) {
    this.allCommonFields.forEach((field: string) => {
      if (!this.selectedCommonFields.includes(field)) {
        this.bulkForm.get(field).disable({ onlySelf: true, emitEvent: true });
      } else {
        this.bulkForm.get(field).enable({ onlySelf: true, emitEvent: true });
      }
    });
  }
}
