<div class="app-registration-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loading">edit</mat-icon>
                    <mat-spinner *ngIf="loading" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1 id="editTitle" data-automation="editTitle">
                    <ng-container *ngIf="!loading">{{ displayLabel }}</ng-container>
                    <ng-container *ngIf="loading">{{ 'registration.loading' | translate }}</ng-container>
                </h1>
                <p class="x-desktop-only subtitle" data-automation="editSubtitle">
                    {{ selectedDRType.editSubtitleKey | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                color="gray"
                (click)="handleCancel()"
                [disabled]="this.regId === ''"
                data-automation="regCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'registration.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                [disabled]="this.regId === ''"
                data-automation="regDelete"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'registration.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                (click)="handleSubmit()"
                data-automation="regSubmit"
                [disabled]="!edit.valid || saving"
            >
                <mat-icon>done</mat-icon>
                <div *ngIf="saving" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'registration.button.save' | translate }}</span>
            </button>
        </div>
    </header>

    <form #edit="ngForm" class="registration-form" [ngClass]="{ 'page-saving': saving }">
        <p class="x-mobile-only subtitle" data-automation="subtitle">{{ 'registration.edit.subtitle' | translate }}</p>
        <ng-container [ngSwitch]="selectedDRType.name">
            <app-registration
                *ngSwitchCase="'Registration'"
                [mode]="mode"
                [appData]="appData"
                [transformRules]="transformRules"
                [availabilityPoints]="availabilityPoints"
            ></app-registration>
            <app-registration-set
                *ngSwitchCase="'RegistrationSet'"
                [mode]="mode"
                [appData]="appData"
            ></app-registration-set>
        </ng-container>
    </form>
</div>
